import React from "react";
import Teams from "./teams";
import Messenger from "../component/chat-components/ChatUi";

const TeamChat = () => {
  return (
    <Teams>
      <Messenger />
    </Teams>
  );
};

export default TeamChat;

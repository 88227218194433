import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axiosInstance from "../../../utils/axiosInstance";

export const changePassword = createAsyncThunk(
  "security/changePassword",
  async (pass, thunkAPI) => {
    try {
      const request = await axiosInstance.patch(`/users/changePassword`, pass);
      return request.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "security/deleteAccount",
  async (userUuid, thunkAPI) => {
    try {
      const request = await axiosInstance.delete(`/users/${userUuid}`);
      Cookies.remove("user");
      Cookies.remove("token");
      return request.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

import { configureStore } from "@reduxjs/toolkit";
import itemSlice from "./features/item/itemSlice";
import authSlice from "./features/auth/authSlice";
import reactScrollSlice from "./features/reactScroll/reactScrollSlice";
import planSlices from "./features/plans/planSlices";
import securitySlice from "./features/security/securitySlice";
import profileSlice from "./features/profile/profileSlice";

export const store = configureStore({
  reducer: {
    items: itemSlice,
    auth: authSlice,
    profile: profileSlice,
    security: securitySlice,
    reactScroll: reactScrollSlice,
    plans: planSlices,
  },
});

import axios from "axios";
import { baseUrl } from "../baseUrl";
import { getAuthToken } from "./testLogin";

export const sendEmail = async (name, email, subject, message) => {
  const res = await axios.post(
    `${baseUrl}/users/sendEmail`,
    {
      name,
      email,
      subject,
      message,
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    }
  );
  return res;
};

import React from "react";
import BillingCard from "./parts/BillingCard";
import BillingHistory from "./parts/BillingHistory";
import Header from "./parts/Header";
import PaymentMethod from "./parts/PaymentMethod";
import { useLocation } from "react-router";
import ReactGA from "react-ga4";
import "./parts/BillingPage.css";
import NotFound from "../component/NotfoundPage/Notfound";


const BillingPage = () => {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.event("page_view", { page_title: "Billing Page" });
    ReactGA.send({
      page: location.pathname,
      hitType: "pageview",
      title: "Billing Page",
    });
  }, []);

  return (
    <div className="container-xl px-4 mt-4">
      <Header />
      <hr className="mt-0 mb-4" />
      <NotFound/>
      {/* <BillingCard /> */}
      {/* <PaymentMethod /> */}
      {/* <BillingHistory /> */}
    </div>
  );
};

export default BillingPage;

import React from "react";
import headerImg from "../img/header-1.png";
import Typewriter from "react-ts-typewriter";
import { Link } from "react-router-dom";

const Header2 = () => {
  return (
    <>
      <header className="pb-10  bg-bg-3">
        <div className="container">
          <div className="row align-items-left flex-md-row-reverse">
            <div className="col-md-6">
              {/* <img
                src={headerImg}
                // srcset="i/header-1@2x.png 2x"

                alt=""
                className="w-100"
                data-aos="fade-down"
                data-aos-delay="0"
              /> */}
            </div>
            <div className="col-md-6 mt-8 mt-md-20 mt-30">
              <h1
                className="display-4"
                data-aos="fade-down"
                data-aos-delay="150"
                style={{ height: "35%" }}
              >
                <Typewriter
                  loop={true}
                  delay={1200}
                  cursor={false}
                  speed={60}
                  text="World's Best AI Coding Assistant!"
                />
              </h1>
              <p
                className="mb-8 mt-3 fs-1 text-bg-1"
                data-aos="fade-down"
                data-aos-delay="250"
              >
                AI Powered Code Generator to your Code Editor, Cutting your
                development time in half. Focus more on deployment, less on
                typing
              </p>
              <Link
                to="/login?redirect=signup"
                className="btn btn-bg-1"
                data-aos="fade-down"
                data-aos-delay="400"
              >
                TRY FREE TRIAL
              </Link>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header2;

import React, { useState, useContext } from "react";
import MyAccount from "../component/coderoom/MyAccount";
import MyAppearance from "../component/coderoom/MyAppearance";
import style from "./CodeRoomSetting.module.css";
import { ThemeContext } from "../utils/ThemeContext";

const CodeRoomSetting = () => {
  const { context } = useContext(ThemeContext);
  const [activeSection, setActiveSection] = useState("myaccount");

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  return (
    <>
      <div
        className={style.setting}
        style={{ backgroundColor: context === "dark" ? "#242424" : "white" }}
      >
        <div
          className={style.leftbar}
          style={{ backgroundColor: context === "dark" ? "black" : "#f1f1f1" }}
        >
          <div className={style.left1}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                color: context === "dark" ? "white" : "black",
              }}
            >
              USER SETTINGS
            </p>
            <div
              className={
                context === "dark"
                  ? style.hover_div_dark
                  : style.hover_div_light
              }
              onClick={() => handleSectionClick("myaccount")}
            >
              <svg
                viewBox="0 0 24 24"
                height="20"
                width="20"
                aria-hidden="true"
                focusable="false"
                fill={context === "dark" ? "#ffffff" : "#000000"}
                xmlns="http://www.w3.org/2000/svg"
                class="StyledIconBase-ea9ulj-0 bWRyML"
              >
                <path d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"></path>
              </svg>
              <span
                style={{
                  marginLeft: "10px",
                }}
              >
                My Account
              </span>
            </div>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                marginTop: "20px",
                color: context === "dark" ? "white" : "black",
              }}
            >
              CLIENT SETTINGS
            </p>
            <div
              className={
                context === "dark"
                  ? style.hover_div_dark
                  : style.hover_div_light
              }
              onClick={() => handleSectionClick("appearance")}
            >
              <svg
                viewBox="0 0 24 24"
                height="20"
                width="20"
                aria-hidden="true"
                focusable="false"
                fill={context === "dark" ? "#ffffff" : "#000000"}
                xmlns="http://www.w3.org/2000/svg"
                class="StyledIconBase-ea9ulj-0 bWRyML"
              >
                <path d="M9.38 21.646A9.985 9.985 0 0 0 12 22l.141-.001a2.998 2.998 0 0 0 2.515-1.425c.542-.876.6-1.953.153-2.88l-.198-.415c-.453-.942-.097-1.796.388-2.281.485-.485 1.341-.841 2.28-.388h.001l.413.199a2.99 2.99 0 0 0 2.881-.153A2.997 2.997 0 0 0 22 12.141a9.926 9.926 0 0 0-.353-2.76c-1.038-3.827-4.353-6.754-8.246-7.285-3.149-.427-6.241.602-8.471 2.833S1.666 10.247 2.096 13.4c.53 3.894 3.458 7.208 7.284 8.246zM15.5 6a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-5-1a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zM9 15.506a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-2.5-6.5a1.5 1.5 0 1 1-.001 3.001A1.5 1.5 0 0 1 6.5 9.006z"></path>
              </svg>
              <span
                style={{
                  marginLeft: "10px",
                }}
              >
                Appearance
              </span>
            </div>
          </div>
        </div>
        <main
          className={style.main}
          style={{ backgroundColor: context === "dark" ? "#242424" : "white" }}
        >
          {activeSection === "myaccount" && <MyAccount />}
          {activeSection === "appearance" && <MyAppearance />}
        </main>
      </div>
    </>
  );
};

export default CodeRoomSetting;

import React from "react";
import { useLocation, Link } from "react-router-dom";

function Header() {
  const { pathname } = useLocation();

  return (
    <>
      <nav className="nav nav-borders" style={{ paddingTop: "4rem" }}>
        <Link
          style={{
            color: pathname === "/dashboard/edit" ? "#0061f2" : "#69707a",
            borderBottomColor:
              pathname === "/dashboard/edit" ? "#0061f2" : "none",
          }}
          className="nav-link ms-0"
          to="/dashboard/edit"
        >
          Profile
        </Link>
        <Link
          style={{
            color: pathname === "/dashboard/comingsoon" ? "#0061f2" : "#69707a",
            borderBottomColor:
              pathname === "/dashboard/comingsoon" ? "#0061f2" : "none",
          }}
          className="nav-link active"
          to="/dashboard/comingsoon"
        >
          Billing
        </Link>
        <Link
          style={{
            color: pathname === "/dashboard/security" ? "#0061f2" : "#69707a",
            borderBottomColor:
              pathname === "/dashboard/security" ? "#0061f2" : "none",
          }}
          className="nav-link"
          href="/dashboard/security"
          to="/dashboard/security"
        >
          Security
        </Link>
      </nav>
    </>
  );
}

export default Header;

import React from "react";
import logo from "../img/logo.png";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <section className="py-10 pb-md-20  bg-bg-1">
      <div
        className="xed-top"
        style={{
          top: "1rem",
        }}
      >
        <Link to={"/"}>
          <img
            src={logo}
            style={{ height: "4.3rem", marginLeft: "2rem" }}
            alt="logo"
          />
        </Link>
      </div>
      <div className="container text-center">
        <h2
          className="display-5 mb-6 text-light-1"
          style={{ marginTop: "2rem" }}
          data-aos="fade-down"
          data-aos-delay="150"
        >
          About
        </h2>
        <p
          className="fs-2 remove-br-sm mb-0 text-light-2"
          data-aos="fade-down"
          data-aos-delay="250"
        >
          DeepTuring- Artificial General Intelligence
        </p>
      </div>
    </section>
  );
};

export default Header;

// Init
import React, { useCallback, useEffect, useRef, useState } from "react";
import Select from "react-select";
import axios from "axios";
import toast from "react-hot-toast";
import { HiSpeakerWave } from "react-icons/hi2";
// Style
import "./Home.css";
import { useNavigate, useLocation } from "react-router";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import { baseUrl } from "../baseUrl";
import { getTextToSpeech } from "../api/text-to-speech";
import { VoiceToText } from "./voice-to-text";
import { Readable } from "stream";
import { useStream } from "react-fetch-streams";
import Cookies from "js-cookie";

const Home = () => {
  let navigate = useNavigate();
  // State
  const [text, setText] = useState(""); // Text to translate
  const [selectedOption, setSelectedOption] = useState(null); // Selected dropdown item
  const [loading, setLoading] = useState(false); // Loading state
  const [audioObj, setAudioObj] = useState({ url: "", data: [] });
  const [transcripts, setTranscripts] = useState([]); // transcripts will be the text from speech
  const audioRef = useRef(null);
  const [url, setUrl] = useState(baseUrl);
  const location = useLocation();

  // Options for dropdown
  const dropdownOptions = [
    { value: "0", label: "QNA" },
    { value: "1", label: "SQL Translate" },
    { value: "2", label: "SQL Request" },
    { value: "3", label: "Parse Unstructured Data" },
    { value: "4", label: "Python To Natural Language" },
    { value: "5", label: "Javascript Helper Chatbox" },
    { value: "6", label: "Calculate Time Complexity" },
    { value: "7", label: "Programming Language Converter" },
    { value: "8", label: "Explain Code" },
    { value: "9", label: "Python Bug Fixer" },
    { value: "10", label: "Write Python Docstring" },
    { value: "11", label: "Javascript One Line Function" },
    { value: "12", label: "Completions" },
    { value: "13", label: "Text To Commands" },
    { value: "14", label: "Ml Ai Language Tutor" },
    { value: "15", label: "Javascript To Python" },
  ];

  useEffect(() => {
    ReactGA.event("page_view", { page_title: "Home Page" });
    ReactGA.send({
      page: location.pathname,
      hitType: "pageview",
      title: "Home Page",
    });
  }, []);

  // Apis for each dropdown item
  const apis = [
    "/codexOpenai/qAndA",
    "/codexOpenai/sqlTranslate",
    "/codexOpenai/sqlRequest",
    "/codexOpenai/parseUnstructuredData",
    "/codexOpenai/pythonToNaturalLanguage",
    "/codexOpenai/javascriptHelperChatbox",
    "/codexOpenai/calculateTimeComplexity",
    "/codexOpenai/programmingLanguageConverter",
    "/codexOpenai/explainCode",
    "/codexOpenai/pythonBugFixer",
    "/codexOpenai/writePythonDocstring",
    "/codexOpenai/javascriptOneLineFunction",
    "/codexOpenai/completions",
    "/codexOpenai/textToCommands",
    "/codexOpenai/MlAiLanguageTutor",
    "/codexOpenai/javascriptToPython",
  ];

  // Handle dropdown change and submit the query to the server at the same time
  const handleChange = async (e) => {
    // the server url + the selected API from the dropdown
    // const url = baseUrl + apis[parseInt(e.value)];
    if (e && e.value && e.value != "") {
      setUrl(baseUrl + apis[parseInt(e.value)]);
      setSelectedOption(e.label);
    }
  };

  // Handle submit
  const handleSubmit = async () => {
    if (url == baseUrl) {
      toast.error("Please Select Preset");
      return false;
    }
    toast("Loading..."); // i don't know why to use toast like this instead of .error or .success
    // console.log(first)

    setLoading(true);
    try {
      // API Call in stream mode
      try {
        mixpanel.track("Home Page", { "Translation Language": selectedOption });
      } catch (error) {
        console.log("Mixpanel Error : ", error);
      }

      let config = {
        headers: {
          // "Content-Type": "application/json",
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          // Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          Authorization: `Bearer ${Cookies.get("token")}`,
          uuid: process.env.REACT_APP_UUID,
        },
        body: JSON.stringify({
          // query: text,
          query: " let data = {name:Brendan Eich,);",
          stream: true,
        }),
      };
      // const response = await fetch(url, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     // Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      //     Authorization: `Bearer ${Cookies.get("token")}`,
      //     uuid: process.env.REACT_APP_UUID,
      //   },
      //   body: JSON.stringify({
      //     // query: text,
      //     query: " let data = {name:Brendan Eich,);",
      //     stream: true,
      //   })
      const response = await axios
        .post(
          url,
          {
            query: text,
            // query: " let data = {name:Brendan Eich,);",
            stream: true,
          },
          {
            headers: {
              // "Content-Type": "application/json",
              Accept: "application/json",
              "Content-Type": "application/json;charset=UTF-8",
              // Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
              Authorization: `Bearer ${Cookies.get("token")}`,
              uuid: process.env.REACT_APP_UUID,
            },
          }
        )
        .then(async (response) => {
          // const response = await axios(config).then(async (response) => {
          setText(""); // Clear text before changing

          //("response: ", response);
          // This data is a ReadableStream
          const data = response.data;

          setText((prev) => prev + data.data.choices[0].message.content);
          const lines = data.data.choices[0].message.content.split("\n");

          // lines.map((line) => {
          //   setText((prev) => prev + line);
          // });

          // // remove the preceeding 'data' key in each chunk in the list
          // const parsedLines = lines
          //   .map((line) => line.replace(/^data: /, "").trim()) // Remove the "data: " prefix
          //   .filter((line) => line !== "" && line !== "[DONE]") // Remove empty lines and "[DONE]"
          //   .map((line) => JSON.parse(line)); // Parse the JSON string

          // for (const parsedLine of parsedLines) {
          //   //("stream: ", parsedLine.choices[0].text);
          //   // Update the UI with the new chunk value
          //   if (parsedLine) {
          //     setText((prev) => prev + parsedLine.choices[0].text);
          //   }
          // }
          setLoading(false);

          // const reader = data.getReader();
          // const decoder = new TextDecoder("utf-8");

          // loop through the stream until it's done
          // while (true) {
          //   // const { value, done } = await reader.read();
          //   // break the loop if the stream is done
          //   if (done) {
          //     break;
          //   }

          //   // decode the stream data as it's raw
          //   // const chunkValue = decoder.decode(value);

          //   // convert the chunk into json format
          //   // const chunkJson = JSON.parse(chunkValue);
          //   // it's multiple chunks, convert it into array / list
          //   // const lines = chunkJson.data.split("\n");
          //   const lines = data.choices[0].text.split("\n");

          //   console.log("DATA ??", data.choices);
          //   // remove the preceeding 'data' key in each chunk in the list
          //   const parsedLines = lines
          //     .map((line) => line.replace(/^data: /, "").trim()) // Remove the "data: " prefix
          //     .filter((line) => line !== "" && line !== "[DONE]") // Remove empty lines and "[DONE]"
          //     .map((line) => JSON.parse(line)); // Parse the JSON string
          //   // now, we have a chunk in a json format
          //   for (const parsedLine of parsedLines) {
          //     //("stream: ", parsedLine.choices[0].text);
          //     // Update the UI with the new chunk value
          //     if (parsedLine) {
          //       setText((prev) => prev + parsedLine.choices[0].text);
          //     }
          //   }
          // }
          // setLoading(false);
        });
    } catch (err) {
      console.log("ERROR >> ", err);
      // //(
      //   err.response.data.error.statusCode === 500 ||
      //     err.response.data.error.statusCode === 500
      // );
      //(err);
      if (
        // err.response.data.error.statusCode === 500 ||
        // err.response.data.error.statusCode === 401
        err
      ) {
        navigate("/dashboard/notfound");
      }
      toast.dismiss(); // Dismissing the loading... toast
      toast.error("Failed to load the engine");
    }
  };

  function handlePlayAudio(e) {
    if (audioObj.url.length > 0) {
      audioRef.current.src = audioObj.url;
      audioRef.current.play();
      return;
    }
    return;
  }

  return (
    // Main container
    <div className="playground">
      <div className="playground-container">
        {/* Playground Header Section */}
        <div className="playground-header">
          <h1>Playground</h1>
          <Select
            className="basic-single playground-select"
            classNamePrefix="select"
            placeholder="Load a preset..."
            isClearable={true}
            isSearchable={true}
            name="preset"
            onChange={handleChange}
            options={dropdownOptions}
          />
        </div>

        {/* Text Area Section */}
        <div className="playground-textarea">
          <textarea
            placeholder="Type text here"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <div className="tw-flex tw-items-center tw-space-x-5">
            <button
              // disabled={selectedOption === null || loading}
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <i className="fa fa-refresh fa-spin" /> : "Submit"}
            </button>
            <VoiceToText setText={setText} />
            <HiSpeakerWave
              // onClick={handleSubmit}
              className="tw-cursor-pointer"
              size={28}
            />
            {<audio className="tw-invisible" ref={audioRef} controls />}
            <button
              onClick={() => setText("")}
              // disabled={selectedOption === null || loading}
              disabled={loading}
              style={{ backgroundColor: "grey" }}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

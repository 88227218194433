import React, { useEffect } from "react";
import * as style from "./dashboard.module.css";
import { FaChartPie, FaFacebookF } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { FaTwitter } from "react-icons/fa";
import Sidebar from "../component/parts/SideBar";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import DasboardHeader from "../component/parts/DasboardHeader";
import { moveToFAQ } from "../app/features/reactScroll/reactScrollSlice";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import Spinner from "../component/chat-components/Spinner";
import Nav from "../component/parts/Nav";
import DashboardNav from "../component/parts/DashboardNav";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigateAndMoveToFAQ = () => {
    navigate("/");
    dispatch(moveToFAQ(true));
  };

  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Redux state
  const { loading, isError, message, isSuccess, user, loadingLogout } =
    useSelector((state) => state.auth);
  useEffect(() => {
    ReactGA.event("page_view", { page_title: "Dashboard Page" });
    ReactGA.send({
      page: location.pathname,
      hitType: "pageview",
      title: "Dashboard Page",
    });

    try {
      mixpanel.track("Dashboard Page");
    } catch (error) {
      console.log("Mixpanel Error : ", error);
    }
  }, []);
  return (
    <>
      {windowDimensions.width <= 1024 ? <DashboardNav /> : <DasboardHeader />}
      <section style={{ overflow: "hidden" }}>
        {user ? (
          <div>
            {/* partial:index.partial.html */}
            {/* ---------Sidebar-------- */}
            {windowDimensions.width > 1024 ? <Sidebar /> : <></>}

            {/* -----Header ----- */}
            <div className={style.main_content}>
              {/* <DasboardHeader /> */}
              {/*  ----Main Application-----  */}
              <Outlet />

              {/* ----Footer---- */}
            </div>
            <div className={style.projects}>
              <div className={style.card}>
                <div
                  className={style.card_header}
                  style={{
                    marginLeft:
                      windowDimensions.width <= 1024 ? "0px" : "345px",
                  }}
                >
                  <div className={style.card_social}>
                    <a
                      style={{ marginLeft: "1rem" }}
                      color="black"
                      href="https://www.facebook.com/DeepTuring"
                      target="_blank"
                    >
                      <FaFacebookF style={{ fontSize: "1.5rem" }} />
                    </a>
                    <a
                      style={{ marginLeft: "1rem" }}
                      color="black"
                      href="https://www.instagram.com/deepturing/"
                      target="_blank"
                    >
                      <FiInstagram style={{ fontSize: "1.5rem" }} />
                    </a>
                    <a
                      style={{ marginLeft: "1rem" }}
                      color="black"
                      href="https://twitter.com/DeepTuring"
                      target="_blank"
                    >
                      <FaTwitter style={{ fontSize: "1.5rem" }} />
                    </a>
                  </div>

                  <div
                    style={{
                      position: "static",
                      left: "50%",
                      bottom: "0",
                      zIndex: "10",
                      margin: "0px auto",
                    }}
                  >
                    <span>
                      <Link to="/about" style={{ color: "black" }}>
                        About
                      </Link>
                      &nbsp;|&nbsp;
                    </span>
                    <span>
                      <span onClick={navigateAndMoveToFAQ}>
                        <Link style={{ color: "black" }}>FAQ</Link>
                      </span>
                      &nbsp;|&nbsp;
                    </span>
                    <span>
                      <Link to="/tos" style={{ color: "black" }}>
                        Term of service
                      </Link>
                      &nbsp;|&nbsp;
                    </span>
                    <span>
                      <Link to="/privacy" style={{ color: "black" }}>
                        privacy policy
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
              {/* partial */}
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </section>
    </>
  );
};

export default Dashboard;

export const validateFormFields = (values, fieldErrors) => {
  let validationErrors = {};

  for (const [fieldName, value] of Object.entries(values)) {
    if (!value) {
      validationErrors = {
        ...validationErrors,
        [fieldName]: fieldErrors[fieldName],
      };
    }
  }
  return validationErrors;
};

export const statusDetail = [
  { name: "Online", color: "#4bd94b", value: "1" },
  { name: "Offline", color: "grey", value: "2" },
  { name: "Away", color: "#fdbb40de", value: "3" },
];

import React from "react";
import BarChart from "./barchart";
import LineChart from "./lineChart";
import PieChart from "./pieChart";

function ChartType({ chartType, chartData, options }) {
  switch (chartType) {
    case "Bar":
      return <BarChart chartData={chartData} options={options} />;
    case "Line":
      return <LineChart chartData={chartData} options={options} />;
    case "Pie":
      return <PieChart chartData={chartData} options={options} />;
    default:
  }
}

export default ChartType;

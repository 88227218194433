import React from "react";
import style from "./thankyou.module.css";

const Thankyou = () => {
  return (
    <>
      <div className={style.content}>
        <div className={style.wrapper_1}>
          <div className={style.wrapper_2}>
            <h1>Thank you !</h1>
            <p>Thanks for subscribing to our news letter.</p>
            <p>you should receive a confirmation email soon</p>
            <button className={style.go_home}>go home</button>
          </div>
          <div className={style.footer_like}>
            <p>
              Email not received?
              <a href="#">Click here to send again</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thankyou;

import axios from "axios";
export const getChat = async (
  roomId,
  setRules,
  setCachedChat,
  setInitialLoading
) => {
  try {
    setInitialLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/chats/${roomId}`,
      {},
      config
    );
    if (response) {
      setRules(response.data.rules);
      // setCacheChatInClient(response.data.chats);
      setCachedChat(response.data.chats);
      setInitialLoading(false);
    }
  } catch (error) {
    setInitialLoading(false);
    //("error.response.data::: ", error);
  }
};

export const setChannelsCache = (channels) => {
  if (channels && channels.length === 0) {
    return null;
  }
  let channelsList = getChannelCache();
  let rooms = [];

  if (channelsList.length === 0) {
    new Promise((resolve, reject) => {
      channels.map((_channel) => {
        let channelRoom = _channel.rooms;
        channelRoom.map((_channelRoom) => {
          _channelRoom.chats = [];
          rooms.push(_channelRoom);
        });
        return rooms;
      });

      return resolve(rooms);
    }).then((_rooms) => {
      localStorage.setItem("channelChats", JSON.stringify(rooms));
    });
  } else {
    new Promise((resolve, reject) => {
      channels.map((_channel) => {
        let channelRoom = _channel.rooms;
        channelRoom.map((_channelRoom) => {
          let isNewRoom = channelsList.find(
            (_oldChannel) => _oldChannel.uuid !== _channelRoom.uuid
          );
          if (isNewRoom) {
            isNewRoom.chats = [];
            rooms.push(isNewRoom);
          } else {
            rooms.push(isNewRoom);
          }
        });
      });
    }).then((_rooms) => {
      localStorage.setItem("channelChats", JSON.stringify(rooms));
    });
  }
};

export const getChannelCache = () => {
  let chats = localStorage.getItem("channelChats");
  return chats ? JSON.parse(chats) : [];
};

export const setChatsInChannels = (chats, roomId) => {
  console.log("SET CHAT IN CHANNEls", chats.length);
  if (!chats) {
    return [];
  }
  if (chats.length == 0) {
    return [];
  }
  let channels = getChannelCache();

  let channel = channels.find((_channel) => _channel.uuid == roomId);

  if (!chats && chats.length === channel.chats.length) {
    return [];
  }
  if (channels.length == 0) {
    channel.chats.push(chats);
  }
  if (!channel || channel.length == 0) {
    return [];
  }

  chats.map((_chat, idx) => {
    if (_chat.room != roomId) {
      setNewMessages(_chat);
      return null;
    }
  });
};

export const updateRoom = (roomId, channel) => {
  let channels = getChannelCache();
  let selectedRoomIdx = channels.findIndex(
    (_channel) => _channel.uuid == roomId
  );
  if (selectedRoomIdx >= 0) {
    channels[selectedRoomIdx] = channel;
    localStorage.setItem("channelChats", JSON.stringify(channels));
  }
};

export const setNewMessages = (chat) => {
  let prevMsg = getNewMessages();
  let isRoomExist = prevMsg.find((_prev) => _prev.room == chat.room);

  if (isRoomExist) {
    let idxOfRoom = prevMsg.indexOf(isRoomExist);
    let isChatExist = isRoomExist.chats.findIndex(
      (_chat) => _chat.chat.uuid == chat.chat.uuid
    );
    if (isChatExist >= 0) {
      return null;
    }

    isRoomExist.chats.push(chat);
    prevMsg[idxOfRoom] = isRoomExist;
  } else {
    let room = {
      room: chat.room,
      chats: [chat],
    };
    prevMsg.push(room);
  }
  localStorage.setItem("new_msg", JSON.stringify(prevMsg));
};

export const getNewMessages = () => {
  let chats = localStorage.getItem("new_msg");
  return chats ? JSON.parse(chats) : [];
};

export const setSeenMessage = (roomId) => {
  if (!roomId) {
    return false;
  }

  let newMessages = getNewMessages();
  let seenMsg = newMessages.filter((_msg) => _msg.room != roomId);
  if (seenMsg) {
    localStorage.setItem("new_msg", JSON.stringify(seenMsg));
  }
};

export const getChatsInChannel = (roomId) => {
  if (!roomId) {
    return [];
  }

  let chats = localStorage.getItem("cacheChats");
  chats.filter((_chat) => _chat.room === roomId);
  return chats ? JSON.parse(chats) : [];
};

export const setCacheChatInClient = (chats) => {
  //("FILTERR >>>", chats.length, getCacheChatInClient().length);
  if (chats.length >= getCacheChatInClient().length) {
    localStorage.setItem("cacheChats", JSON.stringify(chats));
  }
};

export const getCacheChatInClient = () => {
  let chats = localStorage.getItem("cacheChats");
  return chats ? JSON.parse(chats) : [];
};

export const setUnseenMsg = (data) => {
  let cachedChats = getCacheChatInClient();

  //("DATA ??? ", { data });
  if (cachedChats.length > 0) {
    let isFoundInCached =
      cachedChats.findIndex((_cached) => _cached.room === data.room) === 0
        ? true
        : false;
    if (!isFoundInCached) {
      localStorage.setItem("unseen", JSON.stringify(data));
    }
  } else {
    localStorage.setItem("unseen", JSON.stringify(data));
  }
};

export const getUnseenMsg = () => {
  let chats = localStorage.getItem("unseen");
  return chats ? JSON.parse(chats) : [];
};

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import Footer from "./parts/mainFooter";
import Header from "./parts/mainHeader";
import Nav from "./parts/Nav";
import engine from "./img/19e0226c3078401f8782f6c24aac0d9e-2-2.png";
import openSource from "./img/19e0226c3078401f8782f6c24aac0d9e-1-1-1.png";
import Typewriter from "react-ts-typewriter";
import { Element } from "react-scroll";
import worldmap from "../assets/worldmap.mp4";
import { sendEmail } from "../Service/sendEmail";
import codeAlternateVideo from "../assets/code-alternative-suggestion-compressed.mp4";
import playgroundVideo from "../assets/Project-3-playground-demo-compressed.mp4";
import sandboxVideo from "../assets/Project-2-sandbox-cropped.mp4";
// import { Link as ScrollLink, scroller } from "react-scroll";

const MainPage = () => {
  const [contactFormData, setContactFormData] = useState({
    name: "",
    email: "",
    subject: "Question",
    message: "",
  });

  const [contactFormSuccess, setContactFormSuccess] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { name, email, subject, message } = contactFormData;

    try {
      console.log(name, email, subject, message);
      const response = await sendEmail(name, email, subject, message);
      console.log(response.data);
      setContactFormSuccess(true);
    } catch (error) {
      console.log(error);
      setContactFormSuccess(false);
    }
  };

  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    try {
      // Track the home page event
      mixpanel.track("Main Page");
    } catch (error) {
      console.log("Mixpanel Error at Main Page: ", error);
    }
    ReactGA.event("page_view", { page_title: "Main Page" });
    ReactGA.send({
      page: location.pathname,
      hitType: "pageview",
      title: "Main Page",
    });
  }, []);

  const subscribeEnterprise = () => {
    ReactGA.event("purchase", {
      page_title: "Pricing Page",
      currency: "USD",
      value: 15.99,
      items: [{ item_id: "ENTERPRISE_TIER", item_name: "Enterprise Tier" }],
    });
    ReactGA.send({
      page: location.pathname,
      hitType: "pageview",
      title: "Pricing Page",
    });
    navigate("/login");
  };
  const subscribePro = () => {
    ReactGA.event("purchase", {
      page_title: "Pricing Page",
      currency: "USD",
      value: 7.99,
      items: [{ item_id: "PRO_TIER", item_name: "Pro Tier" }],
    });
    ReactGA.send({
      page: location.pathname,
      hitType: "pageview",
      title: "Pricing Page",
    });
    navigate("/login");
  };
  const subscribeFree = () => {
    ReactGA.event("purchase", {
      page_title: "Pricing Page",
      currency: "USD",
      value: 0.0,
      items: [{ item_id: "BASIC_TIER", item_name: "Basic Tier" }],
    });
    ReactGA.send({
      page: location.pathname,
      hitType: "pageview",
      title: "Pricing Page",
    });
    navigate("/login");
  };

  return (
    <>
      <Nav />
      <Element name="home">
        <Header />
      </Element>
      {/* GET STARTED */}
      <section className="py-10 py-md-20 bg-bg-3 text-center">
        <div className="container">
          <p data-aos="fade-down" data-aos-delay="0" className="fs-1 mb-4 ">
            Get Started
          </p>
          <h2
            className="display-5 mb-6"
            data-aos="fade-down"
            data-aos-delay="150"
          >
            Code Faster with AI Completions
          </h2>
          <p
            className="fs-2 mb-0 text-dark-1 remove-br-sm"
            data-aos="fade-down"
            data-aos-delay="250"
          >
            DeepTuring offers ideas and autocompletes entire lines or sections
            of code, automating tiresome work and eliminating the need for
            pricey and annoying code search.
            <br />
            DeepTuring can write 50% or even more of the code for you
          </p>
        </div>
      </section>
      {/* APLLICATION */}
      <Element name="feature">
        <section className="py-10 py-lg-20 bg-bg-3 text-center text-lg-start">
          <div className="container">
            <div className="row align-items-center justify-content-center justify-content-lg-start ">
              <div className="col-8 col-md-6">
                {/* <img
                  src={callToAction}
                  // srcset="i/call-to-action-3@2x.png 2x"
                  alt=""
                  className="img-fluid"
                  data-aos="fade-down"
                  data-aos-delay="0"
                /> */}
                <div
                  className="border-2 border-black"
                  data-aos="fade-down"
                  data-aos-delay="250"
                >
                  <video
                    data-aos="fade-down"
                    data-aos-delay="0"
                    className="p-1 h-auto w-100 aspect-video"
                    autoPlay
                    loop
                    muted
                  >
                    <source src={codeAlternateVideo} type="video/mp4" />
                  </video>
                </div>
              </div>
              <div className="ps-lg-12 ps-xl-3 col-sm-10 col-md-8 col-lg-6 col-xl-5">
                <h2
                  className="display-5 mb-6 mt-8 mt-lg-0"
                  data-aos="fade-down"
                  data-aos-delay="250"
                  style={{ textAlign: "left" }}
                >
                  World’s best AI for your programming needs.
                </h2>
                <p
                  className="fs-1 mb-8 text-bg-1"
                  data-aos="fade-down"
                  data-aos-delay="500"
                  style={{ textAlign: "left" }}
                >
                  DeepTuring is democratizing software development. Whether
                  you’re just getting started, or a professional looking to
                  optimize the most out of your work, DeepTuring dramatically
                  improves your coding efforts
                </p>
                <div className="accordion" id="custom-id-2q1z5ncb">
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <button
                        className="accordion-button fs-2 text-bg-1 fw-bold"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion_collapse_1"
                        aria-expanded="true"
                      >
                        Code Autocompleter
                      </button>
                    </div>
                    <div
                      id="accordion_collapse_1"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#custom-id-2q1z5ncb"
                    >
                      <div className="accordion-body text-start">
                        <p className="fs-3 fw-bold text-bg-1">
                          DeepTuring provides an AI Code assistance that
                          generates and autocomplete code that helps you write
                          code faster and with less work, Cutting development
                          time
                        </p>

                        <p className="mb-0" style={{cursor:"pointer"}}>
                          {/* <ScrollLink to="faq">learn more</ScrollLink> */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <img
                      src=""
                      id="js-get-smile"
                      className="visually-hidden"
                      style={{ opacity: "0.01" }}
                      alt=""
                    />
                    <div className="accordion-header">
                      <button
                        className="accordion-button fs-1 text-bg-1 fw-bold collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion_collapse_2"
                        aria-expanded="false"
                      >
                        Convert comments to code
                      </button>
                    </div>
                    <div
                      id="accordion_collapse_2"
                      className="accordion-collapse collapse"
                      data-bs-parent="#custom-id-2q1z5ncb"
                    >
                      <div className="accordion-body text-start">
                        <p className="mb-0 fw-bold fs-3 text-bg-1">
                          Write a comment describing the logic you want and let
                          DeepTuring assemble the code for you.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <button
                        className="accordion-button fs-1 text-bg-1 fw-bold collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion_collapse_3"
                        aria-expanded="false"
                      >
                        Programming language Converter
                      </button>
                    </div>
                    <div
                      id="accordion_collapse_3"
                      className="accordion-collapse collapse"
                      data-bs-parent="#custom-id-2q1z5ncb"
                    >
                      <div className="accordion-body text-start">
                        <p className="mb-0 fw-bold fs-3 text-bg-1">
                          Need to swap languages or frameworks completely? Is
                          that one code snippet written only in Java? Easily
                          convert it to Python or another language using our
                          conversion feature
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <button
                        className="accordion-button fw-bold fs-1 text-bg-1 collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#custom-id-9c74sigw"
                        aria-expanded="false"
                      >
                        Debugging and Code Restructure
                      </button>
                    </div>
                    <div
                      id="custom-id-9c74sigw"
                      className="accordion-collapse collapse"
                      data-bs-parent="#custom-id-2q1z5ncb"
                    >
                      <div className="accordion-body text-start">
                        <p className="mb-0 fw-bold fs-3 text-bg-1">
                          Spend less time solving bugs and more time building.
                          <br />
                          DeepTuring reviews and debugs your code in real-time,
                          so you can focus on completing your task at hand.{" "}
                          <br />
                          Our AI also restructures your code for optimum
                          efficiency. It improves your coding technique by
                          rephrasing and reducing unnecessary lines of code and
                          syntax to ensure a clean end result.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <button
                        className="accordion-button fw-bold fs-1 text-bg-1 collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#custom-id-5eybxw0e"
                        aria-expanded="false"
                      >
                        Tests without the toil.
                      </button>
                    </div>
                    <div
                      id="custom-id-5eybxw0e"
                      className="accordion-collapse collapse"
                      data-bs-parent="#custom-id-2q1z5ncb"
                    >
                      <div className="accordion-body text-start">
                        <p className="mb-0 fw-bold fs-3 text-bg-1">
                          Tests are the backbone of any robust software
                          engineering project. Import a unit test package, and
                          let DeepTuring suggest tests that match your
                          implementation code.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <button
                        className="accordion-button fw-bold fs-1 text-bg-1 collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#custom-id-diz65n2u"
                        aria-expanded="false"
                      >
                        Real time Alternatives suggestions
                      </button>
                    </div>
                    <div
                      id="custom-id-diz65n2u"
                      className="accordion-collapse collapse"
                      data-bs-parent="#custom-id-2q1z5ncb"
                    >
                      <div className="accordion-body text-start">
                        <p className="mb-0 fw-bold fs-3 text-bg-1">
                          Want to evaluate a few different approaches?
                          DeepTuring can show you a list of solutions. Use the
                          code as provided, or edit it to meet your needs
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  href="#"
                  className="btn me-4 btn-bg-1 btn-lg float-xxl-start"
                  data-aos="fade-down"
                  data-aos-delay="650"
                  style={{ marginTop: "50px" }}
                >
                  Get Started
                </a>
                <a
                  href="#"
                  className="btn btn-outline-dark-2  btn-lg float-xxl-start"
                  data-aos="fade-down"
                  data-aos-delay="750"
                  style={{ marginTop: "50px" }}
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </section>
      </Element>
      {/* <!-- Feature 1 --> */}
      <section className="py-10 py-lg-20 text-center bg-bg-3"></section>

      {/* <!-- Application 2 --> */}
      <section className="py-10 py-lg-20 bg-bg-3 text-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-11 col-lg-8 col-xl-7 col-xxl-6">
              <h2
                className="display-5 mb-6"
                data-aos="fade-down"
                data-aos-delay="0"
                style={{ paddingLeft: "1rem" }}
              >
                <span>{"  "}</span>
                <Typewriter
                  text=" Used Worldwide"
                  loop={true}
                  delay={1200}
                  speed={60}
                />
              </h2>
              <p
                className="mb-15 fs-1 text-bg-1"
                data-aos="fade-down"
                data-aos-delay="150"
              >
                Automate Remote Knowledge Sharing. We serve the global market so
                users can share knowledge and code with their team members
                effortlessly across countries and time zones allowing you to
                focus more on deployment, less on coding
              </p>
            </div>
          </div>
          <div
            className="p-15 bg-bg-2"
            data-aos="fade-down"
            data-aos-delay="250"
          >
            <video
              src={worldmap}
              className="img-fluid"
              autoPlay
              muted
              loop
            ></video>
          </div>
        </div>
      </section>
      <section className="py-10 py-lg-20 bg-bg-3 text-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-11 col-lg-8 col-xl-7 col-xxl-6">
              <h2
                className="display-5 mb-6"
                data-aos="fade-down"
                data-aos-delay="0"
              >
                Your big idea starts here
              </h2>
              <p
                className="mb-15 fs-1 text-bg-1"
                data-aos="fade-down"
                data-aos-delay="150"
              >
                Let our tool do your coding for you, fulfilling your software
                needs, providing assistance and generating clean, bug free
                software within seconds. Focus more on deployment, less on
                typing.
              </p>
            </div>
          </div>
          <div
            className="p-15 bg-bg-2"
            data-aos="fade-down"
            data-aos-delay="250"
          >
            {/* <img
              src={placeholder}
              //   srcset="
              //   ./img/map.gif 2x
              // "
              alt=""
              className="img-fluid w-75 h-75"
            /> */}

            <div
              className="mb-35 border-2 border-black"
              data-aos="fade-down"
              data-aos-delay="250"
            >
              <video
                data-aos="fade-down"
                data-aos-delay="0"
                className="w-100 h-auto p-2 aspect-video"
                autoPlay
                loop
                muted
              >
                <source src={playgroundVideo} type="video/mp4" />
              </video>
            </div>
            <div
              className="border-2 border-black"
              data-aos="fade-down"
              data-aos-delay="250"
            >
              <video
                data-aos="fade-down"
                data-aos-delay="0"
                className="w-100 h-auto p-2 aspect-video"
                autoPlay
                loop
                muted
              >
                <source src={sandboxVideo} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Application 1 --> */}
      <section className="py-10 py-lg-20 bg-bg-3 text-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8">
              <h2
                className="display-5 mb-6 text-bg-1"
                data-aos="fade-down"
                data-aos-delay="150"
              >
                How does it Work?
              </h2>
            </div>
            <div className="col-md-8 col-lg-6 col-xl-5 col-xxl-5 mb-15">
              <p
                className="mb-0 fs-1 fw-bold text-bg-1"
                data-aos="fade-down"
                data-aos-delay="250"
              >
                DeepTuring AI tool
                <br />
                Our easy to use tool combines our DeepTuring Ai engine with
                custom trained datasets into one place for you within our
                product
              </p>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-6 mb-4 mb-md-0"
              data-aos="fade-down"
              data-aos-delay="400"
            >
              <div className="py-15 h-100 bg-bg-2">
                <img
                  src={openSource}
                  //   srcset="
                  //   pub/185029/ea555aaade9698f7280435fa483018d0/uploads/19e0226c3078401f8782f6c24aac0d9e-1-1-1.png 2x
                  // "
                  alt=""
                  className="img-fluid mb-8 w-75"
                />
                <h2 className="text-bg-1 fs-1">
                  Opensource Trained Ai
                  <br />
                  The Ai Model DeepTuring uses was trained on more than a
                  billion lines of free to use open-source code.
                </h2>
              </div>
            </div>
            <div className="col-md-6" data-aos="fade-down" data-aos-delay="500">
              <div className="py-15 h-100 bg-bg-2">
                <img
                  src={engine}
                  //   srcset="
                  //   pub/185029/ea555aaade9698f7280435fa483018d0/uploads/19e0226c3078401f8782f6c24aac0d9e-2-2.png 2x
                  // "
                  alt=""
                  className="img-fluid mb-8 w-75"
                />
                <h6 className="mb-0 fs-1 fw-bold text-bg-1">
                  DeepTuring Ai Engine
                  <br />
                  DeepTuring Ai Engine is built on advanced datasets. It is then
                  finetuned to unlock additional uses, It is best in code
                  generation.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Content 2--> */}
      <Element name="faq">
        <section className="py-10 py-lg-20 text-center bg-bg-3">
          <div className="container">
            <h2
              className="display-5 mb-6 text-bg-1"
              data-aos="fade-down"
              data-aos-delay="0"
            >
              Frequently Asked Questions
            </h2>
            <div className="accordion" id="custom-id-7e7v5ecl">
              <div className="accordion-item">
                <div className="accordion-header">
                  <button
                    className="accordion-button fs-1 text-bg-1 fw-bold"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion_collapse_1"
                    aria-expanded="true"
                  >
                    What is DeepTuring AI?
                  </button>
                </div>
                <div
                  id="accordion_collapse_1"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#custom-id-7e7v5ecl"
                >
                  <div className="accordion-body text-start">
                    <p className="fs-3 text-bg-1">
                      DeepTuring provides an AI Code assistance that
                      autocomplete code, generates code and helps you write code
                      faster and with less work, cutting development time.
                      <br />
                      DeepTuring draws context from comments and code and
                      suggests individual lines and whole functions instantly.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header">
                  <button
                    className="accordion-button fw-bold fs-1 text-bg-1 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion_collapse_2"
                    aria-expanded="false"
                  >
                    How does DeepTuring AI work?
                  </button>
                </div>
                <div
                  id="accordion_collapse_2"
                  className="accordion-collapse collapse"
                  data-bs-parent="#custom-id-7e7v5ecl"
                >
                  <div className="accordion-body text-start">
                    <p className="mb-0 fs-3 text-bg-1">
                      DeepTuring AI Model is capable of understanding both
                      programming and human languages because it was trained on
                      freely accessible source code and natural language.
                      <br />
                      Your comments and code are sent to DeepTuring AI when
                      using any of our DeepTuring's tools, which uses its
                      trained artificial intelligence model to synthesize and
                      suggest individual lines as well as entire functions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header">
                  <button
                    className="accordion-button fw-bold fs-1 text-bg-1 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion_collapse_3"
                    aria-expanded="false"
                  >
                    What programming languages do DeepTuring AI knows?
                  </button>
                </div>
                <div
                  id="accordion_collapse_3"
                  className="accordion-collapse collapse"
                  data-bs-parent="#custom-id-7e7v5ecl"
                >
                  <div className="accordion-body text-start">
                    <p className="mb-0 fs-3 text-bg-1">
                      DeepTuring is most adept in Python, but it is also
                      proficient in several languages including JavaScript,
                      Node.js, Go, Perl, PHP, Ruby, Swift, Objective C, C , C#,
                      C++, Java, TypeScript, and even Shell, In addition to SQL,
                      HTML, CSS
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion" id="custom-id-eqfic85g">
              <div className="accordion-item">
                <div className="accordion-header">
                  <button
                    className="accordion-button fw-bold fs-1 text-bg-1 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#custom-id-vsu2i6x9"
                    aria-expanded="false"
                  >
                    Does DeepTuring AI write perfect code?
                  </button>
                </div>
                <div
                  id="custom-id-vsu2i6x9"
                  className="accordion-collapse collapse"
                  data-bs-parent="#custom-id-7e7v5ecl"
                >
                  <div className="accordion-body text-start">
                    <p className="fs-3 text-bg-1">
                      Not 100% but very close! While DeepTuring AI makes every
                      effort to comprehend your purpose and provide the best
                      code it can, the code it produces might not always be
                      flawless. As we continue to strive toward making
                      DeepTuring AI smarter, code recommended by DeepTuring AI
                      should be thoroughly examined, tested, and verified, just
                      like any other code.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header">
                  <button
                    className="accordion-button fw-bold fs-1 text-bg-1 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#custom-id-nf1gmiy6"
                    aria-expanded="false"
                  >
                    How do I get the most out of DeepTuring AI?
                  </button>
                </div>
                <div
                  id="custom-id-nf1gmiy6"
                  className="accordion-collapse collapse"
                  data-bs-parent="#custom-id-7e7v5ecl"
                >
                  <div className="accordion-body text-start">
                    <p className="mb-0 fs-3 text-bg-1">
                      The best results come from breaking up your code into
                      manageable functions, giving your function parameters
                      sensible names, and providing clear docstrings and
                      comments as you go. Additionally, it appears to perform
                      best when guiding you through unknown libraries or
                      frameworks.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header">
                  <button
                    className="accordion-button fw-bold fs-1 text-bg-1 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#custom-id-mz8gdqyb"
                    aria-expanded="false"
                  >
                    What data has DeepTuring AI been trained on?
                  </button>
                </div>
                <div
                  id="custom-id-mz8gdqyb"
                  className="accordion-collapse collapse"
                  data-bs-parent="#custom-id-7e7v5ecl"
                >
                  <div className="accordion-body text-start">
                    <p className="mb-0 fs-3 text-bg-1">
                      The Ai Model that powers DuringTuring has been trained on
                      a selection of English language and source code from
                      publicly available sources, including code in public
                      repositories on GitHub.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion" id="custom-id-rkknz0p0">
              <div className="accordion-item">
                <div className="accordion-header">
                  <button
                    className="accordion-button fs-1 fw-bold text-bg-1 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#custom-id-v31sbayv"
                    aria-expanded="false"
                  >
                    Why was DeepTuring AI trained on data from publicly
                    available sources?
                  </button>
                </div>
                <div
                  id="custom-id-v31sbayv"
                  className="accordion-collapse collapse"
                  data-bs-parent="#custom-id-7e7v5ecl"
                >
                  <div className="accordion-body text-start">
                    <p className="fs-3 text-bg-1">
                      Training machine learning models on publicly available
                      data is considered fair use across the machine learning
                      community. The models gain insight and accuracy from the
                      public collective intelligence.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header">
                  <button
                    className="accordion-button fw-bold fs-1 text-bg-1 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#custom-id-kdzjktve"
                    aria-expanded="false"
                  >
                    What is DeepTuring Code Assistant (codebot)?
                  </button>
                </div>
                <div
                  id="custom-id-kdzjktve"
                  className="accordion-collapse collapse"
                  data-bs-parent="#custom-id-7e7v5ecl"
                >
                  <div className="accordion-body text-start">
                    <p className="mb-0 fs-3 text-bg-1">
                      DeepTuring Code Assistant (codebot) is an AI Chatbot that
                      Revolutionize your coding experience with a chat interface
                      designed specifically for developers, seamlessly
                      integrated into our Coderoom and Extension (coming soon).
                      It's more than just a code suggestion tool – DeepTuring
                      code Assistant is a powerful companion.{" "}
                      {/*deeply embedded within your IDE.*/} It understands the
                      code you type, identifies error messages, and offers
                      comprehensive analysis and insights. You'll receive
                      in-depth explanations of code blocks, automated generation
                      of unit tests, and even proposed fixes for bugs. With
                      DeepTuring Code Assistant (codebot), coding becomes a
                      collaborative conversation that enhances your productivity
                      and accelerates your development workflow. Elevating your
                      coding game.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header">
                  <button
                    className="accordion-button fw-bold fs-1 text-bg-1 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#custom-id-kdzjktve"
                    aria-expanded="false"
                  >
                    Who owns the code DeepTuring AI generates?
                  </button>
                </div>
                <div
                  id="custom-id-kdzjktve"
                  className="accordion-collapse collapse"
                  data-bs-parent="#custom-id-7e7v5ecl"
                >
                  <div className="accordion-body text-start">
                    <p className="mb-0 fs-3 text-bg-1">
                      DeepTuring AI is a tool. DeepTuring AI's recommendations
                      and the code you produce with its assistance are yours,
                      and you hold full responsibility for them. It is advised
                      that you thoroughly test, examine, and inspect the code,
                      just as you would with any code you personally develop.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header">
                  <button
                    className="accordion-button fw-bold fs-1 text-bg-1 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#custom-id-4hkgjbr5"
                    aria-expanded="false"
                  >
                    Will my private code be shared with other users?
                  </button>
                </div>
                <div
                  id="custom-id-4hkgjbr5"
                  className="accordion-collapse collapse"
                  data-bs-parent="#custom-id-7e7v5ecl"
                >
                  <div className="accordion-body text-start">
                    <p className="mb-0 fs-3 text-bg-1">
                      Absolutely NOT. Your private code is not used as
                      suggestions or generated code for other users. We NEVER
                      store or share any of your code. We do not retain any user
                      code regardless of inputting or using our features at any
                      given point.
                      <br />
                      You also have complete control over your user data and
                      produced code.
                    </p>
                  </div>
                </div>
              </div>
              {/*<div className="accordion-item">
                <div className="accordion-header">
                  <button
                    className="accordion-button fw-bold fs-1 text-bg-1 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#custom-id-woiwgt3i"
                    aria-expanded="false"
                  >
                    What are Tokens?
                  </button>
                </div>
                <div
                  id="custom-id-woiwgt3i"
                  className="accordion-collapse collapse"
                  data-bs-parent="#custom-id-7e7v5ecl"
                >
                  <div className="accordion-body text-start">
                    <p className="mb-0 fs-3 text-bg-1">
                      Tokens are pieces of words used for natural language
                      processing. Words are broken up for interpreting and
                      processing by the AI. For English text, 1 token is
                      approximately 4 characters or 0.75 words.
                    </p>
                  </div>
                </div>
          </div>*/}
              {/*<div className="accordion-item">
                <div className="accordion-header">
                  <button
                    className="accordion-button fw-bold fs-1 text-bg-1 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#custom-id-kv6fhu4g"
                    aria-expanded="false"
                  >
                    How is the pricing plan done?
                  </button>
                </div>
                <div
                  id="custom-id-kv6fhu4g"
                  className="accordion-collapse collapse"
                  data-bs-parent="#custom-id-7e7v5ecl"
                >
                  <div className="accordion-body text-start">
                    <p className="mb-0 fs-3 text-bg-1">
                      Token requests are billed based on the number of tokens in
                      the inputs you provide and the answer that our Ai model
                      generates.
                      <br />
                      Basic Tier is a pay- as-you-go model costing only $0.18
                      per 1000 Tokens <br />
                      The Pro Tier is a monthly subscription which gives you
                      10,000 Tokens usage, once the tokens have been used up,
                      you will be billed $0.18 per 1000 additional tokens on a
                      pay as you go basis. <br />
                      Our Enterprise Tier is a monthly subscription which gives
                      you 100,000 Tokens usage and once used up, you will be
                      billed $0.18 for 1000 additional tokens per seat on a pay
                      as you go basis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header">
                  <button
                    className="accordion-button fw-bold fs-1 text-bg-1 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#custom-id-0hx35y0j"
                    aria-expanded="false"
                  >
                    When is the payment processed?
                  </button>
                </div>
                <div
                  id="custom-id-0hx35y0j"
                  className="accordion-collapse collapse"
                  data-bs-parent="#custom-id-7e7v5ecl"
                >
                  <div className="accordion-body text-start">
                    <p className="mb-0 fs-3 text-bg-1">
                      Monthly Subscriptions will be processed at the beginning
                      of your billing cycle.
                      <br />
                      Pro and Enterprise Tier users will see their additional
                      token usage for the month (if incurred) charged at the end
                      of the billing cycle.
                    </p>
                  </div>
                </div>
          </div>*/}
              <div className="accordion-item">
                <div className="accordion-header">
                  <button
                    className="accordion-button fw-bold fs-1 text-bg-1 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#custom-id-0pd8s0e0"
                    aria-expanded="false"
                  >
                    What is DeepTuring Coderoom?
                  </button>
                </div>
                <div
                  id="custom-id-0pd8s0e0"
                  className="accordion-collapse collapse"
                  data-bs-parent="#custom-id-7e7v5ecl"
                >
                  <div className="accordion-body text-start">
                    <p className="mb-0 fs-3 text-bg-1">
                      DeepTuring CodeRoom is a Free online community that
                      connects developers, non-developers, newbies, tech
                      enthusiast etc who are interested in programming or tech
                      related topics to give or receive real time assistance to
                      each other, collaborate on projects, making connections
                      and friendships or simply just a place to hangout and
                      chat.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header">
                  <button
                    className="accordion-button fw-bold fs-1 text-bg-1 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#custom-id-0pd8s0e1"
                    aria-expanded="false"
                  >
                    Do you provide code attribution for Ai generated code?
                  </button>
                </div>
                <div
                  id="custom-id-0pd8s0e1"
                  className="accordion-collapse collapse"
                  data-bs-parent="#custom-id-7e7v5ec1"
                >
                  <div className="accordion-body text-start">
                    <p className="mb-0 fs-3 text-bg-1">
                      Absolutely! We believe in crediting and providing
                      attribution to authors and developers of public code, so
                      we provide the ability within our extension that scans and
                      identify code strings generated that matches with public
                      code and provide a list of repository with similar code,
                      allowing you the opportunity to provide attribution where
                      appropriate. All you have to do is highlight the code
                      snippets or double click the line of code, wait for the
                      popup, click view matches and it will find the results for
                      you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>
      {/* <!-- Pricing 2 --> */}
      <Element name="pricing">
        <section className="py-10 py-lg-20 bg-bg-1">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-10 col-xl-8">
                <h2
                  className="display-5 mb-6 text-light-1"
                  data-aos="fade-down"
                  data-aos-delay="0"
                >
                  Pricing &amp; Plans
                </h2>
              </div>
              <div className="col-md-9 col-lg-7 col-xl-6 col-xxl-5 mb-10">
                <p
                  className="fs-2 mb-0 text-light-2"
                  data-aos="fade-down"
                  data-aos-delay="150"
                >
                  Start developing in half the time with an
                  <span className="text-nowrap"> all-in-one </span>
                  AI Coder - no more long hours spent on the boring stuff! As
                  we're currently in Beta, Enjoy our Pro Tier plan for FREE
                </p>
              </div>
            </div>
            <label
              className="form-check form-switch text-center mb-12"
              data-aos="fade-down"
              data-aos-delay="250"
            >
              <span className="form-check-label font-heading fs-4 me-4 text-light-1">
                Monthly
              </span>
            </label>
            <div
              id="custom-id-0ul77e57"
              className="carousel slide carousel-fade"
              data-bs-interval="false"
              data-bs-touch="false"
              data-aos="fade-down"
              data-aos-delay="400"
            >
              <div className="carousel-inner">
                <div className="carousel-item bg-bg-1">
                  <div className="row justify-content-center">
                    <div className="mb-8 mb-lg-0 col-lg-4 col-md-7 col-sm-10">
                      <div className="d-flex flex-column justify-content-between h-100 p-6 border rounded-2 bg-dark-2 border-dark-2">
                        <div>
                          <h2 className="mb-4 text-light-1">Demo monthly</h2>
                          <p className="mb-6 text-light-2">
                            The website builder to help you grow
                            <span className="text-nowrap"> - free </span>
                            from bugs and errors.
                          </p>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              Many Components
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              Based on Bootstrap
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              HTML, CSS, LESS
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">Block Model</h6>
                          </div>
                        </div>
                        <a href="#" className="mt-6 btn w-100 btn-bg-3">
                          Get started for Free
                        </a>
                      </div>
                    </div>
                    <div className="mb-8 mb-lg-0 col-lg-4 col-md-7 col-sm-10">
                      <div className="d-flex flex-column justify-content-between h-100 p-6 border rounded-2 bg-bg-1 border-light-3">
                        <div>
                          <h2 className="mb-4 text-light-1">Pro monthly</h2>
                          <p className="mb-6 text-light-2">
                            Get your idea out there in the way you want it.
                            Start with Startup.
                          </p>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="fill-action-1 position-absolute top-50 translate-middle-y"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              Many Components
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="fill-action-1 position-absolute top-50 translate-middle-y"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              Based on Bootstrap
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="fill-action-1 position-absolute top-50 translate-middle-y"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              HTML, CSS, LESS
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="fill-action-1 position-absolute top-50 translate-middle-y"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">Block Model</h6>
                          </div>
                        </div>
                        <a href="#" className="mt-6 btn w-100 btn-action-1">
                          Purchase for $49
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-7 col-sm-10">
                      <div className="d-flex flex-column justify-content-between h-100 p-6 border rounded-2 bg-bg-1 border-light-3">
                        <div>
                          <h2 className="mb-4 text-light-1">
                            Business monthly
                          </h2>
                          <p className="mb-6 text-light-2">
                            Want to create something beautiful? Now you can. In
                            hours, not weeks.
                          </p>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="fill-action-3 position-absolute top-50 translate-middle-y"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              Many Components
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="fill-action-3 position-absolute top-50 translate-middle-y"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              Based on Bootstrap
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="fill-action-3 position-absolute top-50 translate-middle-y"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              HTML, CSS, LESS
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="fill-action-3 position-absolute top-50 translate-middle-y"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">Block Model</h6>
                          </div>
                        </div>
                        <a href="#" className="mt-6 btn w-100 btn-action-3">
                          Purchase for $99
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item active bg-bg-1">
                  <div className="row justify-content-center">
                    <div className="mb-8 mb-lg-0 col-lg-4 col-md-7 col-sm-10">
                      <div className="d-flex flex-column justify-content-between h-100 p-6 border rounded-2 bg-dark-2 border-dark-2">
                        <div>
                          <h2 className="mb-4 text-light-1">Basic Tier</h2>
                          <p className="mb-6 text-light-2">
                            Get Started with our most basic plan
                            <span className="text-nowrap"> </span>
                            and unlock the magic.
                          </p>

                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              Basic DeepTuring Extension
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              Restricted to - PHP, HTML, CSS, JAVASCRIPT
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              Argument Completion and Semantic Structure
                              Awareness
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              Ai Code Suggestions
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              {" "}
                              DeepTuring Coderoom
                            </h6>
                          </div>
                        </div>
                        <button
                          className="mt-6 btn w-100 btn-bg-3"
                          onClick={subscribeFree}
                        >
                          Get Started for Free
                        </button>
                        {/* <a href="/login" className="mt-6 btn w-100 btn-bg-3">
                          Download for Free
                        </a> */}
                      </div>
                    </div>
                    <div className="mb-8 mb-lg-0 col-lg-4 col-md-7 col-sm-10">
                      <div className="d-flex flex-column justify-content-between h-100 p-6 border rounded-2 bg-bg-1 border-light-3">
                        <div>
                          <h2 className="mb-4 text-light-1">Pro Tier</h2>
                          <p className="mb-6 text-light-2">
                            Get your idea out there in the way you want it.
                            Start with the pro plan for the ultimate tool.
                          </p>

                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              All the features in Basic Tier
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              Full Version of DeepTuring Extension
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              All Of Our Supported Programming Languages
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              DeepTuring Sandbox (coming soon)
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              DeepTuring Ai Playground
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              Speech to Text and Text to Speech Feature (not available in beta)
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              AI Explain Code Feature
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                      </svg>
                            <h6 className="ps-7 text-light-1">
                              Programming Language Converter
                            </h6>
                          </div>
                        </div>
                        <button
                          className="mt-6 btn w-100 btn-bg-3"
                          onClick={subscribePro}
                        >
                          Get started now
                        </button>
                        {/* <a href="/login" className="mt-6 btn w-100 btn-light-1">
                          Subscribe for $7.99
                        </a> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-7 col-sm-10">
                      <div className="d-flex flex-column justify-content-between h-100 p-6 border rounded-2 bg-bg-1 border-light-3">
                        <div>
                          <h2 className="mb-4 text-light-1">Enterprise Tier</h2>
                          <p className="mb-6 text-light-2">
                            Want the most productive tool for yourself or your
                            teams daily workload? Create Software in Minutes.
                            Focus more on deployment and less on typing.
                          </p>

                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              All the features in Basic and Pro Tier
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              Full Suite of Team Features
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              ML/AI Tutor chatbot
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              SQL Translator and Generator
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              Parse Unstructured Data Tool
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              DeepTuring Extension Code Assistant (Codebot)
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            {/*<h6 className="ps-7 text-light-1">
                              AI Explaining Code Feature
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                      </svg>*/}
                            <h6 className="ps-7 text-light-1">
                              Ai Auto code debugger
                            </h6>
                          </div>
                          <div className="position-relative mb-5">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="position-absolute top-50 translate-middle-y fill-light-1"
                            >
                              <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"></path>
                            </svg>
                            <h6 className="ps-7 text-light-1">
                              Ai Code Logic Tests Generator
                            </h6>
                          </div>
                        </div>
                        <button
                          className="mt-6 btn w-100 btn-bg-3"
                          onClick={subscribeEnterprise}
                        >
                          Not available in Beta
                        </button>
                        {/* <a href="/login" className="mt-6 btn w-100 btn-light-1">
                          Subscribe for $15.99
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>
      {/* <!-- Contact 3 --> */}
      <Element name="contact">
        <section className="py-10 py-lg-20 bg-bg-3">
          <div className="container">
            <h2
              className="text-center display-5 mb-6"
              data-aos="fade-down"
              data-aos-delay="0"
            >
              Have a question? Contact us
            </h2>
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9">
                <form
                  action="form-handler.php"
                  method="post"
                  enctype="multipart/form-data"
                  className="js-ajax-form"
                  data-aos="fade-down"
                  data-aos-delay="150"
                >
                  {/* <!-- forms alerts --> */}
                  {contactFormSuccess && (
                    <div
                      className={`alert alert-action-8 fixed-top js-form-result`}
                      data-result="success"
                      role="alert"
                    >
                      <span
                        className="js-form-alert-text"
                        data-default-text="Thanks for your message!"
                      >
                        Thanks for your message!
                      </span>
                    </div>
                  )}
                  {contactFormSuccess === false && (
                    <div
                      className={`alert alert-action-5 fixed-top js-form-result`}
                      data-result="error"
                      role="alert"
                    >
                      <span className="js-form-alert-text"> Error! </span>
                    </div>
                  )}
                  {/* <!-- forms alerts end --> */}
                  <label className="fs-6 fw-bold text-dark-1 mb-3">
                    {" "}
                    Name{" "}
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={contactFormData.name}
                    onChange={(e) =>
                      setContactFormData({
                        ...contactFormData,
                        name: e.target.value,
                      })
                    }
                    placeholder="Billie Reynolds"
                    className="mb-6 form-control border border-dark-3"
                  />
                  <label className="fs-6 fw-bold text-dark-1 mb-3">
                    {" "}
                    Email{" "}
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={contactFormData.email}
                    onChange={(e) =>
                      setContactFormData({
                        ...contactFormData,
                        email: e.target.value,
                      })
                    }
                    placeholder="example@mail.com"
                    className="form-control border border-dark-3 mb-6"
                  />
                  <label className="fs-6 fw-bold text-dark-1 mb-3">
                    {" "}
                    Subject{" "}
                  </label>
                  <select
                    name="subject"
                    className="form-select border border-dark-3 mb-6"
                    value={contactFormData.subject}
                    onChange={(e) =>
                      setContactFormData({
                        ...contactFormData,
                        subject: e.target.value,
                      })
                    }
                  >
                    <option value="Question" selected="selected">
                      Question
                    </option>
                    <option value="SalesOrder">Sales Order</option>
                    <option value="Suggestion">Suggestion</option>
                  </select>
                  <label className="fs-6 fw-bold text-dark-1 mb-3">
                    {" "}
                    Message{" "}
                  </label>
                  <textarea
                    name="message"
                    placeholder="Type here short description"
                    value={contactFormData.message}
                    onChange={(e) =>
                      setContactFormData({
                        ...contactFormData,
                        message: e.target.value,
                      })
                    }
                    className="form-control border border-dark-3 mb-6"
                    rows="5"
                  ></textarea>
                  <label className="form-check mb-6">
                    <input
                      className="form-check-input basic-bg-1 checked-bg-1"
                      type="checkbox"
                      name="checkbox"
                      checked="checked"
                    />
                    <span className="form-check-label">
                      Subscribe to our newsletter
                    </span>
                  </label>
                  <button className="btn w-100 btn-bg-1" onClick={handleSubmit}>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Element>
      <Footer />
    </>
  );
};
export default MainPage;

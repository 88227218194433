import React, { useState } from "react";
import mixpanel from "mixpanel-browser";
import logo from "./img/logo.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import loadingImage from "../assets/loading.gif";

import {
  loginUser,
  signUpUser,
  subUserSignup,
} from "../app/features/auth/authService";
import axios from "axios";
import ReactGA from "react-ga4";
import { editIsSuccess } from "../app/features/auth/authSlice";
import "./Login.css";

import Spinner from "react-bootstrap/Spinner";
const Login = () => {
  const container = React.useRef();

  // React Router
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const [loader, setLoader] = useState(true);
  const subUserToken = location.search.split("=")[1];

  // State
  const [signUpObj, setSignUpObj] = React.useState({
    firstName: "", //"izeres",
    lastName: "", //"fais",
    email: "", //"izerea12f@gmail.com",
    password: "", //"ann1234",
    countryCode: "", //250,
    dateOfBirth: "", //"2000-03-13",
  });
  const [signInObj, setSignInObj] = React.useState({
    email: "", // "izerea12f@gmail.com",
    password: "", // "ann1234",
  });
  const [ip, setIP] = React.useState("");

  // Redux state
  const dispatch = useDispatch();
  const { loading, isError, message, isSuccess, user } = useSelector(
    (state) => state.auth
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adjust threshold as needed

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Redux useEffect
  React.useEffect(() => {
    if (isError) {
      toast.error(message);
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      toast.success(message);
      dispatch(editIsSuccess(false));

      if (user === null) {
        setSignUpObj({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          countryCode: "",
          dateOfBirth: "",
        });

        if (container) {
          container.current.classList.toggle("sign-up");
          container.current.classList.toggle("sign-in");
        }
      } else {
        setSignInObj({
          email: "",
          password: "",
        });

        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      }
    }
  }, [isSuccess]);

  React.useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/dashboard");
      }, 100);
    }
  }, [user]);

  // Onload

  React.useEffect(() => {
    setTimeout(() => {
      if (container) {
        if (
          queryParams.get("redirect") === "signup" ||
          queryParams.size === 1 ||
          (location.state && location.state.is_signup)
        )
          container.current.classList.add("sign-up");
        else container.current.classList.add("sign-in");
      }
    }, 200);
  }, []);
  // Get the User GEO Location JSON

  React.useEffect(() => {
    (async () => {
      try {
        const res = await axios.get("https://geolocation-db.com/json/");
        setIP(res.data.IPv4);
        setSignUpObj((pre) => ({ ...pre, countryCode: res.data.country_code }));
      } catch (error) {}
    })();
  }, []);

  const toggle = () => {
    container.current.classList.toggle("sign-in");
    container.current.classList.toggle("sign-up");
  };

  const handleChangeSignUp = (e) => {
    if (subUserToken) {
      setSignUpObj((pre) => ({
        ...pre,
        token: subUserToken,
        [e.target.name]: e.target.value,
      }));
    } else {
      setSignUpObj((pre) => ({ ...pre, [e.target.name]: e.target.value }));
    }
  };
  const handleChangeSignIn = (e) => {
    setSignInObj((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const signUpFunc = async (e) => {
    e.preventDefault();
    if (subUserToken) {
      dispatch(subUserSignup(signUpObj, subUserToken));
    } else {
      dispatch(signUpUser(signUpObj));
    }
    ReactGA.event("sign_up", {
      email: signUpObj.email,
    });
    ReactGA.send({
      page: location.pathname,
      hitType: "pageview",
      title: "Sign Up",
    });
  };

  const signInFunc = (e) => {
    e.preventDefault();
    dispatch(loginUser({ ...signInObj, ip_address: ip }));
    ReactGA.event("login", {
      email: signInObj.email,
    });
    ReactGA.send({
      page: location.pathname,
      hitType: "pageview",
      title: "Login",
    });
  };
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const [showPasswordSignup, setShowPasswordSignup] = useState(false);

  const handleTogglePasswordSignup = () => {
    setShowPasswordSignup(!showPasswordSignup);
  };

  return (
    <div
      className=""
      style={{ height: "100vh", overflow: "scroll" }}
    >
      <div id="container" className="container1 mx-0 my-0" ref={container}>
        {/* <!-- FORM SECTION --> */}
        <div className="row row1" style={{}}>
          {/* <!-- SIGN UP --> */}
          <div className="col1 align-items-center pl-10 flex-col sign-up mt-[-10]">
            <div className="form-wrapper signup-form-wrapper align-items-center">
              <form onSubmit={signUpFunc}>
                <div className="form sign-up">
                  <Link to="/">
                    <img src={logo} alt="logo" className="signup-logo" />
                  </Link>
                  <div className="input-group">
                    <i className="bx bxs-user"></i>
                    <input
                      type="text"
                      placeholder="First Name"
                      required={true}
                      name="firstName"
                      value={signUpObj.firstName}
                      onChange={handleChangeSignUp}
                    />
                  </div>
                  <div className="input-group">
                    <i className="bx bxs-user"></i>
                    <input
                      type="text"
                      placeholder="Last Name"
                      required={true}
                      name="lastName"
                      value={signUpObj.lastName}
                      onChange={handleChangeSignUp}
                    />
                  </div>
                  <div className="input-group">
                    <i className="bx bxs-user"></i>
                    <input
                      type="date"
                      placeholder="Date of birth"
                      required={true}
                      name="dateOfBirth"
                      value={signUpObj.dateOfBirth}
                      onChange={handleChangeSignUp}
                    />
                  </div>
                  <div className="input-group">
                    <i className="bx bx-mail-send"></i>
                    <input
                      type="text"
                      placeholder="Country Code"
                      required={true}
                      name="countryCode"
                      value={signUpObj.countryCode}
                      onChange={handleChangeSignUp}
                    />
                  </div>
                  <div className="input-group">
                    <i className="bx bx-mail-send"></i>
                    <input
                      type="email"
                      placeholder="Email"
                      required={true}
                      name="email"
                      value={signUpObj.email}
                      onChange={handleChangeSignUp}
                    />
                  </div>
                  <div className="input-group">
                    <i className="bx bxs-lock-alt"></i>
                    <input
                      type={showPasswordSignup ? "text" : "password"}
                      placeholder="Password"
                      required={true}
                      name="password"
                      value={signUpObj.password}
                      onChange={handleChangeSignUp}
                    />
                    <span
                      className="input-group-text showpassword"
                      onClick={handleTogglePasswordSignup}
                      style={{ cursor: "pointer" }}
                    >
                      {showPasswordSignup ? (
                        <i className="fa fa-eye" />
                      ) : (
                        <i className="fa fa-eye-slash" />
                      )}
                    </span>
                  </div>
                  <div
                    style={{
                      alignSelf: "flex-start",
                      textAlign: "start",
                      margin: ".5rem .3rem",
                    }}
                  >
                    <i className="bx bxs-lock-alt"></i>
                    <input
                      style={{
                        textAlign: "start",
                        margin: ".2rem",
                        border: "1px solid black",
                      }}
                      type="checkbox"
                      required={true}
                    />
                    <span id="text" style={{ fontSize: ".8rem" }}>
                      By checking the box you acknowledge that you have read and
                      agree to the &nbsp;
                      <Link
                        to="/tos"
                        className="pointer"
                        style={{ color: "black" }}
                      >
                        terms of service
                      </Link>
                      &nbsp; and &nbsp;
                      <Link
                        to="/privacy"
                        className="pointer"
                        style={{ color: "black" }}
                      >
                        privacy policy
                      </Link>
                    </span>
                  </div>
                  <button type="submit">Sign up</button>
                  <p>
                    <span>Already have an account? &nbsp;</span>
                    <Link
                      onClick={toggle}
                      className="pointer"
                      style={{ color: "black" }}
                    >
                      Sign in here
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- END SIGN UP --> */}
          {/* <!-- SIGN IN --> */}

          <div className="col col1 align-items-center flex-col sign-in ">
            <div className="form-wrapper ml-auto align-items-center mb-10">
              <div className="form sign-in">
                <form onSubmit={signInFunc}>
                  <Link to="/">
                    <img src={logo} alt="logo" className="login-logo" />
                  </Link>
                  <div className="input-group">
                    <i className="bx bxs-user"></i>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={signInObj.email}
                      onChange={handleChangeSignIn}
                      required={true}
                    />
                  </div>
                  <div className="input-group">
                    <i className="bx bxs-lock-alt"></i>
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      value={signInObj.password}
                      onChange={handleChangeSignIn}
                      required={true}
                    />
                    <span
                      className="input-group-text showpassword"
                      onClick={handleTogglePassword}
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword ? (
                        <i className="fa fa-eye" />
                      ) : (
                        <i className="fa fa-eye-slash" />
                      )}
                    </span>
                  </div>

                  {loading ? (
                    <Spinner className="mt-4" />
                  ) : (
                    <button type="submit">
                      <>Sign in</>
                    </button>
                  )}
                  <p>
                    <Link
                      to="/forgotpassword"
                      className="pointer"
                      style={{ color: "#000" }}
                    >
                      <b>Forgot password?</b>
                    </Link>
                  </p>
                  <p>
                    <span>Don't have an account? &nbsp;</span>

                    <Link
                      onClick={toggle}
                      className="pointer"
                      style={{ color: "#000" }}
                    >
                      Sign up here
                    </Link>
                  </p>
                </form>
                <div className="p-4"></div>
              </div>
            </div>
            <div className="form-wrapper"></div>
          </div>
          {/* <!-- END SIGN IN --> */}
        </div>
        {/* <!-- END FORM SECTION --> */}
        {/* <!-- CONTENT SECTION --> */}
        <div className="row row1 content-row">
          {/* <!-- SIGN IN CONTENT --> */}
          <div className="col col1 align-items-center  flex-col">
            <div className="text sign-in">
              <h2 style={{ color: "white" }}>Welcome Back</h2>
            </div>
            <div className="img sign-in"></div>
          </div>
          {/* <!-- END SIGN IN CONTENT --> */}
          {/* <!-- SIGN UP CONTENT --> */}
          <div className="col1 signup-col1 align-items-center flex-col">
            <div className="img sign-up"></div>
            <div className="text sign-up">
              <h2 style={{ color: "white", textAlign: "center" }}>
                Hello Friend!
              </h2>
              <h2
                style={{
                  fontSize: "18px",
                  width: "100%",
                  color: "white",
                  textAlign: "center",
                  // marginLeft: "50px",
                }}
              >
                Enter your details and start your journey with us today!
              </h2>
            </div>
          </div>
          {/* // <!-- END SIGN UP CONTENT -->
            // <!-- END CONTENT SECTION --> */}
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default Login;

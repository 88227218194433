import axios from "axios";
import { baseUrl } from "../baseUrl";
import { getAuthToken } from "./testLogin";

export const createSubUser = () => {
  return 7;
};

export const subUserSignup = async (data) => {
  const enterPriseToken = getAuthToken();
  const res = await axios.post(
    baseUrl + "/users/subusers/signup?token=" + enterPriseToken,
    data
  );
  return res;
};

export const referSubuser = async (email) => {
 const res = await axios.get(`${baseUrl}/users/refersubUser?email=${email}`, {

    headers: {
      Authorization: getAuthToken(),
    },
  });
  return res;
};

export const getUser = async (id) => {
  // {{Url}}/users/7e72de6a-7d21-493a-b9c2-0bd4c57c0637

  const res = await axios.get(`${baseUrl}/users/${id}`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
  return res;
};

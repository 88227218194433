import React, { useEffect } from "react";
import style from "./thankyou.module.css";
import { useNavigate } from "react-router-dom";

const Thankyou = () => {
  const navigate = useNavigate();
  const redirectToProfile = () => {
    navigate("/dashboard/billing");
  };
  useEffect(() => {
    setTimeout(() => {
      redirectToProfile();
    }, 3000);
  }, []);

  return (
    <>
      <div className={style.content}>
        <div className={style.wrapper_1}>
          <div className={style.wrapper_2}>
            <h1 className={style.heading}>Thank you !</h1>
            <p>Thanks for subscribing to our service.</p>
            <p>you should receive a confirmation email soon</p>
            <button
              className={style.go_home}
              onClick={(e) => {
                redirectToProfile();
              }}
            >
              go home
            </button>
          </div>
          <div className={style.footer_like}>
            <p>
              Email not received?
              <a href="#">Click here to send again</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thankyou;

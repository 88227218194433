import React, { useEffect, useState } from "react";
import { BsSpeedometer2 } from "react-icons/bs";
import { FaChartPie } from "react-icons/fa";
import { SiCodesandbox } from "react-icons/si";
import { SlBasket } from "react-icons/sl";
import { ImProfile } from "react-icons/im";
import { HiUser } from "react-icons/hi";
import { RiTeamFill } from "react-icons/ri";
import { AiOutlineLogout } from "react-icons/ai";
import { AiFillWechat } from "react-icons/ai";
import style from "../../page/dashboard.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTeam } from "../../context/TeamProvider";
import { logout } from "../../app/features/auth/authSlice";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
import logo from "../img/logo.png";
function Sidebar({ onSideBarMenuClicked }) {
  const { sidebarNotiIndicator, setSidebarNotiIndicator } = useTeam();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    console.log("Location changed >> ", location);
  }, [location]);

  const handleClick = (link) => {
    onSideBarMenuClicked(link);
    // console.log("LINK >> ", link);
    // if (link != location.pathname && windowWidth <= 1024) {
    //   setChangeSideBar((prev) => true);
    // }
  };

  const logoutFunc = () => {
    dispatch(logout());
    try {
      mixpanel.track("Logout Completed", {
        "Logout Time": new Date().toISOString(),
      });
      mixpanel.reset();
    } catch (error) {
      console.log("Mixpanel Error : ", error);
    }
    Cookies.remove("user");
    Cookies.remove("token");

    localStorage.clear();

    toast.success("Logout Successfully!");
    setTimeout(() => {
      navigate("/login");
    }, 800);
  };

  const divRef = React.useRef(null);
  const buttonRef = React.useRef(null);

  const handleButtonClick = (link) => {
    console.log("Clicked >> ", divRef.current);
    if (divRef.current) {
      divRef.current.click();
    }
  };

  return (
    <React.Fragment>
      <div className={style.sidebar}>
        <div className={style.sidebar_brand}>
          <Link to="#">
            <h3>
              <span>
                <img src={logo} alt="logo" style={{ width: "5rem" }} />
              </span>{" "}
              <span>Deep Turing</span>
              <a
                className="btn btn-sm btn-width-equal-height btn-bg-3 invisible "
                data-bs-dismiss="offcanvas"
                ref={divRef}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-dark-2"
                >
                  <path d="M8.9398 8L13.1398 3.80667C13.2653 3.68113 13.3359 3.51087 13.3359 3.33333C13.3359 3.1558 13.2653 2.98554 13.1398 2.86C13.0143 2.73447 12.844 2.66394 12.6665 2.66394C12.4889 2.66394 12.3187 2.73447 12.1931 2.86L7.9998 7.06L3.80646 2.86C3.68093 2.73447 3.51066 2.66394 3.33313 2.66394C3.1556 2.66394 2.98533 2.73447 2.8598 2.86C2.73426 2.98554 2.66374 3.1558 2.66374 3.33333C2.66374 3.51087 2.73426 3.68113 2.8598 3.80667L7.0598 8L2.8598 12.1933C2.79731 12.2553 2.74771 12.329 2.71387 12.4103C2.68002 12.4915 2.6626 12.5787 2.6626 12.6667C2.6626 12.7547 2.68002 12.8418 2.71387 12.9231C2.74771 13.0043 2.79731 13.078 2.8598 13.14C2.92177 13.2025 2.99551 13.2521 3.07675 13.2859C3.15798 13.3198 3.24512 13.3372 3.33313 13.3372C3.42114 13.3372 3.50827 13.3198 3.58951 13.2859C3.67075 13.2521 3.74449 13.2025 3.80646 13.14L7.9998 8.94L12.1931 13.14C12.2551 13.2025 12.3288 13.2521 12.4101 13.2859C12.4913 13.3198 12.5785 13.3372 12.6665 13.3372C12.7545 13.3372 12.8416 13.3198 12.9228 13.2859C13.0041 13.2521 13.0778 13.2025 13.1398 13.14C13.2023 13.078 13.2519 13.0043 13.2857 12.9231C13.3196 12.8418 13.337 12.7547 13.337 12.6667C13.337 12.5787 13.3196 12.4915 13.2857 12.4103C13.2519 12.329 13.2023 12.2553 13.1398 12.1933L8.9398 8Z"></path>
                </svg>
              </a>
            </h3>
          </Link>
        </div>
        <div className={style.sidebar_menu}>
          <ul>
            <li>
              <Link
                to="/dashboard"
                className="active"
                onClick={() => handleButtonClick("/dashboard")}
              >
                <BsSpeedometer2
                  style={{ marginRight: "2rem", fontSize: "2rem" }}
                />
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/edit"
                onClick={() => handleButtonClick("/dashboard/edit")}
              >
                <HiUser style={{ marginRight: "2rem", fontSize: "2rem" }} />
                <span>My Profile</span>
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/userchart"
                onClick={() => handleButtonClick("/dashboard/userchart")}
              >
                <FaChartPie style={{ marginRight: "2rem", fontSize: "2rem" }} />
                <span>Usage charts</span>
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/ide"
                onClick={() => handleButtonClick("/dashboard/ide")}
              >
                <SlBasket style={{ marginRight: "2rem", fontSize: "2rem" }} />
                <span>IDE and Plugins</span>
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/comingsoon"
                onClick={() => handleButtonClick("/dashboard/comingsoon")}
              >
                <SiCodesandbox
                  style={{ marginRight: "2rem", fontSize: "2rem" }}
                />
                <span>Sandbox</span>
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/ai"
                onClick={() => handleButtonClick("/dashboard/ai")}
              >
                <ImProfile style={{ marginRight: "2rem", fontSize: "2rem" }} />
                <span>Deepturing AI</span>
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/teamChat"
                onClick={() => handleButtonClick("/dashboard/teamChat")}
              >
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    setSidebarNotiIndicator(false);
                  }}
                >
                  <RiTeamFill
                    style={{ marginRight: "2rem", fontSize: "2rem" }}
                  />
                  <span>Team </span>
                  {sidebarNotiIndicator && (
                    <div
                      style={{
                        borderRadius: "50%",
                        backgroundColor: "red",
                        height: "12px",
                        width: "12px",
                        marginLeft: "0.5rem",
                      }}
                    ></div>
                  )}
                </div>
              </Link>
            </li>
            <li>
              <Link
                to="/welcome-coderoom"
                onClick={() => handleButtonClick("/welcome-coderoom")}
              >
                <AiFillWechat
                  style={{ marginRight: "2rem", fontSize: "2rem" }}
                />
                <span>Coderoom</span>
              </Link>
            </li>
            <li style={{ marginTop: "4rem" }} onClick={logoutFunc}>
              <Link>
                <AiOutlineLogout
                  style={{ marginRight: "2rem", fontSize: "2rem" }}
                />
                <span>Logout</span>
              </Link>
            </li>
          </ul>
        </div>
        <Link to="#"></Link>
      </div>
    </React.Fragment>
  );
}

export default Sidebar;

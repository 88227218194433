import { Link } from "react-router-dom";
import "./TermsOfService.module.css";
export const TermsOfService = () => (
  <>
    <div class="main">
      <b>
        <h1>Terms of Service</h1>
      </b>
      <p>Last updated: March 20, 2024</p>
      <hr />
      <p>
        <b>1. Acceptance of Terms</b>
      </p>
      <p>
        These Terms of Service ("Terms" or "Terms of Use") you are reading are a
        legally binding agreement between Sluu Corporation Ltd operator of
        DeepTuring, henceforth in this Terms of Service known as("Deepturing",
        "we" or "us") and yourself ("you"). By downloading, installing or using
        product offered by DeepTuring, including the DeepTuring product, and/or
        by clicking on the "I Agree" or such similar button provided by
        DeepTuring prior to accessing any of DeepTuring′s (i) services and/or
        (ii) website and/or (iii) any application which runs on your device and
        suggests, generate, autocompletes code and/or (iv) additional components
        to code development environment provided by DeepTuring (collectively,
        the "Services") you agree that you have read, understood, accept and
        agree to be bound by these Terms. If you do not agree to these Terms, do
        not use the Services. Please note that the term "you" also includes the
        company or other entity/organization that is our customer and on whose
        behalf (as applicable) you are accepting these Terms. DeepTuring
        reserves the right, in its sole discretion, to revise or modify these
        Terms at any time, and you agree to be bound by such revisions or
        modifications. Users are responsible for viewing these Terms
        periodically. Your continued use of the Services after a change or
        modification of these Terms has been made will constitute your
        acceptance of the revised Terms. If you do not agree to the Terms, your
        only remedy is to discontinue your use of the Services and to cancel any
        Account(s) (as defined below) you have created for use of the Services.
        If you violate the Terms, DeepTuring reserves the right to issue you a
        warning regarding the violation or to immediately terminate or suspend
        the Accounts you have created for using the Services. You agree that
        DeepTuring does not need to provide you notice before terminating or
        suspending your Account(s), but it may provide such notice in its sole
        discretion. You agree that DeepTuring may change any part of the
        Services, including its content, at any time or discontinue the Services
        or any part thereof, for any reason, without notice to you and without
        liability.
      </p>
      <p>
        <b> Age Requirements</b>
      </p>
      <p>
        By accessing our services, you confirm that you’re at least 13 years old
        and meet the minimum age of digital consent in your country. We maintain
        a <a href="/restriction">list of minimum ages</a> around the world as a
        resource for you, but we aren’t able to guarantee that it is always
        accurate. If you are old enough to access our services in your country,
        but not old enough to have authority to consent to our terms, your
        parent or guardian must agree to our terms on your behalf. Please ask
        your parent or guardian to read these terms with you. If you’re a parent
        or legal guardian, and you allow your teenager to use the services, then
        these terms also apply to you and you’re responsible for your teenager’s
        activity on the services. You may not use the Services and may not
        accept these Terms if you are a person barred from receiving the
        Services under the laws of the country in which you are resident or from
        which you use the Services.
      </p>
      <p>
        <b>2. Description of the Services</b>
      </p>
      <p>
        DeepTuring′s Services include, without limitation (i) an AI software
        and/or a service for generating, autocompleting software code, (ii) A
        software tool integrated with Artificial intelligence that offers coding
        and software development assistance, converting programing languages and
        (iii) a software and/or a service which analyzes your code-development
        environment and the activities performed therein, and automatically
        suggests you relevant code portions which may be applicable to your
        uses. DeepTuring shall not assume any responsibility to any content
        which is published, displayed and/or suggested through the Services,
        including without limitation with respect to any suggested/generated
        code, its integrity, accuracy and/or reliability. DeepTuring cannot
        guarantee that the Services will always function without disruptions,
        delay or errors. A number of factors may impact the quality of your
        communications and use of the Services, and may result in the failure of
        your communications including, without limitation, your local network,
        firewall, your internet service provider, the public internet and your
        power supply. DeepTuring takes no responsibility for any disruption,
        interruption or delay caused by any failure of or inadequacy in any of
        these items or any other items over which we have no control.
        DeepTuring, its directors, officers, employees and consultants do not
        assume any responsibility or liability with respect to any Suggested or
        Generated Code, even if such Code and/or any use made by you thereof (i)
        infringes any intellectual property right of any third party, (ii)
        requires the distribution to the public of, or public access to, your
        (or any third party′s) source code or any of your software and/or
        products, (iii) restrict your ability to charge for distribution of, or
        use your (or any third party′s) source code or any of your software
        and/or products, or (iv) causes any damage to or imposes any liability
        on you and/or any third party. We recommend that you carefully read the
        applicable license of any code you intend to use, prior to such usage.
        We note that as part of the development of the Services provided by
        DeepTuring, DeepTuring uses certain "Free and Open Source Software" or
        "FOSS". In that respect DeepTuring represents that its use of such FOSS
        is in compliance with the license terms thereof (however Deepturing
        makes no other representations and/or warranties in connection with such
        FOSS.)
      </p>
      <p>
        <b>3. Access to the Services</b>
      </p>
      <p>
        It is your duty to make sure your computer or other device satisfies all
        technical requirements so you may access and utilize the Services. You
        do not receive the necessary equipment from DeepTuring to access and/or
        use our services. All fees (such as Internet service provider or airtime
        charges) imposed by third parties in connection with your use and access
        of the Services are your responsibility.{" "}
      </p>
      <p>
        <b>4. Account Information</b>
      </p>
      <p>
        In order to register to our Services, you may be asked, if and as
        applicable, to sign-in using your relevant authentication provider
        service. During the process of creating an account in order to access
        the Services ("Account"), you may be required to select a password or to
        allow us to access your Account information from an authentication
        service (the "Login Information"). The following rules govern the
        security of your Account and Login Information. For the purposes of
        these Terms, references to Account and Login Information shall include
        any account and account information, including names, email, password,
        etc whether or not created for the purpose of using the Services, that
        are used to access the Services (for example, account information for a
        social network service account from which the Services are accessed):
      </p>
      <p>
        • You shall not share your Account or Login Information, nor let anyone
        else access your Account or do anything else that might jeopardize the
        security of your Account.
      </p>
      <p>
        • In the event you become aware of or reasonably suspect any breach of
        security, including, without limitation, any loss, theft, or
        unauthorized disclosure of your Login Information or unauthorized access
        to your Account, you must immediately notify DeepTuring and modify your
        Login Information;
      </p>
      <p>
        • You are solely responsible for maintaining the confidentiality of the
        Login Information, and you will be responsible for all uses of your
        Login Information, including purchases, whether or not authorized by
        you;
      </p>
      <p>
        • You are responsible for anything that happens through your Account,
        whether or not such actions were taken by you, including, for the
        avoidance of doubt, actions taken by third parties. You therefore
        acknowledge that your Account may be terminated if someone else uses it
        to engage in any activity that violates these Terms or is otherwise
        improper or illegal;
      </p>
      <p>
        • You undertake to monitor your Account and restrict use by any
        individual barred from accepting these Terms and/or receiving the
        Services, under the provisions listed herein or any applicable law. You
        shall accept full responsibility for any unauthorized use of the
        Services by any of the above mentioned;
      </p>
      <p>
        Any personal information you provide to us when creating or updating
        your Account or when we access your Account information from a social
        network service, which may include your name, e-mail address, profile
        picture and any such other information, will be held and used in
        accordance with DeepTuring′s Privacy Policy available at "Privacy
        Policy" which constitutes an integral part of these Terms. You agree
        that you will supply accurate and complete information to us, and that
        you will update that information promptly after it changes. You
        represent and warrant that you have full right and authority to provide
        Deepturing with the foregoing information, including, without
        limitation, any third party′s consent (to the extent required under any
        applicable law).
      </p>
      <p>
        <b>5. License </b>
      </p>
      <p>
        {" "}
        Subject to your agreement and compliance with these Terms, DeepTuring
        grants you a personal, non-exclusive, non-transferable, non-sub
        licensable, revocable, limited scope license to use the Services for
        your own internal purposes. You hereby acknowledge that your license to
        use the Services is limited by these Terms, and, if you violate or if,
        at any point, you do not agree to any of these Terms, your license to
        use the Services shall immediately terminate, and you shall immediately
        refrain from using the Services. If the Services or any part thereof is
        determined to be illegal under the laws of the country in which you are
        situated, you shall not be granted any license to use the Services, and
        must refrain from using the Services.{" "}
      </p>
      <p>
        <b> 6. Fees; Payment </b>
      </p>
      <p>
        {" "}
        DeepTuring may offer its user’s access to certain Services through
        various plans, of which could be a pay-as-you-go usage plan, a monthly
        subscription plan or a hybrid of the two. Some plans may be offered at
        different rates as shall be detailed on the Services from time to time.
        Access to these Services and use of selected features of these Services
        is subject to timely payment of applicable fees, if any. DeepTuring
        reserves the right to terminate your use of the Services in case of any
        failure on your part to timely pay any fees due by you to DeepTuring (if
        any). DeepTuring may, at its sole discretion, add, remove or change the
        features and services it offers or the fees (including the amount or the
        type of fees) DeepTuring charges at any time. You agree that you are
        responsible for checking the DeepTuring website regularly to confirm
        whether there are any new fees and their effective date(s). DeepTuring
        is not responsible for any charges or expenses you incur as a result
        from being billed by DeepTuring in accordance with these Terms. All fees
        payable by you are exclusive of any applicable taxes and duties,
        including, without limitation, VAT and applicable sales tax. Fees are
        quoted and payable in United States dollars, and fees are based on
        Services purchased and actual usage.{" "}
      </p>
      <p>
        <b> 7. Refund Policy </b>
      </p>
      <p>
        {" "}
        Due to the nature of digital service, we do NOT offer refunds unless it
        is legally required. You may at any time cancel service. If service is
        canceled during the middle of your billing cycle, you will lose your
        access to service immediately.{" "}
      </p>
      <p>
        <b> 8. Account Termination </b>
      </p>
      <p>
        {" "}
        For any reason, including but not limited to a suspected violation of
        these Terms, illegal or improper use of your account, or illegal or
        improper use of the services, products, or DeepTuring's intellectual
        property as determined by DeepTuring in its sole discretion, DeepTuring
        may refuse access to the Services or terminate your account without
        prior notice. DeepTuring has the right to close any and all of your
        accounts if you have multiple accounts.{" "}
        {/*Apart from the aforementioned, Deepturing 
        retains the right to arbitrarily withhold, withdraw, or seize Benefits 
        linked to your account. "Benefits" are defined as the licensed rights 
        to access and/or use any offline or online components or features of 
        the Services, including but not limited to customer content and 
        digital or virtual assets, that are given, awarded, gifted, delivered to,
        or purchased by you.*/}
        If your Account, or a particular subscription for a Service associated
        with your Account, is terminated, suspended{" "}
        {/*and/or if any Benefits are selectively
        removed, revoked or garnisheed from your Account,*/}{" "}
        no refund will be granted, no Benefits will be credited to you or
        converted to cash or other forms of reimbursement, and you will have no
        further access to your Account.{" "}
        {/*or Benefits associated with your Account or such particular
        Service.*/}{" "}
        You acknowledge that DeepTuring is not required to provide you notice
        before suspending or terminating your Account or selectively removing,
        revoking or garnisheeing Benefits associated with your Account. In the
        event that Deepturing terminates your Account, you may not participate
        nor make use of the Services again without Deepturing′s express consent.
        DeepTuring reserves the right to refuse to keep Accounts for, and
        provide access to the Services to any individual. You may not allow
        individuals whose Accounts have been terminated by us to use your
        Account. If you believe that any action has been taken against your
        Account in error, please contact us at: support@deepturingai.com{" "}
      </p>
      <p>
        <b>9. Communication Channels </b>
      </p>
      <p>
        {" "}
        The Services may provide communication channels such as messaging chat
        feature, forums, communities, or chat areas ("Communication Channels")
        designed to enable you to communicate with other users of the Services.
        DeepTuring is under no obligation to monitor these communication
        channels but may do so, and reserves the right to review materials
        posted to the Communication Channels and to remove any materials, at any
        time, with or without notice for any reason, at its sole discretion.
        DeepTuring may also terminate or suspend your access to any
        Communication Channels at any time, without notice, for any reason. You
        acknowledge that chats, postings, or materials posted by users on the
        Communication Channels are neither endorsed nor controlled by
        DeepTuring, and these communications should not be considered reviewed
        or approved by DeepTuring. You will be solely responsible for your
        activities within the Communication Channels and under no circumstances
        will DeepTuring be liable for any activity within the Communication
        Channels. You agree that all your communications within the
        Communication Channels are public, and you have no expectation of
        privacy regarding your use of the Communication Channels. DeepTuring is
        not responsible for information that you choose to share on the
        Communication Channels, or for the actions of other users.{" "}
      </p>
      <p>
        {" "}
        <b> 10. Rules of Conduct and Usage </b>
      </p>
      <p>
        {" "}
        You represent and warrant that you have full right and authority to use
        the Services and to be bound by these Terms. You agree that you will
        comply fully with these Terms and all applicable domestic and
        international laws, regulations, statutes, ordinances that govern your
        use of such Services. Without limiting the foregoing and in recognition
        of the global nature of the Internet, you agree to comply with all local
        and international rules regarding online conduct. You also agree to
        comply with all applicable laws affecting the transmission of content or
        the privacy of persons. You undertake that you shall not defraud, or
        attempt to defraud, DeepTuring or other users, and that you shall not
        act in bad faith in your use of the Services. If DeepTuring determines
        that you have acted in bad faith and/or in violation of these Terms, or
        if DeepTuring determines that your actions fall outside of reasonable
        community standards, DeepTuring may, at its sole discretion, terminate
        your Account and prohibit you from using the Services. You agree that
        your use of the Services shall be lawful and that you will comply with
        the usage rules. In furtherance of the foregoing, and as an example and
        not as a limitation, you agree that you shall not:
      </p>
      <p>
        {" "}
        • Create an Account with or access the Services if you are barred from
        receiving the Services under the provisions of these Terms or any
        applicable law;{" "}
      </p>
      <p>
        {" "}
        • Promote, host, display or implement the Services on any websites that:
        (i) contain indecent, obscene or pornographic material, hate speech,
        highly explosive subject matter (as determined by DeepTuring at its sole
        discretion), defamatory, libelous, obscene, gambling related,
        discriminatory, deceptive, abusive spyware, adware, or other malicious
        programs or code, unsolicited mass distribution of email, or any illegal
        subject matter or activities or otherwise offensive content, or content
        addressed to individuals under the age of 18; (ii) incorporate any
        materials that infringe or assists others to infringe on any copyright,
        trademark or other intellectual property rights; (iii) are an
        incentive-based website, such as a lottery or sweepstakes site which
        rewards users for clicking on links; (iv) engage in activities that
        violate Deepturing′s Privacy Policy; (v) openly encourage users to click
        on banner ads or other sponsored links to support the site; or (vi)
        infringe any right of any third party or violate any applicable law or
        regulation;{" "}
      </p>
      <p>
        {" "}
        • Upload, post, transmit or otherwise disseminate any material that is
        vulgar, indecent, obscene, pornographic, sexual or that is, in a
        reasonable person′s view, otherwise offensive or objectionable;{" "}
      </p>
      <p>
        {" "}
        • Libel, ridicule, defame, mock, stalk, intimidate, threaten, harass, or
        abuse anyone, hatefully, racially, ethnically or in any other manner;{" "}
      </p>
      <p>
        {" "}
        • Copy, rent, lease, sell, transfer, assign, sublicense, disassemble,
        reverse engineer or decompile (except if expressly authorized by
        Deepturing or by applicable statutory law), modify or alter any part of
        the Services;{" "}
      </p>
      <p>
        {" "}
        • Upload or transmit (or attempt to upload or transmit) files that
        contain viruses, Trojan horses, worms, time bombs, cancelbots, corrupted
        files or data, or any other similar software or programs that may damage
        the operation of the Services or the computers of other users of the
        Services;{" "}
      </p>
      <p>
        {" "}
        • Advertise, solicit or transmit any commercial advertisements,
        including chain letters, junk e-mail or repetitive messages (spim or
        spam) to anyone;{" "}
      </p>
      <p>
        {" "}
        • Violate the contractual, personal, intellectual property or other
        rights of any party including by using, uploading, transmitting,
        distributing, or otherwise making available any information or material
        made available through the Services in any manner that infringes any
        copyright, trademark, patent, trade secret, or other right of any party
        (including rights of privacy or publicity);{" "}
      </p>
      <p>
        {" "}
        • Create false personas, multiple identities, multiple user Accounts,
        set up an Account on behalf of someone other than yourself, use bots or
        other automated software programs to defraud or which otherwise violate
        these Terms and/or the terms of service of any third-party applications
        or social networks through which the Services is accessed;{" "}
      </p>
      <p>
        {" "}
        • Attempt to obtain passwords or other private information from other
        members including personally identifiable information (whether in text,
        image or video form), identification documents, or financial
        information;{" "}
      </p>
      <p>
        {" "}
        • Upload or transmit (or attempt to upload or to transmit), without
        DeepTuring′s express consent, any material that acts as a passive or
        active information collection or transmission mechanism, including,
        without limitation, clear graphics interchange formats ("gifs"), 1x1
        pixels, web bugs, cookies or other similar devices (sometimes referred
        to as "spyware", "passive collection mechanisms" or "pcms");{" "}
      </p>
      <p>
        {" "}
        • Improperly use support channels or complaint buttons to make false
        reports to DeepTuring;{" "}
      </p>
      <p>
        {" "}
        • Develop and distribute "auto" software programs, "macro" software
        programs or other "cheat utility" software programs or applications;{" "}
      </p>
      <p>
        {" "}
        • Encourage any third party to: (i) directly or indirectly generate
        usage, queries, impressions, or clicks through any automated, deceptive,
        fraudulent or other invalid means; (ii) edit or modify any tag, or
        remove, obscure or minimize any tag in any way; or (iii) engage in any
        action or practice that reflects poorly on Deepturing or otherwise
        disparages or devalues Deepturing′s reputation or goodwill;{" "}
      </p>
      <p>
        {" "}
        • Make representations with respect to DeepTuring not approved in
        advance and in writing by DeepTuring. You shall obtain DeepTuring′s
        prior written approval to the content of any marketing message, and with
        respect to any use of DeepTuring′s trade name and/or trademarks and/or
        designs in connection with the Services;{" "}
      </p>
      <p>
        {" "}
        • Rent, lease, sell, trade, gift, bequeath or otherwise transfer your
        Account to anyone without DeepTuring′s prior written consent;{" "}
      </p>
      <p>
        {" "}
        • Access or use an Account which has been rented, leased, sold, traded,
        gifted, bequeathed, or otherwise transferred from the Account creator
        without DeepTuring′s prior written consent;{" "}
      </p>
      <p>
        • Engage in any fraudulent activity with respect to payment methods or
        advertiser tracking mechanisms;{" "}
      </p>
      <p>
        • Violate any applicable laws or regulations, or encourage or promote
        any illegal activity including, but not limited to, copyright
        infringement, trademark infringement, defamation, invasion of privacy,
        identity theft, hacking, cracking or distribution of counterfeit
        software, or cheats or hacks for the Services;{" "}
      </p>
      <p>
        • Attempt to use the Services on or through any service that is not
        authorized by DeepTuring. Any such use is at your own risk and may
        subject you to additional or different terms. Deepturing takes no
        responsibility for your use of the Services through any service that is
        not authorized by it;{" "}
      </p>
      <p>
        {" "}
        • Attempt to interfere with, hack into or decipher any transmissions to
        or from the servers for the Services;{" "}
      </p>
      <p>
        {" "}
        • and/or Interfere with the ability of others to enjoy using the
        Services, including disruption, overburden or aid the disruption or
        overburdening of the Services′ servers, or take actions that interfere
        with or materially increase the cost to provide the Services for the
        enjoyment of all its users.{" "}
      </p>
      <p>
        <b> 11. Intellectual Property Ownership </b>
      </p>
      <p>
        {" "}
        DeepTuring and/or its affiliates retain all rights in the Services′
        materials (including, but not limited to, applications, software,
        designs, graphics, texts, information, pictures, video, sound, music,
        and other files, and their selection and arrangement) (collectively,
        "Services′ Materials"). The entire contents of the Services are
        protected by applicable copyright, trade dress, patent, and trademark
        laws, international conventions, and other laws protecting intellectual
        property and related proprietary rights. You shall not, nor shall you
        cause any other party to modify, decompile, disassemble, reverse
        engineer, copy, transfer, create derivative works from, rent,
        sub-license, distribute, reproduce framed, republish, scrape, download,
        display, transmit, post, lease or sell in any form or by any means, in
        whole or in part, use for any purpose other than for using the Services
        pursuant to these Terms or otherwise exploit any of the Services′
        Materials without DeepTuring′s explicit, prior written consent. All
        other uses of copyrighted or trade mark material, including any
        derivative use, require explicit, prior written consent from Deepturing.
        Any reproduction or redistribution of materials not in accordance with
        these Terms is explicitly prohibited and may result in the termination
        of your Account as well as severe civil and criminal penalties.
        Deepturing and/or its licensors and affiliates own all right, title, and
        interest, including copyrights and other intellectual property rights,
        in and to all the Services′ Materials. You hereby acknowledge that you
        do not acquire any ownership rights by using the Services or by
        accessing any of the Services′ Materials, or rights to any derivative
        works thereof. You are not required to provide DeepTuring with any
        feedback or suggestions regarding the Services or any of the Services′
        Materials. However, should you provide DeepTuring with comments or
        suggestions for the modification, correction, improvement or enhancement
        of the Services and/or any of the Services′ Materials, then, subject to
        the terms and conditions of these Terms, you hereby grant DeepTuring a
        non-exclusive, irrevocable, worldwide, royalty-free license, including
        the right to sublicense, to use and disclose such comments and
        suggestions in any manner Deepturing chooses and to display, perform,
        copy, have copied, make, have made, use, sell, offer to sell, and
        otherwise dispose of Deepturing′s and its sublicensees′ products and
        content embodying such comments or suggestions in any manner and via any
        media DeepTuring chooses, but without reference to the source of such
        comments or suggestions.
      </p>
      <p>
        {" "}
        <b>
          {" "}
          Subject to the foregoing, for the avoidance of any doubt, by using the
          Services DeepTuring is not granted with any rights in and to any code
          and/or other intellectual property developed by you while using the
          Services in accordance with the provisions hereof and any code
          generated and created by you while using the Services in accordance
          with these Terms of service and incorporated by you into any code you
          generate shall be deemed as part of the code generated by you for all
          purposes and Deepturing hereby grants you a non-exclusive, perpetual
          and royalty-free license to use, within the code generated by you. It
          is further clarified that any code developed by you while using the
          Services hereunder, is NOT used by DeepTuring whatsoever. (Not even
          for the purpose of improving the service provided specifically and
          solely to you).
        </b>
      </p>
      <p>
        <b>
          {" "}
          12. Disclaimer of Warranty; Limitation of Liability; Indemnification{" "}
        </b>
      </p>
      <p>
        {" "}
        You agree that your use of the Services shall be at your sole risk. To
        the fullest extent permitted by law (but without derogating from
        DeepTuring′s representation in respect of its use of FOSS as provided
        under Section 2 above), DeepTuring, its officers, directors, employees,
        and agents disclaim all warranties, explicit or implied, in connection
        with the Services and your use thereof including implied warranties of
        merchantability, title, fitness for a particular purpose or
        non-infringement, usefulness, authority, accuracy, completeness, and
        timeliness. DeepTuring makes no warranties or representations about the
        accuracy or completeness of the content of the Services, of the content
        of any sites linked to the Services, of any Third Party Materials (as
        defined below) and assumes no liability or responsibility for any:{" "}
      </p>
      <p> • Generated Code; </p>
      <p>
        {" "}
        • Errors, mistakes, inaccuracies, non-suitability or non-conformity of
        any content, including, without limitation, any Suggested/Generated
        Code;{" "}
      </p>
      <p>
        {" "}
        • Direct, indirect, special, incidental, punitive or consequential
        damages including without derogating personal injury, property damages
        and/or monetary damages, of any nature whatsoever, arising out of the
        use of or the inability to use the Services;{" "}
      </p>
      <p>
        {" "}
        • Any unauthorized access to or use of Third Party Materials, secure
        servers and/or any and all personal information and/or financial
        information stored therein;{" "}
      </p>
      <p>
        {" "}
        • Any interruption or cessation of transmission to or from the Services;{" "}
      </p>
      <p>
        {" "}
        • Any bugs, viruses, Trojan horses, or the like which may be transmitted
        to or through the Services by any third party; or{" "}
      </p>
      <p>
        {" "}
        • Any errors or omissions in any content or for any loss or damage of
        any kind incurred as a result of the use of any content posted,
        e-mailed, transmitted, or otherwise made available via the Services,
        including, without limitation, any Generated Code.{" "}
      </p>
      <p>
        {" "}
        Without derogating from the abovementioned, in no event will DeepTuring,
        its directors, officers, agents, contractors, partners, consultants
        and/or employees, be liable to you or any third person for any special,
        direct, indirect, incidental, special, punitive, or consequential
        damages whatsoever including any lost profits or lost data arising from
        your use of the Services or other materials on, accessed through or
        downloaded from the Services, whether based on warranty, contract, tort,
        or any other legal theory, and whether or not Deepturing has been
        advised of the possibility of these damages. The foregoing limitation of
        liability shall apply to the fullest extent permitted by law in the
        applicable jurisdiction. You specifically acknowledge that Deepturing
        shall not be liable for any user submissions and/or defamatory,
        offensive and/or illegal conduct by any third party, and that the risk
        of harm or damage from and/or associated with the foregoing rests
        entirely with you.{" "}
      </p>
      <p>
        {" "}
        You agree to indemnify and hold DeepTuring, and each of its directors,
        officers, agents, contractors, partners and employees, harmless from and
        against any loss, liability, claim, demand, damages, costs and expenses,
        including reasonable attorney′s fees, arising out of or in connection
        with any of the following:{" "}
      </p>
      <p>
        {" "}
        • Your use of and access to the Services, including, without limitation,
        any Generated Code;{" "}
      </p>
      <p> • Your violation of any term of these Terms; </p>
      <p>
        {" "}
        • Your violation of any third party right, including without limitation
        any copyright, property, or privacy right; or{" "}
      </p>
      <p>
        {" "}
        • Any claim that any user submission made by you has caused damage to a
        third party;{" "}
      </p>
      <p>
        <b> 13. Notices </b>
      </p>
      <p>
        {" "}
        Notices to you may be made via the Services and/or e-mail. DeepTuring
        may also provide notices of changes to these Terms or other matters by
        displaying notices or links to notices to you generally on the Services.
        You agree that all agreements, notices, disclosures and any other
        communications that Deepturing provide as aforementioned satisfy any
        legal requirement that such communications be in writing.{" "}
      </p>
      <p>
        <b> 14. General </b>
      </p>
      <p>
        {" "}
        By using or visiting the Services, you agree to the laws of the State of
        Jamaica, without regard to principles of conflict of laws and regardless
        of your location, will govern these Terms and any dispute of any sort
        that might arise between you and DeepTuring. Any claim or dispute
        between you and DeepTuring that arises in whole or in part from your use
        of the Services shall be decided exclusively by a court of competent
        jurisdiction located in Kingston, Jamaica, to the exclusion of any other
        courts, and you hereby consent to, and waive all defenses of lack of
        personal jurisdiction and forum non-convenient with respect to venue and
        jurisdiction in the courts of Kingston, Jamaica. Deepturing reserves the
        right to amend these Terms at any time and without notice, and it is
        your responsibility to review these Terms regularly for any changes.
        Your use of the Services following any amendment of these Terms will
        signify your assent to and acceptance of its revised terms.YOU AND
        DEEPTURING AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO
        THE SERVICES MUST COMMENCE WITHIN THREE (3) MONTHS AFTER THE CAUSE OF
        ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.{" "}
      </p>

      {/* <Link to="/restriction">
        <p>
          <b>Read Minimum age requirements here</b>
        </p>
      </Link> */}
    </div>
  </>
);

import React, { useContext } from "react";
import { useDropzone } from "react-dropzone";
import { ThemeContext } from "../../utils/ThemeContext";
import styles from "./styles/dragNdrop.module.css";

const DragNDrop = ({ isDragging, setDragging, setDroppedImage }) => {
  const { context } = useContext(ThemeContext);
  //   const [isDragging, setIsDragging] = useState(false); // Track dragging state
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    accept: "image/*",
    onDragEnter: () => {
      setDragging(true); // Set dragging state to true on drag enter
    },
    onDragLeave: () => {
      setDragging(false); // Set dragging state to false on drag leave
    },
    onDrop: (acceptedFiles) => {
      setDragging(false); // Set dragging state to false on drop
      setDroppedImage((prevImages) => [...prevImages, ...acceptedFiles]);
    },
  });

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: context === "dark" ? "black" : "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: context === "dark" ? "#242424" : "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    height: "100%",
    opacity: ``,
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused || isDragging ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      opacity: isDragging ? 1 : 0, // Show the component only when dragging
    }),
    [isFocused, isDragAccept, isDragReject, isDragging]
  );

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: "100%",
        width: "100%",
      }}
    >
      <div {...getRootProps({ style })} className={styles.dropZone}>
        <input
          {...getInputProps()}
          // style={{ backgroundColor: context === "dark" ? "#000" : "#fff" }}
        />
        <h5 style={{ color: context === "dark" ? "#fff" : "#000" }}>
          Drop files here
        </h5>
        {/* {droppedImage && <img src={droppedImage} alt="Dropped" />} */}
      </div>
    </div>
  );
};

export default DragNDrop;

import React from "react";
import ThankYouService from "../component/ui/TYService";
const Test = () => {
  return (
    <>
      <div style={{ display: "block", marginBottom: "20%" }}>
        <ThankYouService />
      </div>
    </>
  );
};

export default Test;

import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";

///Actions
export const fetchPlans = createAsyncThunk("fetchPlans", async () => {
  // const response = await fetch("http://localhost:3080/api/v1/plans/list");
  //changed route
  const response = await fetch("https://deepturingai.com/api/v1/plans/list");

  const result = await response.json();
  return result.plans;
});

export const selectPlanAction = createAction("Plans/selectPlan");

////Slices
const planSlice = createSlice({
  name: "Plans",
  initialState: {
    isLoading: false,
    plans: [
      {
        planSubId: "48d8bbad-e4df-4319-8ebb-c5f0da5d35ea",
        planName: "Basic",
        planPrice: "100",
        planDesc: "New plan",
      },
      {
        planSubId: "ea6ef758-b548-4f52-b039-1e4612272e8f",
        planName: "Pro",
        planPrice: "200",
        planDesc: "Pro plan",
      },
      {
        planSubId: "009c58d0-dffc-4971-9866-1d5133abb644",
        planName: "Enterprise",
        planPrice: "300",
        planDesc: "Enterprise plan",
      },
    ],
    isError: false,
    selectPlan: null,
  },
  reducers: {
    selectPlan(state, action) {
      state.selectPlan = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlans.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPlans.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      // state.plans = action.payload;
    });
    builder.addCase(fetchPlans.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export default planSlice.reducer;

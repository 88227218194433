import React from "react";
import loading from "../../assets/loading.gif";

const Spinner = () => {
  return (
    <>
      <div className="tw-flex tw-container tw-items-center tw-justify-center tw-m-auto tw-my-10 tw-h-full tw-w-full">
        <img className="" src={loading} alt="loading"/>
      </div>
    </>
  );
};

export default Spinner;

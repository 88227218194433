// import "./App.css";
// import React, { lazy, Suspense } from "react";
// import { Provider } from "react-redux";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { store } from "./app/store";
// import { TeamProvider } from "./context/TeamProvider";
// import { ImageProvider } from "./utils/ThemeContext";
// const AboutPage = lazy(() => import("./component/AboutPage"));
// const BillingPage = lazy(() => import("./component/BillingPage.js"));
// const Editprofile = lazy(() => import("./component/EditProfile.js"));
// const MainPage = lazy(() => import("./component/MainPage"));
// const SecurityPage = lazy(() => import("./component/SecurityPage"));
// const Login = lazy(() => import("./component/Login"));
// const ErrorGlitched = lazy(() => import("./component/ErrorGlitched"));
// const SaayError = lazy(() => import("./component/SaayError"));
// const BearError = lazy(() => import("./component/BearError"));
// const AddUser = lazy(() => import("./page/adduser"));
// const ThankYou = lazy(() => import("./page/thankyou"));
// const ThankYouService = lazy(() => import("./page/thankyouservice"));
// const Pricing = lazy(() => import("./page/pricing"));
// const Payment = lazy(() => import("./page/payment"));
// const Home1 = lazy(() => import("./page/comingsoon"));
// const Ide = lazy(() => import("./page/ide"));
// const Restriction = lazy(() => import("./page/restriction"));
// const ScrollTop = lazy(() => import("./hooks/ScrollTop"));
// const Dashboard = lazy(() => import("./page/dashboard"));
// const Dashboardpage = lazy(() => import("./page/dashboardpage"));
// const Charts = lazy(() => import("./page/charts"));
// const Usage = lazy(() => import("./page/useageChart"));
// const SandBox = lazy(() => import("./component/SandBox/SandBox"));
// const Coming = lazy(() => import("./component/ui/comingSoon"));
// const Home = lazy(() => import("./component/Home"));
// const FourOhFour = lazy(() => import("./component/NotfoundPage/Notfound"));
// const Teams = lazy(() => import("./page/teams"));
// const MeetApp = lazy(() => import("./component/JitsiMeet/Meet"));
// const TeamChat = lazy(() => import("./page/memberChat"));
// const WelcomeCodeRoom = lazy(() => import("./page/WelcomeCodeRoom"));
// const CodeRoom = lazy(() => import("./page/codeRoom"));
// const CodeRoomSetting = lazy(() => import("./page/codeRoomSetting"));

// function App() {
//   return (
//     <Provider store={store}>
//       <ImageProvider>
//         <Router>
//           <ScrollTop>
//             <Suspense fallback={<div>Loading...</div>}>
//               <Routes>
//                 <Route path="/" element={<MainPage />} />
//                 <Route path="/teams" element={<Teams />} />
//                 <Route
//                   path="dashboard"
//                   element={
//                     <TeamProvider>
//                       <Dashboard />
//                     </TeamProvider>
//                   }
//                 >
//                   <Route path="" element={<Dashboardpage />} />
//                   <Route path="billing" element={<BillingPage />} />
//                   <Route path="edit" element={<Editprofile />} />
//                   <Route path="security" element={<SecurityPage />} />
//                   <Route path="chart" element={<Charts />} />
//                   <Route path="userchart" element={<Usage />} />
//                   <Route path="adduser" element={<AddUser />} />
//                   <Route path="ai" element={<Home />} />
//                   <Route path="sandbox" element={<SandBox />} />
//                   <Route path="teamChat" element={<TeamChat />} />
//                   <Route
//                     path="meet"
//                     element={
//                       <main>
//                         <MeetApp />
//                       </main>
//                     }
//                   />
//                   <Route
//                     path="ide"
//                     element={<Ide ideLink="/dashboard/comingsoon" />}
//                   />

//                   <Route
//                     path="comingsoon"
//                     element={
//                       <main>
//                         <Home1 />
//                       </main>
//                     }
//                   />
//                   <Route
//                     path="notfound"
//                     element={
//                       <main>
//                         <FourOhFour returnToHappinessLink="/dashboard/ai" />
//                       </main>
//                     }
//                   />
//                   <Route
//                     path="error"
//                     element={
//                       <main>
//                         <FourOhFour returnToHappinessLink="/dashboard" />
//                       </main>
//                     }
//                   />
//                 </Route>
//                 <Route path="pricing" element={<Pricing />} />
//                 <Route path="ide" element={<Ide ideLink="/comingsoon" />} />
//                 <Route path="/payment" element={<Payment />} />
//                 <Route path="/thankyou" element={<ThankYou />} />
//                 <Route path="/restriction" element={<Restriction />} />
//                 <Route path="/thankyouservice" element={<ThankYouService />} />
//                 <Route path="/adduser" element={<AddUser />} />
//                 <Route path="/about" element={<AboutPage />} />
//                 <Route path="/login" element={<Login />} />
//                 <Route path="/comingsoon" element={<Home1 />} />
//                 <Route path="/coderoom-setting" element={<CodeRoomSetting />} />
//                 <Route path="/welcome-coderoom" element={<WelcomeCodeRoom />} />
//                 <Route path="/coderoom" element={<CodeRoom />} />
//                 <Route path="/coderoom/:id" element={<CodeRoom />} />
//                 <Route path="/404g" element={<ErrorGlitched />} />
//                 <Route path="/404s" element={<SaayError />} />
//                 <Route path="/404b" element={<BearError />} />
//                 <Route
//                   path="*"
//                   element={<FourOhFour returnToHappinessLink="/" />}
//                 />
//               </Routes>
//             </Suspense>
//           </ScrollTop>
//         </Router>
//       </ImageProvider>
//     </Provider>
//   );
// }

// export default App;

import "./App.css";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, useNavigate } from "react-router-dom";
import AboutPage from "./component/AboutPage";
import BillingPage from "./component/BillingPage.js";
import Editprofile from "./component/EditProfile.js";
import MainPage from "./component/MainPage";
import SecurityPage from "./component/SecurityPage";
import Login from "./component/Login";
import ErrorGlitched from "./component/ErrorGlitched";
import SaayError from "./component/SaayError";
import BearError from "./component/BearError";
import AddUser from "./page/adduser";
import ThankYou from "./page/thankyou";
import ThankYouService from "./page/thankyouservice";
import Pricing from "./page/pricing";
import Payment from "./page/payment";
import Home1 from "./page/comingsoon";
import Ide from "./page/ide";
import Restriction from "./page/restriction";
import ScrollTop from "./hooks/ScrollTop";
import Dashboard from "./page/dashboard";
import Dashboardpage from "./page/dashboardpage";
import Charts from "./page/charts";
import Usage from "./page/useageChart";
import SandBox from "./component/SandBox/SandBox";
import Coming from "./component/ui/comingSoon";
import Home from "./component/Home";
import FourOhFour from "./component/NotfoundPage/Notfound";
import Teams from "./page/teams";
import Checkout from "./component/Checkout";
import MeetApp from "./component/JitsiMeet/Meet";
import { ToastContainer } from "react-toastify";
import { useState } from "react";
import { TeamProvider } from "./context/TeamProvider";
import { ImageProvider } from "./utils/ThemeContext";
import TeamChat from "./page/memberChat";
// import ReduxStore from "./redux/ReduxStore";
import WelcomeCodeRoom from "./page/WelcomeCodeRoom";
import CodeRoom from "./page/codeRoom";
import CodeRoomSetting from "./page/codeRoomSetting";
import Cookies from "js-cookie";
import { addToken, addUser } from "./app/features/auth/authSlice";
import { TermsOfService } from "./component/TermsOfService";
import { PrivacyPolicy } from "./component/PrivacyPolicy";
import ForgotPassword from "./component/ForgotPassword";

import PrivateRoute from "./component/privateRoute";
import {jwtDecode}  from "jwt-decode";
import TokenExpiry from "./component/tokenExpiry";
import VsCode from "./component/ides/vscode";


function App() {
  const teamId = "5f31f440-f78c-4bc2-a478-bcb498302a23";
  const [appRoom, setAppRoom] = useState(`app-${teamId}`);

  // Redux Dispatch
  const dispatch = useDispatch();
  // Get Cookies Check User Login
  React.useEffect(() => {
    const getUser = Cookies.get("user")
      ? JSON.parse(Cookies.get("user"))
      : undefined;
    const getToken = Cookies.get("token");

    if (getToken) {
      dispatch(addToken(getToken));
      dispatch(addUser(getUser));
    }
  }, []);
  return (
    <ImageProvider>
      <ScrollTop>
        <TokenExpiry />
        <ToastContainer />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/teams" element={<PrivateRoute element={<Teams />} />} />
          {/* <Route
            path="/checkout"
            element={<PrivateRoute element={<Checkout />} />}
          /> */}
          <Route
            path="dashboard"
            element={
              <PrivateRoute
                element={
                  <TeamProvider>
                    <TokenExpiry />
                    <Dashboard />
                  </TeamProvider>
                }
              />
            }
          >

            <Route
              path=""
              element={<PrivateRoute element={<Dashboardpage />} />}
            />
            <Route
              path="downloads/vscode"
              element={<PrivateRoute element={<VsCode/>} />}
            />
            <Route
              path="billing"
              element={<PrivateRoute element={<BillingPage />} />}
            />
            <Route
              path="edit"
              element={<PrivateRoute element={<Editprofile />} />}
            />
            <Route
              path="security"
              element={<PrivateRoute element={<SecurityPage />} />}
            />
            <Route
              path="chart"
              element={<PrivateRoute element={<Charts />} />}
            />
            <Route
              path="userchart"
              element={<PrivateRoute element={<Usage />} />}
            />
            <Route
              path="adduser"
              element={<PrivateRoute element={<AddUser />} />}
            />
            <Route path="ai" element={<PrivateRoute element={<Home />} />} />
            <Route
              path="sandbox"
              element={<PrivateRoute element={<SandBox />} />}
            />
            <Route
              path="teamChat"
              element={<PrivateRoute element={<TeamChat />} />}
            />
            <Route
              path="meet"
              element={
                <PrivateRoute
                  element={
                    <main>
                      <MeetApp />
                    </main>
                  }
                />
              }
            >
              <Route
                path=""
                element={
                  <PrivateRoute element={<Dashboardpage appRoom={appRoom} />} />
                }
              />

              <Route
                path="billing"
                element={<PrivateRoute element={<BillingPage />} />}
              />
              <Route
                path="edit"
                element={<PrivateRoute element={<Editprofile />} />}
              />
              <Route
                path="security"
                element={<PrivateRoute element={<SecurityPage />} />}
              />
              <Route
                path="chart"
                element={<PrivateRoute element={<Charts />} />}
              />
              <Route
                path="userchart"
                element={<PrivateRoute element={<Usage />} />}
              />
              <Route
                path="meet"
                element={
                  <PrivateRoute
                    element={
                      <main>
                        <MeetApp />
                      </main>
                    }
                  />
                }
              />
              <Route
                path="ide"
                element={<Ide ideLink="dashboard/comingsoon" />}
              />
              <Route path="ai" element={<PrivateRoute element={<Home />} />} />

              <Route
                path="sandbox"
                element={<PrivateRoute element={<SandBox />} />}
              />
              <Route
                path="team"
                element={
                  <PrivateRoute
                    element={
                      <main>
                        <Teams />
                      </main>
                    }
                  />
                }
              />


            </Route>
            {/* <Route
              path="pricing"
              element={<PrivateRoute element={<Pricing />} />}
            /> */}
            <Route path="ide" element={<Ide ideLink="comingsoon" />} />
            <Route
              path="payment"
              element={<PrivateRoute element={<Payment />} />}
            />
            <Route
              path="thankyou"
              element={<PrivateRoute element={<ThankYou />} />}
            />
            <Route
              path="restriction"
              element={<PrivateRoute element={<Restriction />} />}
            />
            <Route
              path="thankyouservice"
              element={<PrivateRoute element={<ThankYouService />} />}
            />
            <Route
              path="adduser"
              element={<PrivateRoute element={<AddUser />} />}
            />
            <Route path="about" element={<AboutPage />} />
            <Route path="login" element={<Login />} />
            <Route path="comingsoon" element={<Home1 />} />
            <Route path="404g" element={<ErrorGlitched />} />
            <Route path="404s" element={<SaayError />} />
            <Route path="404b" element={<BearError />} />
            <Route
              path="*"
              element={<FourOhFour returnToHappinessLink="/" />}
            />
            <Route
              path="ide"
              element={<Ide ideLink="dashboard/comingsoon" />}
            />

            <Route
              path="comingsoon"
              element={
                <main>
                  <Home1 />
                </main>
              }
            />
            <Route
              path="notfound"
              element={
                <main>
                  <FourOhFour returnToHappinessLink="/dashboard/ai" />
                </main>
              }
            />
            <Route
              path="error"
              element={
                <main>
                  <FourOhFour returnToHappinessLink="/dashboard" />
                </main>
              }
            />
          </Route>
          {/* <Route path="pricing" element={<Pricing />} /> */}
          <Route path="ide" element={<Ide ideLink="/comingsoon" />} />
          <Route
              path="downloads/vscode" element={<VsCode/>} />
              
            
          <Route path="payment" element={<Payment />} />
          <Route path="thankyou" element={<ThankYou />} />
          <Route path="restriction" element={<Restriction />} />
          <Route path="thankyouservice" element={<ThankYouService />} />
          <Route path="adduser" element={<AddUser />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="tos" element={<TermsOfService />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="login" element={<Login />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="comingsoon" element={<Home1 />} />
          {/* <Route
              path="/download/vscode" element={<VsCode/>} /> */}
          <Route
            path="coderoom-setting"
            element={<PrivateRoute element={<CodeRoomSetting />} />}
          />
          <Route
            path="welcome-coderoom"
            element={<PrivateRoute element={<WelcomeCodeRoom />} />}
          />
          <Route
            path="coderoom"
            element={<PrivateRoute element={<CodeRoom />} />}
          />
          <Route
            path="coderoom/:id"
            element={<PrivateRoute element={<CodeRoom />} />}
          />

          <Route path="404g" element={<ErrorGlitched />} />
          <Route path="404s" element={<SaayError />} />
          <Route path="404b" element={<BearError />} />
          <Route path="*" element={<FourOhFour returnToHappinessLink="/" />} />
        </Routes>
      </ScrollTop>
    </ImageProvider>
  );
}

export default App;

import axios from "axios";

// const BASE_URL = "http://localhost:3080/api/v1";
const BASE_URL = "https://deepturingai.com/api/v1";

const AUTH_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiN2U3MmRlNmEtN2QyMS00OTNhLWI5YzItMGJkNGM1N2MwNjM0IiwiaWF0IjoxNjgxMDg0MjM0LCJleHAiOjE2ODEzNDM0MzR9.EgeFOUNL92YKzO8ctQH5aNiC1QgS9wyZzCHv2TkEUSM";

export async function getTextFromSpeech(formData) {
  const response = await axios.post(
    BASE_URL + "/speech-text/speech-to-text",
    formData,
    {
      "Content-Type": "multipart/form-data",
      headers: {
        Authorization: `Bearer ${AUTH_TOKEN}`,
      },
    }
  );
  return response.data;
}

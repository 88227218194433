import axios from "axios";
import { baseUrl } from "../baseUrl";
import { socketConnect } from "./testLogin";

export const getTeamProfile = async (id) => {
  const res = await axios.get(baseUrl + "/api/v1/teams/" + id);
  return res;
};

export const getTeamMessages = async (id) => {
  const res = await axios.get(socketConnect + "/api/v1/chats/" + id);
  return res;
};

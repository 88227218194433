
import vsExt from "../../../component/img/dt_vscode_ext.svg"
import style from "./index.module.css";

const VsCode = ({  }) => {

    return (
        <div className={style.vscode_home}>

        {/* // <div className="tw-mt-28 tw-grid tw-sm:tw-flex tw-grid-cols-3 tw-height-[100vh] tw-m-2 tw-mb-32 tw-p-2"> */}
            
            <div  className={ style.vscode_info}>
                <span className="tw-font-bold tw-mb-8 tw-text-white">DeepTuring IDE Extension</span>
                <h4 className="tw-font-light tw-text-white tw-mb-16">Install the Vscode extension to get started on your coding journey.</h4>
                <button type="submit" className="tw-px-3 tw-mt-4 tw-bg-white tw-text-black">
                      <>Download</>
                    </button>
            </div>
            <img src={vsExt} alt="logo" className={style.vscode_img}/>
        </div>
    )
}

export default VsCode;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import style from "./styles/MyAccount.module.css";
import user1 from "../../assets/coderoom-chat1.jpg";
import { ThemeContext } from "../../utils/ThemeContext";
import axios from "axios";
import { ReactComponent as ClipIcon } from "../../assets/clip-attach.svg";
import { useDispatch, useSelector } from "react-redux";
import LoadingModal from "../modals/LoadingModal";
import { ToastContainer } from "react-toastify";
import { editAccount } from "../../app/features/profile/profileService";
import { Spinner } from "react-bootstrap";

const MyAccount = () => {
  const { context } = useContext(ThemeContext);
  const dispatch = useDispatch();

  const [userDetails, setUserDetails] = React.useState([]);
  const [username, setUsername] = React.useState("");

  const [initialLoading, setInitialLoading] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [isSaving, setIsSaving] = React.useState(false);

  const getChatroomUser = async () => {
    setInitialLoading(() => true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/users/get_chatroom_user`,
        config
      );
      if (response) {
        setUserDetails(response.data.details);
        setUsername(response.data.details.chatRoomUsername);
        setInitialLoading(() => false);
      }
      // Handle the response data
    } catch (error) {
      "error.response.data::: ", error;
      setInitialLoading(() => false);
    }
  };

  const addChatroomUser = async () => {
    setInitialLoading(() => true);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/users/update_chatroom_user`,
        { chatroomUsername: username },
        config
      );
      if (response) {
        getChatroomUser();
      }
    } catch (error) {
      //("error.response.data::: ", error);
      setInitialLoading(() => false);
    }
  };

  const fileInputRef = React.createRef();
  const [imagePreview, setImagePreview] = React.useState(null);
  const { user } = useSelector((state) => state.auth);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    // Check if a file was selected
    if (!file) {
      return;
    }

    // Check file type (JPG or PNG)
    if (
      !file.type.includes("jpeg") &&
      !file.type.includes("png") &&
      !file.type.includes("jpg")
    ) {
      toast.error("Please select a JPG, JPEG or PNG image.");
      return;
    }

    // Check file size (5 MB limit)
    if (file.size > 5 * 1024 * 1024) {
      toast.error("File size exceeds the 5 MB limit.");
      return;
    }

    // If all checks pass, set the selected file
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleUploadButtonClick = () => {
    // Trigger the hidden file input element
    fileInputRef.current.click();
  };

  // Submit Form Function to update Profile
  const editProfileSubmitFunc = async (e) => {
    setIsSaving((prev) => true);
    e.preventDefault();
    if (username == "") {
      return false;
    }

    try {
      await addChatroomUser();
      await dispatch(
        editAccount({ userUuid: user.uuid, username, file: selectedFile })
      );
      setIsSaving((prev) => false);
    } catch (err) {
      setIsSaving((prev) => false);
    }
  };

  const handleUploadImage = async () => {};
  React.useEffect(() => {
    getChatroomUser();
  }, []);

  return username ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <form
        onSubmit={editProfileSubmitFunc}
        className={style.accountFormHolder}
      >
        <div className={style.title_div}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              color: context === "dark" ? "white" : "black",
            }}
          >
            My Account
          </div>
          <Link to="/coderoom">
            <div
              style={{
                border:
                  context === "dark" ? "2px solid white" : "2px solid black",
                borderRadius: "50%",
              }}
            >
              <svg
                viewBox="0 0 24 24"
                height="28"
                width="28"
                aria-hidden="true"
                focusable="false"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                class="StyledIconBase-ea9ulj-0 bWRyML"
                style={{ color: context === "dark" ? "white" : "black" }}
              >
                <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
              </svg>
            </div>
          </Link>
        </div>
        <div className={style.d_channel_inner_div}>
          {/* <div className={style.upload_image}>
          <img src={user1} alt="user" className={style.d_channel_img} />

          <button
            className={`${style.smileBtn} ${
              context === "dark" ? style.darkSmileBtn : ""
            }`}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleUploadImage}
          >
            <ClipIcon height="38px" width="34px" style={{ margin: "0px" }} />
            &nbsp;Attach Image
          </button>
        </div> */}

          <div className="card-body text-center">
            {/* <!-- Profile picture image--> */}
            <img
              style={{ maxWidth: "8rem", objectFit: "cover" }}
              className="img-account-profile rounded-circle mb-2"
              onError={user1}
              // src="http://bootdey.com/img/Content/avatar/avatar1.png"
              src={
                imagePreview
                  ? imagePreview
                  : user && user.profilePic
                  ? user.profilePic
                  : user1
              }
              alt=""
            />
            {/* <!-- Profile picture help block--> */}
            <div className="small font-italic text-muted mb-4">
              JPG or PNG no larger than 5 MB
            </div>
            {/* <!-- Profile picture upload button--> */}
            {/* Hidden file input */}
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleFileInputChange}
            />
            <button
              className="btn btn-primary black-btn"
              onClick={handleUploadButtonClick}
              type="button"
            >
              Upload new image
            </button>
          </div>

          <div>
            <div className={style.d_channel_user_div}>
              <div
                className={style.d_channel_user_div_p}
                style={{ color: context === "dark" ? "white" : "black" }}
              >
                {user ? user.chatRoomUsername : ""}
              </div>
              <div
                className={style.d_channel_user_div_p2}
                style={{ color: context === "dark" ? "white" : "black" }}
              >
                {user ? user.color : ""}
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "40px" }}>
          <div className={style.input_container}>
            <label
              style={{
                marginBottom: "5px",
                fontWeight: "500",
                color: context === "dark" ? "white" : "black",
              }}
            >
              USERNAME
            </label>
            <input
              type="text"
              placeholder="Username"
              width="100%"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter" && username !== "") {
                  //("ahahha::: ");
                  event.preventDefault();
                  // addChatroomUser();
                  editProfileSubmitFunc();
                }
              }}
            />
            <svg
              viewBox="0 0 24 24"
              height="20"
              width="20"
              aria-hidden="true"
              focusable="false"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              class="StyledIconBase-ea9ulj-0 bWRyML"
              className={style.edit_icon}
              style={{ color: context === "dark" ? "black" : "black" }}
            >
              <path d="M8.707 19.707 18 10.414 13.586 6l-9.293 9.293a1.003 1.003 0 0 0-.263.464L3 21l5.242-1.03c.176-.044.337-.135.465-.263zM21 7.414a2 2 0 0 0 0-2.828L19.414 3a2 2 0 0 0-2.828 0L15 4.586 19.414 9 21 7.414z"></path>
            </svg>
          </div>
        </div>

        <button
          className="btn btn-primary black-btn"
          style={{ display: "flex" }}
          type="submit"
        >
          {isSaving ? <Spinner animation="grow" size="sm" /> : "Save changes"}
        </button>
      </form>
    </div>
  ) : (
    <div>
      <LoadingModal
        showImageChecking={initialLoading}
        isGptLoading={initialLoading}
        initialLoading={initialLoading}
      />
      <ToastContainer />
    </div>
  );
};

export default MyAccount;

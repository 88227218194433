import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import io from "socket.io-client";
import useSound from "use-sound";
import notification from "../../src/assets/notification.mp3";
import { getUserInfo, socketConnect } from "../Service/testLogin";
const TeamContext = React.createContext();

export function useTeam() {
  return useContext(TeamContext);
}

export function TeamProvider({ children }) {
  const [mainRoom, setMainRoom] = useState(); //Accessed by the user that are not in the team. like status_change event
  const [currentRoom, setCurrentRoom] = useState(null); //only for team members. Include both private and group chats
  const [loginUser, setLoginUser] = useState(null);
  const [socket, setSocket] = useState(null);
  const [notiIndicator, setNotiIndicator] = useState({});
  const [sidebarNotiIndicator, setSidebarNotiIndicator] = useState(false);
  const [recNoti, setRecNoti] = useState("");
  const [isEnterprise, setIsEnterprise] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [teamId, setTeamId] = useState();

  let location = useLocation();
  const [play] = useSound(notification);

  useEffect(() => {
    let userInfo = getUserInfo();
    let accountId = "";
    if (userInfo.user) {
      setIsEnterprise(true);
      setUserInfo(userInfo);
      accountId = userInfo.user.uuid;
      setTeamId(accountId);
      setMainRoom(`BASIC-${accountId}`);
    }
    if (userInfo.subuser) {
      setIsEnterprise(false);
      setUserInfo(userInfo);
      accountId = userInfo.subuser.userId;
      setTeamId(accountId);
      setMainRoom(`BASIC-${accountId}`);
    }

    let loginUser = localStorage.getItem("loginResponse")
      ? JSON.parse(localStorage.getItem("loginResponse"))
      : "7e72de6a-7d21-493a-b9c2-0bd4c57c0637";
    setLoginUser(loginUser);

    const socket = io.connect(socketConnect);
    setSocket(socket);
    socket.emit("join_room", { room: `BASIC-${accountId}`, userId: loginUser });
    return () => {
      socket.disconnect();
      setSocket(null);
      // localStorage.removeItem("status");
    };
  }, []);

  useEffect(() => {
    if (socket === null) {
      return;
    }
    socket.on("sent_member_status", (data) => {
      let statusUpdate = data.status;
      for (let x in data.status) {
        data.users.filter((user) => {
          return user.id === x;
        }).length === 0
          ? (statusUpdate[x] = "2")
          : "";
      }
      localStorage.setItem("status", JSON.stringify(statusUpdate));
    });
    socket.on("receive_noti_indicator", (data) => {
      //("FROM TEAM PROVIDER");
      setRecNoti(data);
    });
  }, [socket]);

  useEffect(() => {
    if (location.pathname === "/dashboard/teamChat") {
      return;
    }
    if (recNoti === "") {
      return;
    }
    if (recNoti.sender === loginUser) {
      return;
    }
    if (recNoti.chatroom === currentRoom) {
      return;
    }
    if (!recNoti.mentionedReceivers) {
      // event is for private chats
      if (recNoti.receiverId === loginUser) {
        play();
        location.pathname !== "/dashboard/teamChat" &&
          setSidebarNotiIndicator(true);
      }
      setNotiIndicator((prev) => {
        const updatedNoti = { ...prev };
        updatedNoti[recNoti.chatroom] = true;
        return updatedNoti;
      });
    } else {
      //event is for group chats
      let filteredMember = recNoti.mentionedReceivers.filter((member) => {
        return member.id === loginUser;
      });
      if (filteredMember.length !== 0) {
        play();
        location.pathname !== "/dashboard/teamChat" &&
          setSidebarNotiIndicator(true);
      }
    }
  }, [recNoti]);

  useEffect(() => {
    if (Object.keys(notiIndicator).length === 0) {
      return;
    }

    localStorage.setItem("notification", JSON.stringify(notiIndicator));
  }, [notiIndicator]);

  const value = useMemo(
    () => ({
      loginUser,
      setLoginUser,
      socket,
      room: mainRoom,
      setSocket,
      notiIndicator,
      setNotiIndicator,
      sidebarNotiIndicator,
      setSidebarNotiIndicator,
      currentRoom,
      setCurrentRoom,
      teamId,
      isEnterprise,
      setIsEnterprise,
      userInfo,
    }),
    [
      loginUser,
      setLoginUser,
      socket,
      mainRoom,
      notiIndicator,
      sidebarNotiIndicator,
      currentRoom,
      teamId,
      isEnterprise,
      setIsEnterprise,
      userInfo,
    ]
  );

  return <TeamContext.Provider value={value}>{children}</TeamContext.Provider>;
}

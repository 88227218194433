import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import style from "./allide.module.css";
import Comingsoon from "./comingSoon/index";
import { useLocation, } from "react-router-dom";

const imgData = [
  {
    name: "Vscode",
    img: "/img/ide (10).png",
  },
  {
    name: "Visual Studio",
    img: "/img/ide (6).png",
  },
  {
    name: "Jetbrains IDES",
    img: "/img/ide (2).png",
  },
  {
    name: "Intellij",
    img: "/img/ide (1).png",
  },
  {
    name: "Eclipse",
    img: "/img/ide (9).png",
  },
  {
    name: "Andriod Studio",
    img: "/img/ide (8).png",
  },
  {
    name: "Jupyter",
    img: "/img/ide (3).png",
  },
  {
    name: "Sublime Text",
    img: "/img/ide (5).png",
  },
  {
    name: "Xcode",
    img: "/img/ide (7).png",
  },
  {
    name: "Netbeans",
    img: "/img/ide (4).png",
  },
];

const Allide = ({ ideLink }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [path, setPath] = useState(null);
  useEffect(() => {
    console.log("Location >> ", { location });
    setPath(location.pathname);
  }, []);

  const handleIdeRoute = (e) => {
    if(path == "/ide"){
      return true
    }
    let link =""
    switch(e){
      case 'Vscode':
        link = "/dashboard/downloads/vscode"
        break;
      default: 
        link = path == "/ide" ? "/comingsoon" : "/dashboard/comingsoon"
        break
    }

    navigate(link)
  }

  return (
    <div className={style.allide}>
      {imgData.map(({ name, img }, index) => (
        // <Link key={index} onClick={() => setIsComingSoon(true)}>
        <div
          key={index}
          onClick={() => handleIdeRoute(name)}
          // to={path && path == "/ide" ? "/comingsoon" : "/dashboard/comingsoon"}
        >
          <div
            key={index}
            style={{
              display: "grid",
              placeItems: "center",
              padding: "2rem",
            }}
          >
            <div
              style={
                index === 2
                  ? { width: "12rem", marginTop: "2rem", marginBottom: "2rem" }
                  : index === 6
                  ? {
                      width: "16rem",
                    }
                  : index === 7
                  ? { width: "14rem", marginBottom: "1rem", marginTop: "1rem" }
                  : { width: "15rem", marginBottom: "1rem" }
              }
            >
              <img style={{ width: "100%" }} src={img} alt={name} />
              {/* <h2>HELLO</h2> */}
            </div>
            <h4>{name}</h4>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Allide;

import React from "react";
import { Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function PrivateRoute({ element, ...rest }) {
  const user = JSON.parse(localStorage.getItem("loginResponse"))
  const isAuthenticated = user && user.user.email
  return isAuthenticated ? element : <Navigate to="/404g" replace />;
}

export default PrivateRoute;

import React from "react";
import { Link, useLocation } from "react-router-dom";

const TeamTab = () => {
  const { pathname } = useLocation();

  return (
    <>
      <nav className="nav nav-borders" style={{ paddingTop: "4rem" }}>
        <Link
          style={{
            color: pathname === "/dashboard/teamChat" ? "#0061f2" : "#69707a",
            borderBottomColor:
              pathname === "/dashboard/teamChat" ? "#0061f2" : "none",
          }}
          className="nav-link ms-0"
          to="/dashboard/teamChat"
        >
          Team Chat
        </Link>
        <Link
          style={{
            color: pathname === "/dashboard/adduser" ? "#0061f2" : "#69707a",
            borderBottomColor:
              pathname === "/dashboard/adduser" ? "#0061f2" : "none",
          }}
          className="nav-link active"
          to="/dashboard/adduser"
        >
          User
        </Link>
      </nav>
    </>
  );
};

export default TeamTab;

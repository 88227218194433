import React from "react";
import Payment from "../component/ui/payment";

const Test = () => {
  return (
    <>
      <div style={{ display: "block", marginBottom: "20%" }}>
        <Payment />
      </div>
    </>
  );
};

export default Test;

import React, { useRef } from "react";
import { MentionsInput, Mention } from "react-mentions";
import { ThemeContext } from "../../../utils/ThemeContext";
const users = [
  {
    id: "Codebot",
    display: "@Codebot",
  },
];
const MentionsInputComp = ({ message, handleChange, handleKeyDown }) => {
  React.useEffect(() => {
    document.querySelector(".x-custom-ipt__input").focus();
  }, []);
  const { context } = React.useContext(ThemeContext);
  const defaultStyle = {
    control: {
      backgroundColor: context === "dark" ? "#242424" : "#fff",
      fontSize: 14,
      fontWeight: "normal",
      color: "#FF7715",
      display: "flex",
      flexWrap: "nowrap",
    },
    highlighter: {
      overflow: "hidden",
    },
    input: {
      margin: 0,
      overflow: "hidden",
      height: "100%",
      color: context === "dark" ? "#fff" : "#000",
    },
    "&multiLine": {
      control: {
        fontFamily: "monospace",
      },
      highlighter: {
        padding: 9,
      },
      input: {
        padding: 9,
        minHeight: 3,
        outline: 0,
      },
    },
    suggestions: {
      bottom: "unset", // Remove the bottom positioning
      top: "0", // Position suggestions at the top
      list: {
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 14,
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: "#FF7715",
        },
      },
    },
  };
  defaultStyle.parent = {
    maxHeight: "300px", // Set the desired maximum height
  };
  const defaultMentionStyle = {
    marginLeft: "-10px",
  };
  return (
    <MentionsInput
      value={message}
      onChange={handleChange}
      style={defaultStyle}
      onKeyDown={handleKeyDown}
      className="x-custom-ipt"
    >
      <Mention trigger="@" data={users} style={defaultMentionStyle} />
    </MentionsInput>
  );
};

export default MentionsInputComp;

import React from "react";
import ReactDOM from "react-dom/client";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga4";
import "./index.css";
import App from "./App";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { BrowserRouter as Router } from "react-router-dom";

try {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    debug: false,
  });
} catch (error) {
  console.log("Mixpanel Error: ", error);
}

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  debug: false,
});
ReactGA.initialize(process.env.REACT_APP_GA_TOKEN);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
  <React.StrictMode>
    {/* <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    /> */}
    <QueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            queries: {
              refetchOnWindowFocus: false,
            },
          },
        })
      }
    >
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
    </>

);

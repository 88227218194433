import { createSlice } from "@reduxjs/toolkit";

const reactScrollSlice = createSlice({
  name: "reactScroll",
  initialState: {
    FAQ: false,
  },

  reducers: {
    moveToFAQ: (state, action) => {
      state.FAQ = action.payload;
    },
  },
});

export const { moveToFAQ } = reactScrollSlice.actions;

export default reactScrollSlice.reducer;

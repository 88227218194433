// export const baseUrl = "https://sluucorp-backend.onrender.com/api/v1";
// export const baseUrl = process.env.REACT_APP_BASE_URL + "/api/v1";
// =======
// export const baseUrl = "https://sluucorp-backend.onrender.com/api/v1";
// export const baseUrl = process.env.REACT_APP_BASE_URL;

// export const baseUrl = "http://localhost:3080/api/v1";
// export const localBaseUrl = "http://localhost:3080/api/v1";
export const localBaseUrl = "https://deepturingai.com/api/v1";
export const baseUrl = "https://deepturingai.com/api/v1";

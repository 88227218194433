import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import Cookies from "js-cookie";

export const editProfile = createAsyncThunk(
  "profile/editProfile",
  async (payload, thunkAPI) => {
    try {
      const {
        userUuid, // Extracts the 'userUuid' property from 'payload'
        editProfileObj: { firstName, lastName }, // Destructures 'editProfileObj' and extracts 'firstName' and 'lastName' from it
        file, // Extracts the 'file' property from 'payload'
      } = payload;
      // Create a new FormData object
      const formData = new FormData();

      // Append the file and other data to the FormData
      if (file) {
        formData.append("file", file);
      }
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);

      const request = await axiosInstance.patch(
        `/users/updateProfile/${userUuid}`,
        formData, // Use the FormData here
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
          },
        }
      );
      const response = request;
      if (response.data.user) {
        Cookies.set("user", JSON.stringify(response.data.user), {
          secure: true,
        });
      }

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const editAccount = createAsyncThunk(
  "profile/editProfile",
  async (payload, thunkAPI) => {
    try {
      const {
        userUuid, // Extracts the 'userUuid' property from 'payload'
        username,
        file, // Extracts the 'file' property from 'payload'
      } = payload;
      // Create a new FormData object
      const formData = new FormData();

      // Append the file and other data to the FormData
      if (file) {
        formData.append("file", file);
      }
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);

      const request = await axiosInstance.patch(
        `/users/updateProfile/${userUuid}`,
        formData, // Use the FormData here
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
          },
        }
      );
      const response = request;
      if (response.data.user) {
        Cookies.set("user", JSON.stringify(response.data.user), {
          secure: true,
        });
      }

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

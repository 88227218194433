import React, { useState } from "react";
import { IoSettingsSharp } from "react-icons/io5";
import style from "../../page/dashboard.module.css";
import Dropdown from "react-bootstrap/Dropdown";
import person from "../img/person.png";
import { useTeam } from "../../context/TeamProvider";
import { statusDetail } from "../../utils/functions";
import { useSelector } from "react-redux";

function DasboardHeader() {
  const { loginUser, socket, room } = useTeam();
  const { setMyStatus } = useTeam();
  const [hideDropdwon, setHideDropDown] = useState(true);
  const { user } = useSelector((state) => state.auth);

  const handleStatusChange = (status) => {
    if (socket === null) {
      return;
    }
    // setMyStatus(status)
    socket.emit("send_change_status", {
      userId: loginUser,
      status: status,
      room: room,
    });
    setHideDropDown(true);
  };

  return (
    <React.Fragment>
      <section>
        <header className={style.header}>
          <h2>
            {/* <label htmlFor="nav-toggle">
            </label> */}
            Dashboard
          </h2>
          <div className={style.user_wrapper}>
            <img
              src={user && user.profilePic ? user.profilePic : person}
              width="40px"
              height="40px"
              alt="profile-img"
            />
            <div style={{ margin: "0px 0.6rem" }} className>
              <h4>{user ? `${user.firstName} ${user.lastName}` : "User"}</h4>
            </div>
            <div style={{ position: "relative" }}>
              <IoSettingsSharp
                className="tw-cursor-pointer"
                style={{ fontSize: "1.7rem" }}
                onClick={() => {
                  setHideDropDown((prev) => {
                    return !prev;
                  });
                }}
              />
              <div
                hidden={hideDropdwon}
                style={{
                  position: "absolute",
                  backgroundColor: "white",
                  right: "0",
                  top: "2.5rem",
                  border: "0.5px solid #888888",
                  borderRadius: "8px",
                  padding: "0.2rem 0.5rem",
                }}
              >
                {statusDetail.map((status, index) => {
                  return (
                    <DropdownMenu
                      key={index}
                      text={status.name}
                      color={status.color}
                      click={() => {
                        handleStatusChange(status.value);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </header>
      </section>
    </React.Fragment>
  );
}

function DropdownMenu({ text, color, click, key }) {
  return (
    <Dropdown.Item
      key={key}
      style={{ display: "flex", alignItems: "center" }}
      onClick={click}
    >
      <div
        style={{
          borderRadius: "50%",
          backgroundColor: color,
          height: "12px",
          width: "12px",
          marginRight: "0.5rem",
        }}
      ></div>
      {text}
    </Dropdown.Item>
  );
}

export default DasboardHeader;

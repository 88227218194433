import React, { useEffect, useState } from "react";
import ChatHeader from "./ChatHeader";
import ChatMessages from "./ChatMessages";
import ChatFooter from "./ChatFooter";
import io from "socket.io-client";
import { socketConnect } from "../../Service/testLogin";

const Inbox = ({
  setInboxToggle,
  user: userId,
  teamProfile,
  loginUser,
  handleHeaderBackArrow,
  room,
  status,
}) => {
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState("");
  const [messageReceived, setMessageReceived] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [receiverProfile, setReceiverProfile] = useState(false);
  const [typingIndicator, setTypingIndicator] = useState("");
  useEffect(() => {
    const socket = io.connect(socketConnect);
    setSocket(socket);
    socket.emit("join_room", { room: room, userId: loginUser });
    return () => {
      socket.disconnect();
      setSocket(null);
    };
  }, []);

  useEffect(() => {
    if (socket === null) {
      return;
    }
    socket.on("receive_message", (data) => {
      setMessageReceived(data.chat);
    });
    socket.on("receive_typing_indicator", (data) => {
      setTypingIndicator(() => {
        return { id: data.id, name: data.name, typing: data.typing };
      });
    });
  }, [socket]);

  useEffect(() => {
    if (messageReceived === "") {
      return;
    }
    setMessageList((prev) => {
      return [messageReceived, ...prev];
    });
    setMessageReceived("");
  }, [messageReceived]);

  useEffect(() => {
    if (!userId) {
      return;
    }
    setReceiverProfile(() => {
      return teamProfile.members.filter((member) => {
        return member.id === userId;
      })[0];
    });
  }, [userId]);

  return (
    <div
      className={`tw-flex tw-h-full lg:tw-col-span-4 tw-col-span-full lg:tw-flex tw-flex-col tw-justify-between tw-drop-shadow-lg tw-rounded-lg tw-shadow-lg tw-shadow-slate-400`}
    >
      <ChatHeader
        loading={false}
        handleBackArrow={handleHeaderBackArrow}
        groupChat={false}
        receiverProfile={receiverProfile}
        typingIndicator={typingIndicator}
        status={status}
      />
      <ChatMessages
        clientMessages={messageList}
        setClientMessages={setMessageList}
        loginUser={loginUser}
        groupChat={false}
        room={room}
      />
      <ChatFooter
        message={message}
        setMessage={setMessage}
        loginUser={loginUser}
        setMessageList={setMessageList}
        room={room}
        socket={socket}
        groupChat={false}
        memberCount={teamProfile.members.length}
        receiver={userId}
      />
    </div>
  );
};

export default Inbox;

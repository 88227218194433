import React, { useEffect } from "react";
import { useLocation } from "react-router";
import ReactGA from "react-ga4";
import Allide from "../component/ui/allide";
import mixpanel from "mixpanel-browser";

const Ide = ({ ideLink }) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.event("page_view", { page_title: "IDE Page" });
    ReactGA.send({
      page: location.pathname,
      hitType: "pageview",
      title: "IDE Page",
    });

    try {
      mixpanel.track("IDE Page");
    } catch (error) {
      console.log("Mixpanel Error : ", error);
    }
  }, []);

  return (
    <div>
      <section>
        <div
          style={{
            textAlign: "center",
            textIndent: "0.2rem",
            marginTop: "6rem",
          }}
        >
          <h2 style={{ marginBottom: "2rem", fontSize: "3.1rem" }}>
            Choose an IDE
          </h2>
          <h4 style={{ color: "#5e5c5c" }}>
            We support all major IDEs, find your below :{" "}
          </h4>
        </div>
        <div>
          <Allide ideLink={ideLink} />
        </div>
      </section>
    </div>
  );
};

export default Ide;

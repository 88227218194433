import React, { useContext } from "react";
import style from "./styles/CodeRoomHome.module.css";
// import useMode from "../hooks/mode";
// import Homecard from "./common/HomeCard";
import { ThemeContext } from "../../utils/ThemeContext";

const CodeRoomHome = () => {
  const { context } = useContext(ThemeContext);
  return (
    <div className={style.coderoom_home}>
      <div></div>
    </div>
  );
};

export default CodeRoomHome;

import { createSlice } from "@reduxjs/toolkit";
import { loginUser, signUpUser } from "./authService";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    user: null,
    token: null,
    message: null,
    isError: false,
    isSuccess: false,
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.user = null;
        state.isError = false;
        state.message = null;
        state.isSuccess = false;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.data.user;
        state.token = action.payload.token;
        state.isError = false;
        state.message = action.payload.message;
        state.isSuccess = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(signUpUser.pending, (state) => {
        state.loading = true;
        state.user = null;
        state.isError = false;
        state.message = null;
        state.isSuccess = false;
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = null;
        state.isError = false;
        state.message = "Account Created Successfully";
        state.isSuccess = true;
      })
      .addCase(signUpUser.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      });
  },
  reducers: {
    addUser: (state, action) => {
      state.user = action.payload;
    },
    addToken: (state, action) => {
      state.token = action.payload;
    },
    logout: (state, action) => {
      state.token = null;
      state.user = null;
    },
    editIsSuccess: (state, action) => {
      state.isSuccess = action.payload;
    },
  },
});

export const { addUser, addToken, logout, editIsSuccess } = authSlice.actions;

export default authSlice.reducer;

import axios from "axios";
import { baseUrl } from "../baseUrl";

export const forgotPassword = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/users/forgotpassword`, { email })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

export const resetPassword = (password, token) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/users/resetpasswordbytoken`, {
        password,
        token,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

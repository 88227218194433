import React from "react";
import AddUser from "../component/ui/addUser";
import Teams from "./teams";

const Test = () => {
  return (
    <>
      <Teams>
        <AddUser />
      </Teams>
    </>
  );
};

export default Test;

import axios from "axios";
import { baseUrl } from "../baseUrl";
import Cookies from "js-cookie";

// Function to get the token from the cookie
const getTokenFromCookie = () => {
  return Cookies.get("token");
};

// Update Axios defaults with interceptors
axios.defaults.baseURL = baseUrl;
axios.defaults.headers.common["Content-Type"] = "application/json";

// Request interceptor to add Authorization header with token from cookie
axios.interceptors.request.use(
  (config) => {
    const authToken = getTokenFromCookie();
    if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axios;



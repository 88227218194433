import React, { useRef, useCallback, useState, useEffect } from "react";
import Editor from "../Editor";
import sandbox from "./sandbo";
import Loader from "../ui/Loader";
import { FaMicrophone } from "react-icons/fa";
import { serverUrl, token } from "../../importantVar";
// import {
//   useCodexResetStateDispatch,
//   useCodexStateContext
// } from "./context/codexContext";

import { BsFillArrowRightCircleFill } from "react-icons/bs";
import Dropdown from "../DropDown";
import useLocalStorage from "../hooks/useLocalStorage";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { VoiceToText } from "../voice-to-text";
import mixpanel from "mixpanel-browser";
import Cookies from "js-cookie";

const SandBox = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [command, setCommand] = useState("");
  const [lang, setLang] = useState("javascript");
  const [loading, setLoading] = useState(false);
  // const resetCodexState = useCodexResetStateDispatch();
  const [checked, setChecked] = useState(false);
  const [renderedContent, setRenderedContent] = useState("");
  const [html, setHtml] = useLocalStorage("html", "");
  const [css, setCss] = useLocalStorage("css", "");
  const [js, setJs] = useLocalStorage("js", "");

  const [temp, setTemp] = useLocalStorage("temp", "");
  const [token, setToken] = useState("");

  useEffect(() => {
    let userToken = Cookies.get("token");
    setToken(userToken);
  }, []);
  // const serverUrl = `http://localhost:${process.env.SERVER_PORT}`;
  // const codeDivRef = useRef<HTMLDivElement>(null);
  // const { state } = useCodexStateContext();
  // const inputRef = useRef<ITextField>(null);

  const onChange = (e) => {
    setCommand(e.target.value);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      setRenderedContent(`
      <html>
      <style>${css}</style>
      <body></body>
      <script>${js}</script>
      </html>
      `);
    }, 250);

    return () => clearTimeout(timeout);
  }, [js]);
  //(loading);

  const submitCommand = async (event) => {
    setLoading(true);
    event.preventDefault();

    let obj = {};
    // let url = `http://localhost:3080/api/v1/codexSandbox/codegen`;
    let url = `https://deepturingai.com/api/v1/codexSandbox/codegen`;
    obj["query"] = command;
    var json = JSON.stringify(obj);
    //(json);

    await axios({
      method: "POST",
      url,
      data: json,
      headers: {
        "Content-Type": "application/json;",
        authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        console.log("res", response);
        console.log("re:-", response.data.code);

        if (temp === "") {
          setTemp("/*" + command + "*/  \n" + response.data.code);
          setJs("/*" + command + "*/  \n" + response.data.code);
          setLoading(false);
        } else {
          setTemp(temp + "/*" + command + "*/ \n" + response.data.code);
          setJs(temp + "/*" + command + "*/\n" + response.data.code);
          setLoading(false);
        }
        setHtml(command);
        setCommand("");
      })
      .catch(function (err) {
        //(err);
        setLoading(false);
        setHtml(command);
        setCommand("");
      });
  };
  //   const handleReset = useCallback(() => {
  //     if (codeDivRef.current != null) {
  //         codeDivRef.current.innerText = "";
  //     }
  //     //("resetting context");
  //     fetch(`${serverUrl}/reset`)
  //         .then((response) => response.json())
  //         .then((res) => {
  //             //(`Reset context: ${res.context}`);
  //         })
  //         .catch((error) => console.error(error));

  //     // reset
  //     for (const key in state.intervals) {
  //         if (state.intervals.hasOwnProperty(key)) {
  //             const interval = state.intervals[key];
  //             //(`Clearing interval ${key}`);
  //             clearInterval(interval);
  //         }
  //     }

  //     resetCodexState();
  // }, [resetCodexState, serverUrl]);
  const resetHandle = async () => {
    setJs("");
    setTemp("");
    setHtml("");
    let url = `${serverUrl}codexSandbox/reset`;
    await axios({
      method: "GET",
      url,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response)
      .then((res) => {
        //(`Reset context: ${res.context}`);
      })
      .catch((error) => console.error(error));

    // // reset
    // for (const key in state.intervals) {
    //     if (state.intervals.hasOwnProperty(key)) {
    //         const interval = state.intervals[key];
    //         //(`Clearing interval ${key}`);
    //         clearInterval(interval);
    //     }
    // }
  };
  const handleLangChange = (e) => {
    setLang(e);
    // navigate("/dashboard/comingsoon");
    console.log(e);

    try {
      mixpanel.track("Sandbox Page", {
        "Choose Language": lang,
      });
    } catch (error) {
      console.log("Mixpanel Error : ", error);
    }
  };

  useEffect(() => {
    ReactGA.event("page_view", { page_title: "Sandbox Page" });
    ReactGA.send({
      page: location.pathname,
      hitType: "pageview",
      title: "Sandbox Page",
    });
  }, []);

  return (
    <div className="tw-h-full tw-w-full">
      {/* <Editor
        language="javascript"
        languageName="JS"
        value={js}
        onChange={setJs}
      /> */}
      <div className="tw-flex tw-bg-gray-100 tw-overflow-hidden tw-h-screen tw-py-20">
        <section className="tw-w-3/4.5 tw-mt-13 tw-px-10 tw-h-full tw-flex tw-flex-col tw-gap-5">
          <div className="tw-bg-white tw-w-full tw-dropshadow-lg tw-h-45 tw-rounded-lg">
            <iframe
              srcDoc={renderedContent}
              title="output"
              sandbox={sandbox}
              frameBorder="0"
              width="100%"
              height="100%"
              loading="lazy"
            />
          </div>
          <div className="tw-h-auto tw-flex tw-w-full tw-bg-white tw-px-6 tw-rounded-lg tw-justify-between tw-items-center tw-mb-12">
            <div className="tw-w-full tw-my-4">
              <textarea
                className="tw-w-full tw-outline-none tw-border-0 tw-overflow-y-hidden tw-text-lg tw-h-12 tw-flex tw-py-2"
                cols="30"
                value={command}
                placeholder="Provide instructions..."
                onChange={onChange}
              />
            </div>
            <div className="tw-flex tw-items-center tw-justify-end">
              <Dropdown
                value={lang}
                onChange={handleLangChange}
                options={["javascript", "reactjs", "babylon.js"]}
              />

              <VoiceToText setText={setCommand} />
              <button
                className="tw-h-auto tw--m-3 tw-w-auto tw-bg-white tw-border-0 tw-outline-none tw-flex tw-items-center tw-justify-center tw-rounded-full"
                // className="tw-bg-white tw-border-0"
                onClick={submitCommand}
              >
                <div className="tw-px-4 tw--mb-12 tw--mr-5">
                  {loading && <Loader />}
                </div>

                <BsFillArrowRightCircleFill className="tw-h-8 tw-w-8 tw-text-black" />
              </button>
              {/* <Record /> */}
            </div>
          </div>
        </section>
        <section
          style={{ borderLeft: "2px solid #b0b0b033" }}
          className="tw-w-1/4.5 tw-px-6 tw-py-8 tw-h-screen "
        >
          <div className="tw-w-full tw-flex tw-justify-end">
            <button
              onClick={resetHandle}
              className="tw-bg-black tw-px-3  tw-text-white tw-rounded-lg"
            >
              Clear all
            </button>
          </div>
          <Editor
            language="javascript"
            languageName="JS"
            value={js}
            onChange={setJs}
          />

          {/* {
            result.map((item, index) => (
              <span>{ item.text }</span>
            ))
          } */}
        </section>
      </div>
    </div>
  );
};

export default SandBox;

import { useParams } from "react-router-dom";
import style from "../coderoom/styles/CodeRoomChannel.module.css";

export const renderChatContent = (val, context) => {
  const roomId = useParams().id;

  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!urlPattern.test(urlString);
  };

  switch (val.chat.text) {
    case "Your photo(s) has been removed.":
      return (
        <div className={style.dangerAlert}>
          Your photo(s) has been removed, since it contains <b>NSFW</b> content.
        </div>
      );
    case "Oops! It looks like your message includes inappropriate language.":
      return (
        <div className={style.dangerAlert}>
          Oops! It looks like your message includes inappropriate language.
        </div>
      );
    default:
      return (
        <>
          <div
            className={style.d_channel_user_div_p2}
            style={{
              color: context === "dark" ? "white" : "black",
              fontSize: "large",
            }}
          >
            {isValidUrl(val.chat.text) ? (
              <div className={style.d_channel_user_div_img}>
                <img key={1} src={val.chat.text} alt="" />
              </div>
            ) : (
              val.chat.text
            )}
          </div>
          <div className={style.d_channel_user_div_img}>
            {val.room === roomId && val.chat.image && val.chat.image.length > 0
              ? val.chat.image.map((image, imageIndex) => (
                  <>
                    <img
                      key={imageIndex}
                      src={`${process.env.REACT_APP_BASE_URL}/uploads/${image.image}`}
                      alt=""
                    />
                  </>
                ))
              : null}
          </div>
        </>
      );
  }
};

import React, { useContext, useEffect, useMemo, useState } from "react";
import style from "./styles/CodeRoomChannelName.module.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ThemeContext } from "../../utils/ThemeContext";
import { useTeam } from "../../context/TeamProvider";
import useSound from "use-sound";
import { useSelector } from "react-redux";
import io from "socket.io-client";

import notification from "../../assets/notification.mp3";
import {
  getNewMessages,
  setChannelsCache,
  setSeenMessage,
} from "../../Service/chatService";

// Socket.io initialization
const socket = io.connect(process.env.REACT_APP_BASE_URL, {
  maxHttpBufferSize: 1e8,
  pingTimeout: 60000,
  compress: true,
});

const CodeRoomChannelName = ({
  handleSectionClick,
  setAllChannels,
  incomingMessage,
  activeSection,
}) => {
  const { context } = useContext(ThemeContext);
  const roomId = useParams().id;
  const navigate = useNavigate();
  const [channels, setChannels] = React.useState([]);

  const { user } = useSelector((state) => state.auth);

  const [recNoti, setRecNoti] = useState("");
  const [notiIndicator, setNotiIndicator] = useState({});
  const [play] = useSound(notification);
  const [loginUser, setLoginUser] = useState(null);
  const [cachedChat, setCachedChat] = React.useState([]);
  const [messageReceived, setMessageReceived] = useState("");
  const [unSeenMsgList, setUnSeenMsgList] = useState([]);

  const [clientCache, setClientCache] = useState([]);

  const [newMsg, setNewMsg] = useState([]);
  let newArray = [];

  React.useEffect(() => {
    let newMsgsDelivered = getNewMessages();
    setNewMsg((prev) => newMsgsDelivered);

    // if (cachedChat && cachedChat.length === 0) {
    //   //("SET FIRST CACH");
    //   setCachedChat(incomingMessage);
    // }

    let notificationIndicator = localStorage.getItem("notification")
      ? JSON.parse(localStorage.getItem("notification"))
      : {};

    let loginUser = localStorage.getItem("loginUser")
      ? JSON.parse(localStorage.getItem("loginUser"))
      : "7e72de6a-7d21-493a-b9c2-0bd4c57c0637";
    setLoginUser(loginUser);

    setNotiIndicator(notificationIndicator);

    //("NOTIFICATION >>> ", { notiIndicator });
  }, []);

  React.useEffect(() => {
    // let clientCached = getCacheChatInClient();
    // let chats = clientCached.length > 0 ? clientCached : incomingMessage;
    let newMsgsDelivered = getNewMessages();
    console.log("ICOMING > ", newMsgsDelivered.length, newMsg.length);
    if (newMsgsDelivered.length > newMsg.length) {
      play();
    }
    setNewMsg((prev) => newMsgsDelivered);
    // setClientCache(chats);
  }, [incomingMessage]);

  const isNewNotif = (currentRoomID) => {
    let hasNewMsg = newMsg.find((_msg) => _msg.room == currentRoomID);
    if (hasNewMsg) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (socket === null) {
      return;
    }
    socket.on("receive_message", (data) => {
      // //("MESSAGE RECIEVED >>> ", { data });
      setMessageReceived(data.chat);
    });
  }, [socket]);

  const handleOnClick = (room, uuid) => {
    if (messageReceived && messageReceived.length > 0) {
      let msgReceiver = messageReceived.filter(
        (msg) => msg.room !== currentRoomID
      );
      setMessageReceived((prev) => msgReceiver);
    }

    setSeenMessage(uuid);
    let newMsgRemoved = newMsg.filter((_msg) => _msg.room != uuid);
    setNewMsg((prev) => newMsgRemoved);
    handleSectionClick(room, uuid);
  };

  const calculateRoom = (senderId, receiverId) => {
    return receiverId >= senderId
      ? receiverId.toString() + senderId.toString()
      : senderId.toString() + receiverId.toString();
  };

  React.useEffect(() => {
    //("USER CALLED >>> ", { user });
  }, [user]);

  React.useEffect(() => {
    if (socket && socket === null) {
      return;
    }
    socket.on("receive_noti_indicator", (data) => {
      //console.log("INCOMING NOTIFICATION >>> ", { data });
      setRecNoti(data);
    });
  }, [socket]);

  React.useEffect(() => {
    if (recNoti === "") {
      return;
    }
    if (recNoti.sender === loginUser) {
      return;
    }
    if (recNoti.chatroom === currentRoom) {
      return;
    }
    if (!recNoti.mentionedReceivers) {
      // event is for private chats
      if (recNoti.receiverId === loginUser) {
        play();
      }
      setNotiIndicator((prev) => {
        const updatedNoti = { ...prev };
        updatedNoti[recNoti.chatroom] = true;
        return updatedNoti;
      });
    } else {
      //event is for group chats
      let filteredMember = recNoti.mentionedReceivers.filter((member) => {
        return member.id === loginUser;
      });
      if (filteredMember.length !== 0) {
        play();
      }
    }
  }, [recNoti]);

  const getChannels = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/channels/all`
      );
      if (response.data) {
        setChannels(response.data.chats);
        setAllChannels(response.data.chats);

        // set chat room channel name
        setChannelsCache(response.data.chats);
      }
    } catch (error) {
      //("error.response.data::: ", error.response);
    }
  };

  React.useEffect(() => {
    console.groupEnd("GET CHANNEL CALLED");
    getChannels();
  }, []);

  return (
    <div style={{ width: "9vw" }}>
      {/* first channel */}
      {channels.length > 0
        ? channels.map((val, id) => {
            return (
              <div key={id}>
                <div
                  className={style.d_channel_title}
                  style={{
                    color: context === "dark" ? "white" : "black",
                  }}
                >
                  {" "}
                  - {val.channel}
                </div>
                <ul className="tw-mt-2">
                  {val.rooms.map((room, key) => {
                    return (
                      <li
                        className={`${
                          context === "dark"
                            ? style.d_channel_name_dark
                            : style.d_channel_name_light
                        } ${
                          roomId === room.uuid
                            ? context === "dark"
                              ? style.d_channel_name_dark_active
                              : style.d_channel_name_light_active
                            : ""
                        }`}
                        key={key}
                        onClick={() => {
                          navigate(`/coderoom/${room.uuid}`);
                          handleOnClick(room.room, room.uuid);
                        }}
                      >
                        <div
                          className={style.d_channel_name_text16}
                          style={{
                            color: context === "dark" ? "white" : "black",
                          }}
                        >
                          <span
                            className={style.d_channel_name_span}
                            style={{
                              color: context === "dark" ? "white" : "black",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            # &nbsp; {room.room}
                            {isNewNotif(room.uuid) && (
                              <div
                                style={{
                                  borderRadius: "50%",
                                  backgroundColor:
                                    context === "dark" ? "white" : "black",
                                  height: "12px",
                                  width: "12px",
                                  marginLeft: "0.5rem",
                                }}
                              ></div>
                            )}
                          </span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default CodeRoomChannelName;

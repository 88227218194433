import React, { useEffect } from "react";
import { useState } from "react";
import Cookies from "js-cookie";
import {jwtDecode}  from "jwt-decode";
import { useDispatch } from "react-redux";
import mixpanel from "mixpanel-browser";
import { toast } from "react-toastify";
import { logout } from "../../app/features/auth/authSlice";
import { useNavigate } from "react-router-dom";

function TokenExpiry() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    try {
      mixpanel.track("Logout Completed", {        
        "Logout Time": new Date().toISOString(),
      });
      mixpanel.reset();
    } catch (error) {
      console.log("Mixpanel Error : ", error);
    }
    Cookies.remove("user");
    Cookies.remove("token");
    localStorage.clear()
    
    toast.error("Session Timeout!");
    setTimeout(() => {
      navigate("/login");
    }, 800);
  };

  const checkTokenExpiration = () => {
    const token = Cookies.get("token");

    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      console.log("here token", token, decodedToken, decodedToken.exp < currentTime);

      if (decodedToken.exp < currentTime) {
        // Token expired, initiate logout
        handleLogout()
      }
    }
  };
  const token = Cookies.get("token");

  useEffect(() => {
    let intervalId;
    if (token && !intervalId) {
      intervalId = setInterval(checkTokenExpiration, 60000);
    }
    return () => clearInterval(intervalId);
  }, [token]);
  return <></>;
}

export default TokenExpiry;

import React from "react";
import "./ErrorGlitched.css";

const ErrorGlitched = () => {
  return (
    <div className="glitched-container">
      <div className="error">
        <div className="wrap">
          <div className="404">
            <pre>
              <code>
                <span className="green">&lt;!</span>
                <span>DOCTYPE html</span>
                <span className="green">&gt;</span>
                <br />
                <span className="orange">&lt;html&gt;</span>
                <br />
                <span className="orange">&lt;style&gt;</span>
                <br />
                {/* '* {' */}
                <span className="green" style={{ marginLeft: "150px" }}>
                  {" "}
                  everything
                </span>
                :<span className="blue">awesome</span>;
                <br />
                <span className="orange">&lt;/style&gt;</span>
                <br />
                <span className="orange">&lt;body&gt;</span>
                <br />
                <span style={{ color: "white", marginLeft: "100px" }}>
                  {" "}
                  ERROR 404!
                  <br /> FILE NOT FOUND!
                  <br />
                </span>
                <span className="comment">
                  &lt;!--The file you are looking for, <br /> is not where you
                  think it is.--&gt;
                </span>
                <span className="orange"></span>
              </code>
            </pre>
          </div>
          <br />
          <span className="info">
            <br />
            <span className="orange">&nbsp;&lt;/body&gt;</span>
            <br />
            <span className="orange">&lt;/html&gt;</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ErrorGlitched;

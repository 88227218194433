import { createSlice } from "@reduxjs/toolkit";

const itemSlice = createSlice({
  name: "items",
  initialState: {
    currentPlan: JSON.parse(localStorage.getItem("currentPlan")) || "",
    cards: [],
    histories: [],
  },
  reducers: {
    selectPlan: (state, action) => {
      state.currentPlan = action.payload;
      localStorage.setItem("currentPlan", JSON.stringify(action.payload));
    },
    addCard: (state, action) => {
      state.cards.push({
        cardName: action.payload.cardName,
        cardNumber: action.payload.cardNumber,
        expiryDate: action.payload.expiryDate,
        cvv: action.payload.cvv,
        nameOnCard: action.payload.nameOnCard,
        isCardDetailsAdded: true,
        error: null,
      });
    },

    clearCard: (state, action) => {
      state.cards.splice(action.payload.index, 1, {
        cardName: "",
        cardNumber: "",
        expiryDate: "",
        cvv: "",
        nameOnCard: "",
        isCardDetailsAdded: false,
        error: null,
      });
    },
    billingHistory: (state, action) => {
      state.histories.push({
        transactionId: action.payload.transactionId,
        transactionDate: action.payload.transactionDate,
        transactionAmount: action.payload.transactionAmount,
        transactionStatus: false,
        error: null,
      });
    },
  },
});

export const { selectPlan, addCard, clearCard, billingHistory } =
  itemSlice.actions;
export default itemSlice.reducer;

import React, { useContext, useRef } from "react";
import style from "./styles/CodeRoomChannel.module.css";
import user1 from "../../assets/coderoom-chat1.jpg";
import robot from "../../assets/robot02_90810.png";
import { AiOutlineSend } from "react-icons/ai";
import Spinner from "react-bootstrap/Spinner";
import MentionsInput from "../inputs/mentionsInput/MentionsInput";
import { useParams } from "react-router-dom";
import io from "socket.io-client";
import { ThemeContext } from "../../utils/ThemeContext";
import DragNDrop from "./DragNDrop";
import { IoIosClose } from "react-icons/io";
import MarkdownEditorWithCodeHighlighting from "./MarkdownEditorWithCodeHighlighting";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/coderoomdark.css";
import "./styles/coderoomlight.css";
import LoadingModal from "../modals/LoadingModal";
import {
  getChat,
  getNewMessages,
  setChatsInChannels,
} from "../../Service/chatService";
import { renderChatContent } from "../helpers/renderChatContent";
const customId = "prevent-duplicate";
import EmojiPicker from "./emojipicker/EmojiPicker";
import GifPickerReact from "./gif_picker/GifPicker";
import axios from "axios";
import { useSelector } from "react-redux";

import { ReactComponent as Smiley } from "../../assets/smiley.svg";
import { ReactComponent as Giphy } from "../../assets/gif.svg";
import { ReactComponent as ClipIcon } from "../../assets/clip-attach.svg";
import notification from "../../assets/notification.mp3";

import Cookies from "js-cookie";
import useSound from "use-sound";

// Socket.io initialization
const socket = io.connect(process.env.REACT_APP_BASE_URL, {
  maxHttpBufferSize: 1e8,
  pingTimeout: 60000,
  compress: true,
});

const CodeRoomChannel = ({ userId, userDetails, setIncomingMessage }) => {
  const chatRef = useRef(null);
  const roomId = useParams().id;
  const { context } = useContext(ThemeContext);

  const [initialLoading, setInitialLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isGptLoading, setIsGptLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [cachedChat, setCachedChat] = React.useState([]);
  // console.log("cachedChat::: ", cachedChat);
  const [play] = useSound(notification);

  const [rules, setRules] = React.useState([]);
  const [showImageChecking, setShowImageChecking] = React.useState(false);
  const [isDragging, setDragging] = React.useState(false);
  const [droppedImage, setDroppedImage] = React.useState([]);
  const { user } = useSelector((state) => state.auth);
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
  const [showGifPicker, setShowGifPicker] = React.useState(false);
  const [chatImage, setChatImage] = React.useState("");
  const [loginUser, setLoginUser] = React.useState(null);
  const [currentUser, setCurrentUser] = React.useState(null);
  const [isNormalUser, setIsNormalUser] = React.useState(true);
  const wrapperRef = React.useRef(null);
  const REACT_RULE_PAGE_ID = "140c432a-5fcc-4118-b136-9c379651c93a";
  const REACT_ANNOUNCEMENT = "ea0ca423-d24c-43c2-8623-0eecf8c86b27";
  const [newMsg, setNewMsg] = React.useState([]);

  // const handleChange = (event, newValue, newPlainTextValue, mentions) => {
  //   setMessage((prev) => prev + "" + newPlainTextValue);
  // };

  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    setMessage(newPlainTextValue);
  };

  function handleClickOutside(event) {
    if (wrapperRef) {
      if (wrapperRef.current) {
        !wrapperRef.current.contains(event.target)
          ? handleReactionBtn("BOTH")
          : null;
      }
    }
  }

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    getCurrentUser();
  }, [wrapperRef, roomId, currentUser]);

  React.useEffect(() => {
    let newMsgsDelivered = getNewMessages();
    setNewMsg((prev) => newMsgsDelivered);
    document.addEventListener("click", handleClickOutside, true);

    const getUser = Cookies.get("user")
      ? JSON.parse(Cookies.get("user"))
      : undefined;

    console.log("OUTSIDE USER >> ", getUser);
    if (getUser) {
      setCurrentUser((prev) => getUser);
    }

    getCurrentUser();
  }, []);

  React.useEffect(() => {
    let newMsgsDelivered = getNewMessages();

    if (cachedChat.length > newMsg.length) {
      let hasNewMsg = newMsg.find((_msg) => _msg.room != roomId);
      if (!hasNewMsg) {
        return;
      }
      // console.log("CHATS >> ", cachedChat);
      let chat = hasNewMsg.chats[hasNewMsg.chats.length - 1];

      // return;
      // let isUserMentioned = hasNewMsg.chats.find(
      let isUserMentioned = cachedChat.find(
        (_chat) =>
          _chat.chat.text.includes("@everyone") ||
          _chat.chat.text.includes("@" + currentUser.chatRoomUsername)
      );

      if (isUserMentioned) {
        play();
        return;
      }
    }

    setNewMsg((prev) => newMsgsDelivered);
  }, [cachedChat]);

  React.useEffect(() => {
    setCachedChat([]);
    setRules([]);
    if (roomId !== undefined) {
      getChat(roomId, setRules, setCachedChat, setInitialLoading);
    }

    let loginUser = localStorage.getItem("loginUser")
      ? JSON.parse(localStorage.getItem("loginUser"))
      : "7e72de6a-7d21-493a-b9c2-0bd4c57c0637";
    setLoginUser(loginUser);
    getCurrentUser();
  }, [roomId]);

  React.useEffect(() => {
    console.log("CACHED CHAT >> ", { cachedChat });
    if (roomId !== "140c432a-5fcc-4118-b136-9c379651c93a" && chatRef) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
    setChatsInChannels(cachedChat, roomId);
    setIncomingMessage(cachedChat);
  }, [cachedChat]);

  React.useEffect(() => {
    console.log("JOIN TEAM C ALLED");
    // Join room
    if (userId !== undefined) {
      socket.emit("join_chatroom", {
        userId: userId,
        room: roomId,
      });
    }
  }, [userId]);

  React.useEffect(() => {
    let botResponse = "";

    if (userId !== undefined) {
      const handleDisconnect = (reason) => {
        if (reason === "io server disconnect") {
          window.location.reload();
        }
      };

      const handleLoadingState = (isLoading) => {
        setLoading(isLoading.isLoading);
        setShowImageChecking(isLoading.isLoading);
      };
      const handleGptLoadingState = (isLoading) => {
        setLoading(isLoading.isLoading);
        setIsGptLoading(isLoading.isLoading);
      };

      const handleSimpleMessage = (message1) => {
        setCachedChat((prev) => [...prev, message1]);
      };

      const handleUserMessage = (message1) => {
        setCachedChat((prev) => [...prev, message1]);
      };

      const handleTenorMessage = (message1) => {
        setCachedChat((prev) => [...prev, message1]);
      };

      const handleBotMessage = (message1) => {
        //("message1::: ", message1);
        if (
          message1.chat.text ===
          "You have used your 5 GPT requests. Please try again tomorrow."
        ) {
          toast.error(
            "You have used your 5 GPT requests. Please try again tomorrow.",
            {
              position: "top-right",
              autoClose: 50000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: context === "dark" ? "dark" : "light",
              toastId: customId,
              preventDuplicate: customId,
              className: style.toastify,
            }
          );
        }
        setCachedChat((prev) => [...prev, message1]);
        // if (message1.chat.text) {
        //   botResponse += message1.chat.text + " ";
        // }
        // if (botResponse.trim() !== "") {
        //   setCachedChat((prev) => [
        //     ...prev.slice(0, -1),
        //     {
        //       ...message1,
        //       chat: { ...message1.chat, text: botResponse.trim() },
        //     },
        //   ]);
        // }
      };
      socket.on("disconnect", handleDisconnect);
      socket.on("loading_state", handleLoadingState);
      socket.on("gpt_loading_state", handleGptLoadingState);
      socket.on("simple_message", handleSimpleMessage);
      socket.on("user_message", handleUserMessage);
      socket.on("bot_message", handleBotMessage);
      socket.on("tenor_message", handleTenorMessage);

      // Clean up WebSocket connection on component unmount
      return () => {
        socket.off("loading_state");
        socket.off("simple_message");
        socket.off("user_message");
        socket.off("bot_message");
        socket.off("tenor_message");
      };
    }
  }, [socket, userId]);

  React.useEffect(() => {
    if (chatImage === "") {
      return;
    }
    if (chatImage !== "") {
      let loadImages =
        chatImage.length > 0
          ? chatImage.map((file) => {
              return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = function (event) {
                  const image = event.target.result;
                  resolve(image);
                };

                reader.onerror = function (event) {
                  reject(event.error);
                };

                reader.readAsDataURL(file);
              });
            })
          : null;

      Promise.all(loadImages)
        .then(async (images) => {
          await socket.emit("send_message", {
            type: "text",
            text: message,
            images: images,
            room: roomId,
            userId: userId,
          });
          setMessage("");
          setDroppedImage([]);
        })
        .catch((error) => {
          console.error("Error loading images:", error);
        });
    } else {
      //("");
    }
  }, [chatImage]);

  const getCurrentUser = () => {
    console.log("GET CURRENT USER >>", currentUser);
    if (currentUser) {
      let { paidPlan } = currentUser;
      if (REACT_RULE_PAGE_ID == roomId || REACT_ANNOUNCEMENT == roomId) {
        setIsNormalUser((prev) =>
          paidPlan == "Admin" || "admin" ? false : true
        );
      } else {
        setIsNormalUser((prev) => true);
      }
    } else {
      setIsNormalUser((prev) => false);
    }
  };
  const handleSendMessage = async () => {
    const loadImages = droppedImage.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function (event) {
          const image = event.target.result;
          resolve(image);
        };

        reader.onerror = function (event) {
          reject(event.error);
        };

        reader.readAsDataURL(file);
      });
    });

    if (message == "") {
      return null;
    }
    Promise.all(loadImages)
      .then(async (images) => {
        await socket.emit("send_message", {
          type: "text",
          text: message,
          images: images,
          room: roomId,
          userId: userId,
        });

        setMessage("");
        setDroppedImage([]);
      })
      .catch((error) => {
        console.error("Error loading images:", error);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleDragEnter = () => {
    setDragging(true);
  };

  const handleImageRemove = (index) => {
    const updatedImages = [...droppedImage];
    updatedImages.splice(index, 1);
    setDroppedImage(updatedImages);
  };

  const gptResponseIdentifier = (val) => {
    console.log("RESPONSE IDENTIFIER >>> ", val);
    const codeStartIndex = val.indexOf("```");
    const codeEndIndex = val.lastIndexOf("```");

    if (codeStartIndex !== -1 && codeEndIndex !== -1) {
      const code = val.substring(codeStartIndex + 3, codeEndIndex);
      const explanation =
        val.substring(0, codeStartIndex) + val.substring(codeEndIndex + 3);

      return (
        <>
          <MarkdownEditorWithCodeHighlighting value={code} />
          <p
            style={{
              color: context === "dark" ? "white" : "black",
            }}
          >
            {explanation}
          </p>
        </>
      );
    } else {
      return (
        <p
          style={{
            color: context === "dark" ? "white" : "black",
          }}
        >
          {val}
        </p>
      );
    }
  };

  const handleReactionBtn = (btnType) => {
    switch (btnType) {
      case "EMOJI":
        setShowEmojiPicker(!showEmojiPicker);
        showGifPicker ? setShowGifPicker(!showGifPicker) : "";
        break;
      case "GIF":
        setShowGifPicker(!showGifPicker);
        showEmojiPicker ? setShowEmojiPicker(!showEmojiPicker) : "";
        break;
      case "BOTH":
        setShowGifPicker(false);
        setShowEmojiPicker(false);
        break;
    }
  };

  const handleSelectChatImage = async () => {
    setShowGifPicker(false);
    setShowEmojiPicker(false);

    let input = document.createElement("input");
    let files = [];
    input.type = "file";
    input.onchange = (_) => {
      // you can use this method to get file and perform respective operations
      files = Array.from(input.files);

      setChatImage((prev) => files);
    };
    input.click();
  };

  const ShowMessage = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
          ref={wrapperRef}
          style={{
            width: "fit-content",
          }}
        >
          <EmojiPicker
            socket={socket}
            message={message}
            setMessage={setMessage}
            context={context}
            setShowGifPicker={(type) => handleReactionBtn(type)}
            showEmojiPicker={showEmojiPicker}
            userId={userId}
          />

          <GifPickerReact
            socket={socket}
            message={message}
            setShowGifPicker={(type) => handleReactionBtn(type)}
            context={context}
            showGifPicker={showGifPicker}
            setMessage={(val) => setMessage(val)}
            userId={userId}
          />
        </div>

        <div className={style.rowWrapper}>
          <div className={style.message_wrapper}>
            {droppedImage.length > 0 ? (
              <div className={style.imageWrapper}>
                {droppedImage.map((file, index) => (
                  <div key={index} className={style.imageWrapperInner}>
                    <img
                      src={URL.createObjectURL(file)}
                      alt={`Dropped ${index}`}
                    />
                    <div
                      className={style.circle}
                      onClick={() => handleImageRemove(index)}
                    >
                      <span>
                        <IoIosClose />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}

            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  flexDirection: "row",
                  alignItems: "end",
                  width: "100%",
                }}
              >
                <button
                  className={`${style.smileBtn} ${
                    context === "dark" ? style.darkSmileBtn : ""
                  }`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "38px",
                    width: "34px",
                  }}
                  onClick={() => handleReactionBtn("GIF")}
                >
                  <Giphy />
                </button>
                <button
                  className={`${style.smileBtn} ${
                    context === "dark" ? style.darkSmileBtn : ""
                  }`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "38px",
                    width: "34px",
                  }}
                  onClick={handleSelectChatImage}
                >
                  <ClipIcon
                    height="38px"
                    width="34px"
                    style={{ margin: "0px" }}
                  />
                </button>

                {/* <div style={{ flexGrow: 1 }}> */}
                {/* <MentionsInput
                  style={{ flexGrow: 1 }}
                  message={message}
                  className={style.mentionBox}
                  handleChange={handleChange}
                  handleKeyDown={handleKeyDown}
                /> */}

                <MentionsInput
                  style={{ flexGrow: 1 }}
                  message={message}
                  handleChange={handleChange}
                  // handleKeyDown={handleKeyDown}
                />

                {/* </div> */}
              </div>

              <div className={style.reactionBtn}>
                <button
                  className={`${style.smileBtn} ${
                    context === "dark" ? style.darkSmileBtn : ""
                  }`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0 12px",
                    height: "38px",
                    width: "34px",
                  }}
                  onClick={() => handleReactionBtn("EMOJI")}
                >
                  <Smiley />
                </button>

                <button
                  onClick={() => {
                    handleSendMessage();
                  }}
                  style={{
                    backgroundColor: context === "dark" ? "#f1f1f1" : "#f1f1f1",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #f1f1f1",
                    padding: "8px",
                    margin: "2px",
                    // padding: "10px",
                  }}
                >
                  {loading ? (
                    <Spinner animation="grow" size="sm" />
                  ) : (
                    <AiOutlineSend
                      style={{
                        fontSize: "larger",
                      }}
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          position: "absolute",
          left: 0,
          height: "100%",
          zIndex: isDragging ? 4 : 2,
        }}
      >
        <DragNDrop
          isDragging={isDragging}
          setDragging={setDragging}
          setDroppedImage={setDroppedImage}
        />
      </div>
      <div
        className={style.d_channel}
        onDragEnter={handleDragEnter}
        // onDragLeave={handleDragLeave}
      >
        <div ref={chatRef} className={style.history}>
          {rules.length > 0 ? (
            <div className={style.rules}>
              <div>
                <h4
                  style={{
                    color: context === "dark" ? "white" : "black",
                  }}
                >
                  Welcome to the DeepTuring CodeRoom{" "}
                  <span className={style.wave}>👋</span>
                </h4>
              </div>
              <p
                className={style.d_channel_user_div_p2}
                style={{
                  color: context === "dark" ? "white" : "black",
                }}
              >
                CodeRoom is a online community that allows both developers from
                all skill levels and non deverlopers who are interested in
                programming and tech to give or receive real time assistance to
                each other, collaborating on projects, making connections and
                friendships or simply just a place to hangout and chat. No
                matter your skill level, you are welcome in this community - all
                we ask is you follow the rules:
              </p>
              {rules.map((val, id) => {
                return (
                  <p
                    className={style.d_channel_user_div_p2}
                    style={{
                      color: context === "dark" ? "white" : "black",
                    }}
                  >
                    {id + 1} - {val.rule}
                  </p>
                );
              })}
            </div>
          ) : null}
          {cachedChat.length !== 0 && rules.length === 0
            ? cachedChat.length > 0
              ? cachedChat.map((val, id) => {
                  return (
                    val.room === roomId && (
                      <div>
                        {val.chat.type === "text" ||
                        val.chat.type === "image" ? (
                          <div className={style.d_channel_inner_div}>
                            <div>
                              <img
                                src={user1}
                                // src={user && user.profilePic ? user.profilePic : person}
                                alt="user"
                                className={style.d_channel_img}
                              />
                            </div>
                            <div style={{ width: "90%" }}>
                              <div className={style.d_channel_user_div}>
                                <div className={style.d_channel_user_div_p}>
                                  {val.chat.user
                                    ? val.chat.user.chatRoomUsername
                                    : ""}
                                </div>
                                <div>{renderChatContent(val, context)}</div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {val.chat.text ===
                          "Oops! It looks like your message includes inappropriate language."}
                        {val.chat.type === "bot" &&
                        val.chat.text !==
                          "You have used your 5 GPT requests. Please try again tomorrow." ? (
                          <div className={style.robot_wrapper}>
                            <div>
                              <img
                                src={robot}
                                alt="user"
                                className={style.d_channel_img}
                              />
                            </div>
                            <div className={style.robot_wrapper_div}>
                              <h6
                                style={{
                                  color: context === "dark" ? "white" : "black",
                                }}
                              >
                                Codebot
                              </h6>

                              {val && val.chat && val.chat.text ? (
                                val.chat.text ===
                                  "Invalid question type. Only coding questions are allowed." ||
                                val.chat.text ===
                                  "Invalid question type. Only coding/programming questions are allowed." ||
                                val.chat.text.includes(
                                  "Invalid question type"
                                ) ? (
                                  <div className={style.dangerAlert}>
                                    {val.chat.text}
                                  </div>
                                ) : (
                                  gptResponseIdentifier(val.chat.text)
                                )
                              ) : null}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )
                  );
                })
              : null
            : null}
        </div>

        {/* <div>{isNormalUser ? <ShowMessage /> : null}</div> */}

        {isNormalUser ? (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div
              ref={wrapperRef}
              style={{
                width: "fit-content",
              }}
            >
              <EmojiPicker
                socket={socket}
                message={message}
                setMessage={setMessage}
                context={context}
                setShowGifPicker={(type) => handleReactionBtn(type)}
                showEmojiPicker={showEmojiPicker}
                userId={userId}
              />

              <GifPickerReact
                socket={socket}
                message={message}
                setShowGifPicker={(type) => handleReactionBtn(type)}
                context={context}
                showGifPicker={showGifPicker}
                setMessage={(val) => setMessage(val)}
                userId={userId}
              />
            </div>

            <div className={style.rowWrapper}>
              <div className={style.message_wrapper}>
                {droppedImage.length > 0 ? (
                  <div className={style.imageWrapper}>
                    {droppedImage.map((file, index) => (
                      <div key={index} className={style.imageWrapperInner}>
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Dropped ${index}`}
                        />
                        <div
                          className={style.circle}
                          onClick={() => handleImageRemove(index)}
                        >
                          <span>
                            <IoIosClose />
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}

                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      flexDirection: "row",
                      alignItems: "end",
                      width: "100%",
                    }}
                  >
                    <button
                      className={`${style.smileBtn} ${
                        context === "dark" ? style.darkSmileBtn : ""
                      }`}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "38px",
                        width: "34px",
                      }}
                      onClick={() => handleReactionBtn("GIF")}
                    >
                      <Giphy />
                    </button>
                    <button
                      className={`${style.smileBtn} ${
                        context === "dark" ? style.darkSmileBtn : ""
                      }`}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "38px",
                        width: "34px",
                      }}
                      onClick={handleSelectChatImage}
                    >
                      <ClipIcon
                        height="38px"
                        width="34px"
                        style={{ margin: "0px" }}
                      />
                    </button>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "12vw",
                        flexGrow: 1,
                        flex: 1,
                      }}
                    >
                      <MentionsInput
                        style={{
                          flex: 1,

                          flexGrow: 1,
                        }}
                        className={style.mentionBox}
                        message={message}
                        handleChange={handleChange}
                        handleKeyDown={handleKeyDown}
                      />
                    </div>

                    {/* </div> */}
                  </div>

                  <div className={style.reactionBtn}>
                    <button
                      className={`${style.smileBtn} ${
                        context === "dark" ? style.darkSmileBtn : ""
                      }`}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "end",
                        gap: "0 12px",
                        height: "38px",
                        width: "34px",
                      }}
                      onClick={() => handleReactionBtn("EMOJI")}
                    >
                      <Smiley />
                    </button>

                    <button
                      onClick={() => {
                        handleSendMessage();
                      }}
                      style={{
                       backgroundColor: context === "dark" ? "#f1f1f1" : "#f1f1f1",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #f1f1f1",
                    padding: "4px",
                    margin: "2px",
                      }}
                    >
                      {loading ? (
                        <Spinner animation="grow" size="sm" />
                      ) : (
                        <AiOutlineSend
                          style={{
                            fontSize: "larger",
                            color:"black"
                          }}
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <LoadingModal
        showImageChecking={showImageChecking}
        isGptLoading={isGptLoading}
        initialLoading={initialLoading}
      />
      <ToastContainer />
    </div>
  );
};

export default CodeRoomChannel;

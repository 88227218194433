import React, { useEffect, useState } from "react";
import Skeleton from "./Skeleton";
import moment from "moment/moment";
import { getTeamMessages } from "../../Service/teamServices";

const ChatMessages = ({
  clientMessages,
  setClientMessages,
  loginUser,
  memberNames,
  groupChat,
  room,
}) => {
  const [isChatLoading, setIsChatLoading] = useState(
    room === "" ? false : true
  );

  const handleGetTeamChats = async (id) => {
    setIsChatLoading(true);
    try {
      let res = await getTeamMessages(id);
      // //(res);
      setClientMessages(() => {
        return res.data.chats.map((chat) => {
          return {
            createdAt: chat.createdAt,
            room: chat.room,
            text: chat.text,
            userId: chat.userId,
            type: chat.type,
          };
        });
      });

      setIsChatLoading(false);
    } catch (error) {
      //(error);
      setIsChatLoading(false);
    }
  };

  useEffect(() => {
    if (room === "") {
      return;
    }
    handleGetTeamChats(room);
  }, []);

  useEffect(() => {
    if (room === "") {
      return;
    }
    handleGetTeamChats(room);
  }, [room]);

  return (
    <div
      className={`tw-flex tw-basis-3/4 tw-flex-col-reverse tw-px-6 tw-overflow-scroll tw-overflow-x-hidden tw-h-full tw-py-3`}
    >
      {isChatLoading ? (
        Array.from(Array(10)).map((_, i) => {
          return <Skeleton key={i} />;
        })
      ) : (
        <>
          {clientMessages.length === 0 && (
            <div className="tw-h-full tw-w-full tw-text-center tw-m-4">
              No Messages
            </div>
          )}
          {clientMessages.length !== 0 &&
            clientMessages.map((clientMessage, index) => {
              return (
                <div className="tw-w-full tw-flex tw-flex-col" key={index}>
                  {index === clientMessages.length - 1 ? (
                    <DisplayDay date={clientMessage.createdAt} />
                  ) : (
                    !(
                      moment(clientMessage.createdAt.toString()).format(
                        "LL"
                      ) ===
                      moment(
                        clientMessages[
                          index === clientMessages.length - 1
                            ? index
                            : index + 1
                        ].createdAt.toString()
                      ).format("LL")
                    ) && <DisplayDay date={clientMessage.createdAt} />
                  )}

                  {clientMessage.userId !== loginUser ? (
                    <>
                      {groupChat && (
                        <div className="tw-mt-3 tw--mb-3 tw-text-xs tw-ml-2 tw-text-blue-900 tw-flex">
                          <div>
                            {memberNames().find((item) => {
                              return item.id == clientMessage.userId;
                            })
                              ? memberNames().find((item) => {
                                  return item.id == clientMessage.userId;
                                }).name
                              : "Previous Member"}
                          </div>
                        </div>
                      )}

                      <MessageContent
                        date={clientMessage.createdAt}
                        message={clientMessage.text}
                        cssClass="tw-bg-purple-200 tw-text-gray-900 tw-rounded-bl-none tw-self-start"
                        dateCss="tw-text-left tw-ml-2"
                        type={clientMessage.type}
                      />
                    </>
                  ) : (
                    <>
                      <MessageContent
                        date={clientMessage.createdAt}
                        message={clientMessage.text}
                        cssClass="tw-bg-gray-900 tw-text-white  tw-rounded-br-none tw-self-end"
                        dateCss="tw-text-right"
                        type={clientMessage.type}
                      />
                    </>
                  )}
                </div>
              );
            })}
        </>
      )}
    </div>
  );
};
function DisplayDay({ date }) {
  return (
    <div className="tw-text-center tw-self-center tw-text-xs tw-bg-gray-300 tw-text-gray-800 tw-font-semibold tw-rounded-sm tw-px-4 tw-py-2 tw-mt-3">
      {moment(date.toString()).format("LL")}
    </div>
  );
}
function MessageContent({ message, date, cssClass, dateCss, type }) {
  // //("type",type)
  return (
    <>
      <div
        style={{ wordBreak: "break-word" }}
        className={`${cssClass} tw-mt-3 tw-min-h-fit md:tw-max-w-[45%] tw-max-w-[65%] tw-py-4 tw-px-5 tw-rounded-3xl md:tw-text-sm tw-text-xs tw-break-words`}
      >
        {type === "url" ? (
          <img className="tw-max-w-full" src={message}></img>
        ) : (
          <div>{message}</div>
        )}
      </div>
      <div style={{ fontSize: "0.6rem" }} className={`${dateCss} tw-mt-1`}>
        {moment(date.toString()).format("hh:mm A")}
      </div>
    </>
  );
}

export default ChatMessages;

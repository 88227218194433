import React from "react";
import Comingsoon from "../component/ui/comingSoon";

const Coming = () => {
  return (
    <>
      <div>
        <Comingsoon />
      </div>
    </>
  );
};

export default Coming;

import React from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { ReactComponent as Smiley } from "../../../assets/smiley.svg";
import styles from "./emojiPicker.module.css";
import EmojiPicker, { EmojiStyle } from "emoji-picker-react";
import { useParams } from "react-router-dom";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const EmojiPickerReact = ({
  ref,
  setMessage,
  context,
  showEmojiPicker,
  userId = { userId },
}) => {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleEmojiSelect = (emoji) => {
    if (!emoji || emoji.imageUrl === "") {
      return null;
    }

    setMessage((prev) => prev + "" + emoji.emoji);
  };

  return (
    <div className={styles.emojiPickerComponent}>
      {showEmojiPicker && (
        <div className={styles.emojiPicker}>
          <EmojiPicker
            ref={ref}
            onEmojiClick={handleEmojiSelect}
            theme={context === "dark" ? "dark" : "light"}
            width={windowDimensions.width < 1024 ? "92vw" : "680px"}
            emojiStyle={EmojiStyle.NATIVE}
          />
        </div>
      )}
    </div>
  );
};

export default EmojiPickerReact;

import React from "react";
import Picker from "@progresso/react-giphy-picker-https";

import styles from "../emojipicker/emojiPicker.module.css";
import { useParams } from "react-router-dom";

import { Grid } from "@giphy/react-components";
import { GiphyFetch } from "@giphy/js-fetch-api";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const GifPickerReact = ({
  socket,
  setShowGifPicker,
  showGifPicker,
  userId,
  setMessage,
}) => {
  const roomId = useParams().id;

  const gf = new GiphyFetch(process.env.REACT_APP_GIPHY_KEY);
  const [searchTerm, setSearchTerm] = React.useState("funny");
  const fetchGifs = (offset) => gf.search(searchTerm, { offset, limit: 10 });

  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleEmojiSelect = async (gif) => {
    if (!gif || !gif.images) {
      return null;
    }

    let gifurl = gif.images.original.url;
    gifurl = gifurl.split("?")[0];

    socket.emit("send_message", {
      type: "text",
      text: gifurl,
      room: roomId,
      userId: userId,
    });

    setShowGifPicker("GIF");
    //("END ");
  };

  const handleSearchText = (event) => {
    event.preventDefault();
    if (!event.target) {
      return null;
    }

    let { value } = event.target;
    setSearchTerm((prev) => value);
  };

  React.useEffect(() => {});
  return (
    <div className={styles.emojiPickerComponent}>
      {showGifPicker && (
        <>
          <div className={styles.emojiPicker}>
            <div className={styles.inputHolder}>
              <input
                // style={{ width: "568px" }}
                className={styles.emojiSearch}
                type="text"
                placeholder="Search Here..."
                onChange={(e) => handleSearchText(e)}
                autoFocus
              />
            </div>
            <Grid
              className={styles.gridDisplay}
              width={windowDimensions.width < 1024 ? 372 : 680}
              height={580}
              columns={windowDimensions.width < 1024 ? 3 : 6}
              gutter={6}
              fetchGifs={fetchGifs}
              hideProgressBar={true}
              key={searchTerm}
              onGifClick={(gif, e) => {
                e.preventDefault();
                handleEmojiSelect(gif);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default GifPickerReact;

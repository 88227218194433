import React, { useRef, useState } from "react";
import { IoMdSend } from "react-icons/io";
import { BsEmojiHeartEyesFill } from "react-icons/bs";
import { FiPaperclip } from "react-icons/fi";
import emojiArr from "./emoji";
import S3FileUpload from "react-s3";
import s3 from "../../aws-s3";
import { useTeam } from "../../context/TeamProvider";

const ChatFooter = ({
  message,
  setMessage,
  memberNames,
  setMessageList,
  loginUser,
  room,
  socket,
  groupChat,
  memberCount,
  receiver,
}) => {
  const [hideEmoji, setHideEmoji] = useState(true);
  const [bitmapImage, setBitmapImage] = useState(null);
  const fileRef = useRef();
  const { room: mainRoom } = useTeam();

  const handleTypingSignal = (typing) => {
    let name = loginUser;
    if (groupChat) {
      name = memberNames().find((item) => {
        return item.id == loginUser;
      }).name;
    }
    socket.emit("send_typing_indicator", {
      name,
      id: loginUser,
      room,
      typing: typing,
    });
  };

  const onInputChange = (event) => {
    setMessage(event.target.value);
    if (event.target.value.length === 1) {
      handleTypingSignal(true);
    }
  };

  const handleSendMessage = (event) => {
    event.preventDefault();

    let messageData = {
      createdAt: new Date(),
      room: room,
      text: message,
      userId: loginUser,
      type: "text",
    };

    socket.emit("send_message", messageData);
    if (groupChat) {
      let msg = message;
      let names = memberNames();
      let mentionedReceiver = [];
      let flag = false;
      if (msg.toLowerCase().includes("@everyone")) {
        flag = true;
        mentionedReceiver = names;
      }

      for (let i = 0; i < names.length; i++) {
        if (flag && i === 0) {
          break;
        }
        let memberName = names[i].name.toLowerCase();
        if (
          msg.toLowerCase().includes(`@${memberName}`) ||
          msg.toLowerCase().includes(`@${memberName.split("")[0]}`)
        ) {
          flag = true;
          mentionedReceiver.push(names[i]);
        }
      }

      if (flag) {
        socket.emit("send_noti_indicator", {
          chatroom: room,
          mainRoom: mainRoom,
          sender: loginUser,
          mentionedReceivers: mentionedReceiver,
        });
      }
    } else {
      socket.emit("send_noti_indicator", {
        chatroom: room,
        mainRoom: mainRoom,
        sender: loginUser,
        receiverId: receiver,
      });
    }
    setMessageList((prev) => {
      return [messageData, ...prev];
    });
    handleTypingSignal(false);
    setMessage("");
  };

  const handleFileUpload = (event) => {
    event.preventDefault();
    //("file", fileRef.current.files[0]);
    // let logo = event.target.files[0]
    // handleFileUploadDisplay(logo)

    try {
      S3FileUpload.uploadFile(fileRef.current.files[0], s3)
        .then((data) => {
          let messageData = {
            createdAt: new Date(),
            room: room,
            text: data.location,
            userId: loginUser,
            type: "url",
          };

          socket.emit("send_message", messageData);
          if (groupChat) {
          } else {
            socket.emit("send_noti_indicator", {
              chatroom: room,
              mainRoom: mainRoom,
              sender: loginUser,
            });
          }
          setMessageList((prev) => {
            return [messageData, ...prev];
          });

          setMessage("");
          //("fileUpload", data.location);
        })
        .catch((err) => {
          //("Err File upload to S3", err);
        });
    } catch (error) {
      //("File Upload Error", error);
    }
  };

  const handleFileUploadDisplay = (file) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const uploadFile = reader.result;
      setBitmapImage(uploadFile); //directly into the src of an image
    });
    reader.readAsDataURL(file);
  };

  return (
    <div className="tw-mb-4 tw-basis-24 tw-flex tw-items-center tw-justify-center tw-px-6">
      <form
        style={{ border: "1px solid #888888" }}
        className="tw-relative tw-w-full tw-h-12 tw-flex tw-items-center tw-justify-between tw-px-4 tw-rounded-full tw-border-2 tw-border-slate-400"
        onSubmit={handleSendMessage}
      >
        <div
          className={`tw-absolute tw-max-h-72 tw-overflow-scroll tw-overflow-x-hidden tw-bottom-14 ${
            hideEmoji ? "tw-hidden" : "tw-grid"
          } tw-grid-rows-auto tw-grid-cols-6 tw-p-1 tw-rounded-2xl tw-rounded-bl-none tw-bg-slate-50 tw-list-none`}
        >
          {emojiArr.map((emoji, index) => (
            <li
              className="tw-text-3xl tw-cursor-pointer"
              key={index}
              onClick={() => setMessage(message.concat(emoji))}
            >
              {emoji}
            </li>
          ))}
        </div>

        <BsEmojiHeartEyesFill
          size={28}
          className={`${
            memberCount === 0
              ? "tw-pointer-events-none tw-text-gray-300"
              : "tw-cursor-pointer tw-text-[#1a2238]"
          } tw-text-slate-500`}
          onClick={() => setHideEmoji(!hideEmoji)}
        />

        <input
          placeholder="Message"
          value={message}
          className={`${
            memberCount === 0
              ? "tw-pointer-events-none tw-text-gray-300"
              : "tw-cursor-pointer tw-text-[#1a2238]"
          } tw-w-full tw-h-full tw-border-0 tw-bg-inherit focus:tw-border-0 focus:tw-outline-none tw-px-5`}
          onChange={onInputChange}
          onFocus={() => {
            message.length >= 1 && handleTypingSignal(true);
          }}
          onBlur={() => {
            handleTypingSignal(false);
          }}
        />

        <FiPaperclip
          size={28}
          className={`${
            memberCount === 0
              ? "tw-pointer-events-none tw-text-gray-300"
              : "tw-cursor-pointer tw-text-[#1a2238]"
          } tw-text-slate-500 tw-mx-3 tw-text-[#1a2238]"
        } `}
          onClick={() => fileRef.current.click()}
        />
        <input
          type="file"
          onChange={handleFileUpload}
          hidden
          ref={fileRef}
        ></input>

        <IoMdSend
          size={28}
          className={`${
            memberCount === 0
              ? "tw-pointer-events-none tw-text-gray-300"
              : "tw-cursor-pointer tw-text-[#1a2238]"
          } `}
          onClick={handleSendMessage}
        />
      </form>
    </div>
  );
};

export default ChatFooter;

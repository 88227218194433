import React, { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosCall } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FiVideo } from "react-icons/fi";
import Skeletion from "./Skeleton";
import { statusDetail } from "../../utils/functions";
import { useTeam } from "../../context/TeamProvider";
const imgplaceholdder =
  "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg";

const ChatHeader = (props) => {
  const {
    loading,
    handleBackArrow,
    receiverProfile,
    groupChat,
    typingIndicator,
    multipleUSers,
  } = props;

  let navigate = useNavigate();
  const { socket, loginUser, room } = useTeam();
  const [status, setStatus] = useState(
    localStorage.getItem("status")
      ? JSON.parse(localStorage.getItem("status"))
      : {}
  );
  const [receivedStatus, setReceivedStatus] = useState("");

  useEffect(() => {
    if (socket === null) {
      return;
    }
    socket.on("receive_change_status", (data) => {
      setReceivedStatus(data);
    });
  }, [socket]);

  useEffect(() => {
    if (receivedStatus === "") {
      return;
    }
    if (receivedStatus.id === loginUser) {
      return;
    }
    socket.emit("joined_member_status", { room: room });
    if (receivedStatus.status !== status[receivedStatus.id]) {
      setStatus((prev) => {
        const updatedStatus = { ...prev };
        updatedStatus[receivedStatus.id] = receivedStatus.status;
        return updatedStatus;
      });
    }
  }, [receivedStatus]);

  return (
    <div className="tw-h-20 tw-basis-20 tw-py-4 tw-flex tw-justify-between tw-items-center tw-rounded-tl-lg tw-px-6 tw-shadow-lg">
      <div className="tw-flex tw-items-center">
        <IoIosArrowBack
          size={25}
          className="tw-text-slate-700 tw-cursor-pointer"
          onClick={handleBackArrow}
        />

        <div className="tw-basis-3/4 tw-ml-4">
          {loading === true ? (
            <Skeletion nullMargin />
          ) : (
            <div className="tw-flex tw-items-center tw-space-x-4">
              <div className="tw-relative">
                <img
                  className="tw-rounded-full tw-w-10 tw-h-10"
                  data-testid="flowbite-avatar-img"
                  src={true ? imgplaceholdder : receiverProfile.profilePic}
                />
                {!groupChat && (
                  <span
                    style={{
                      backgroundColor:
                        status[receiverProfile.id] === "1"
                          ? statusDetail[0].color
                          : status[receiverProfile.id] === "3"
                          ? statusDetail[2].color
                          : statusDetail[1].color,
                    }}
                    className="tw-absolute tw-h-3.5 tw-w-3.5 tw-rounded-full tw-border-2 tw-border-white dark:tw-border-gray-800 tw--bottom-1 tw--right-1"
                  ></span>
                )}
              </div>
              <div className="tw-space-y-1 tw-font-medium tw-w-40 tw-h-10">
                <div className="tw-truncate">
                  {receiverProfile && groupChat
                    ? receiverProfile.name
                    : receiverProfile.firstName + "" + receiverProfile.lastName}
                </div>
                {!typingIndicator.typing ? (
                  <div className="tw-text-sm tw-text-gray-500 tw-truncate">
                    {groupChat ? "Members: " : "Active Now"}
                    {groupChat &&
                      receiverProfile.members &&
                      receiverProfile.members.length}
                  </div>
                ) : (
                  <div className="tw-text-sm tw-text-green-500 tw-truncate">
                    {groupChat
                      ? multipleUSers.length === 1
                        ? `${typingIndicator.name} is typing ...`
                        : `Multiple users are typing`
                      : `typing ...`}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="tw-flex">
        <IoIosCall size={25} className="tw-mx-3 tw-cursor-pointer" />
        <FiVideo
          size={25}
          className="tw-cursor-pointer"
          onClick={() => {
            navigate("/dashboard/meet");
          }}
        />
      </div>
    </div>
  );
};

export default ChatHeader;

import React, { useState } from "react";
import "./Login.css";
import logo from "./img/logo.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { loginUser, signUpUser } from "../app/features/auth/authService";
import axios from "axios";
import { editIsSuccess } from "../app/features/auth/authSlice";
import {
  forgotPassword,
  resetPassword,
} from "../Service/forgotPasswordService";

const ForgotPassword = () => {
  const container = React.useRef();

  // React Router
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // State
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isValidInput, setIsValidInput] = useState(false);
  const [newTokenInput, setNewTokenInput] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [signUpObj, setSignUpObj] = React.useState({
    firstName: "", //"izeres",
    lastName: "", //"fais",
    email: "", //"izerea12f@gmail.com",
    password: "", //"ann1234",
    countryCode: "", //250,
    dateOfBirth: "", //"2000-03-13",
  });
  const [signInObj, setSignInObj] = React.useState({
    email: "", // "izerea12f@gmail.com",
    password: "", // "ann1234",
  });
  const [ip, setIP] = React.useState("");

  // Redux state
  const dispatch = useDispatch();
  const { loading, isError, message, isSuccess, user } = useSelector(
    (state) => state.auth
  );

  // Redux useEffect
  React.useEffect(() => {
    if (isError) {
      toast.error(message);
    }
  }, [isError]);

  React.useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/dashboard");
      }, 100);
    }
  }, [user]);

  // Onload

  React.useEffect(() => {
    setTimeout(() => {
      if (container) {
        if (queryParams.get("redirect") === "signup")
          container.current.classList.add("sign-up");
        else container.current.classList.add("sign-in");
      }
    }, 200);
  }, []);
  // Get the User GEO Location JSON
  React.useEffect(() => {
    (async () => {
      try {
        const res = await axios.get("https://geolocation-db.com/json/");
        setIP(res.data.IPv4);
        setSignUpObj((pre) => ({ ...pre, countryCode: res.data.country_code }));
      } catch (error) {}
    })();
  }, []);

  const toggle = () => {
    container.current.classList.toggle("sign-in");
    container.current.classList.toggle("sign-up");
  };

  const handleChangeSignUp = (e) => {
    setSignUpObj((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };
  const handleChangeSignIn = (e) => {
    setSignInObj((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const signUpFunc = (e) => {
    e.preventDefault();
    dispatch(signUpUser(signUpObj));
  };

  const signInFunc = (e) => {
    e.preventDefault();
    dispatch(loginUser({ ...signInObj, ip_address: ip }));
  };

  const handleForgotPassword = async (e) => {
    // e.preventDefault();
    setIsLoading(true);
    console.log("IS VALID >> ", { isValidInput });
    if (!email || email == "") {
      toast.error("Password not Equal!");
      setIsLoading(false);
    }
    try {
      const res = await forgotPassword(email);
      setEmailSent(true);
      setIsLoading(false);

      toast.success(
        "Please check you email, \n\t Security token will expire after 10 Minutes!"
      );
    } catch (err) {
      console.log(err);
      setIsLoading(false);

      toast.error("Something went wrong! \n please check your input");
      setEmailSent(false);
    }
  };

  const handleResetPassword = async (e) => {
    // e.preventDefault();
    setIsLoading(true);
    if (!newTokenInput) {
      toast.success("Password not Equal!");
      setIsLoading(false);
    }
    try {
      const res = await resetPassword(newPassword, newTokenInput);
      // setEmailSent(true);
      toast.success("Password Reset Successfully!");
      setIsLoading(false);
      navigate("/login");
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const comparePassword = (e) => {
    e.preventDefault();
    let isEqual = e.target.value.toString() == newPassword.toString();

    console.log("new pass ", newPassword == e.target.value);
    if (!isEqual) {
      setIsValidInput((prev) => false);
    } else {
      setIsValidInput((prev) => true);
    }
  };
  return (
    <div className="" style={{ minHeight: "100vh", overflow: "hidden" }}>
      <div id="container" className="container1 mx-0 my-0" ref={container}>
        {/* <!-- FORM SECTION --> */}
        <div className="row row1">
          {/* <!-- Forgot Password --> */}
          <div className="col col1 align-items-center flex-col sign-in">
            <div className="form-wrapper ml-auto align-items-center">
              <div className="form sign-in">
                {!emailSent ? (
                  <form>
                    <Link to="/">
                      <img src={logo} alt="logo" className="login-logo" />
                    </Link>
                    <div className="input-group">
                      <i className="bx bxs-user"></i>
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required={true}
                      />
                    </div>

                    <button type="button" onClick={handleForgotPassword}>
                      {isLoading ? "Loading ..." : "Reset Password"}
                    </button>

                    <p>
                      <Link
                        to="/login"
                        className="pointer"
                        style={{ color: "#000" }}
                      >
                        Back to login
                      </Link>
                    </p>
                  </form>
                ) : null}

                {emailSent ? (
                  <form>
                    <Link to="/">
                      <img src={logo} alt="logo" className="login-logo" />
                    </Link>

                    <div className="input-group">
                      <i className="bx bxs-user"></i>
                      <input
                        type="password"
                        placeholder="Token"
                        name="token"
                        value={newTokenInput}
                        onChange={(e) => setNewTokenInput(e.target.value)}
                        required={true}
                      />
                    </div>
                    <div className="input-group">
                      <i className="bx bxs-user"></i>
                      <input
                        type="password"
                        placeholder="New Password"
                        name="password"
                        // value={email}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required={true}
                      />
                    </div>

                    <button type="button" onClick={handleResetPassword}>
                      {isLoading ? "Loading ..." : "Change Password"}
                    </button>

                    <p>
                      <Link
                        to="/login"
                        className="pointer"
                        style={{ color: "#000" }}
                      >
                        Back to login
                      </Link>
                    </p>
                  </form>
                ) : null}
              </div>
            </div>
            <div className="form-wrapper"></div>
          </div>
          {/* <!-- END SIGN IN --> */}
        </div>
        {/* <!-- END FORM SECTION --> */}
        {/* <!-- CONTENT SECTION --> */}
        <div className="row row1 content-row">
          {/* <!-- SIGN IN CONTENT --> */}
          <div className="col col1 align-items-center  flex-col">
            <div className="text sign-in">
              <h2 style={{ color: "white" }}>Welcome Back</h2>
            </div>
            <div className="img sign-in"></div>
          </div>
          {/* <!-- END SIGN IN CONTENT --> */}
          {/* <!-- SIGN UP CONTENT --> */}
          <div className="col1 align-items-center flex-col">
            <div className="img sign-up"></div>
            <div className="text sign-up">
              <h2 style={{ color: "white", textAlign: "center" }}>
                Hello Friend!
              </h2>
              <h2
                style={{
                  fontSize: "18px",
                  width: "100%",
                  color: "white",
                  textAlign: "center",
                  // marginLeft: "50px",
                }}
              >
                Enter your details and start your journey with us today!
              </h2>
            </div>
          </div>
          {/* // <!-- END SIGN UP CONTENT -->
            // <!-- END CONTENT SECTION --> */}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

import { createSlice } from "@reduxjs/toolkit";
import { changePassword, deleteAccount } from "./securityService";

const securitySlice = createSlice({
  name: "security",
  initialState: {
    loading: false,
    message: null,
    isError: false,
    isSuccess: false,
  },

  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.isError = false;
        state.message = null;
        state.isSuccess = false;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.isError = false;
        state.message = action.payload.message;
        state.isSuccess = true;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(deleteAccount.pending, (state) => {
        state.loading = true;
        state.isError = false;
        state.message = null;
        state.isAccountDeleteSuccess = false;
      })
      .addCase(deleteAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.isError = false;
        state.message = "Account Deleted Successfully";
        // state.message = action.payload;
        state.isAccountDeleteSuccess = true;
      })
      .addCase(deleteAccount.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
        state.isAccountDeleteSuccess = false;
      });
  },
  reducers: {
    editIsSuccessSecurity: (state, action) => {
      state.isSuccess = state.payload;
    },
    editIsAccountDeleteSuccess: (state, action) => {
      state.isAccountDeleteSuccess = state.payload;
    },
  },
});

export const { editIsSuccessSecurity, editIsAccountDeleteSuccess } =
  securitySlice.actions;

export default securitySlice.reducer;

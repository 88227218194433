import React, { useContext } from "react";
import { Link } from "react-router-dom";
import style from "./styles/MyAppearance.module.css";
import light from "../../assets/LIGHT-THEME.png";
import dark from "../../assets/DARK-THEME.png";
import { ThemeContext } from "../../utils/ThemeContext";

const MyAppearance = () => {
  const { context, handleImageClick } = useContext(ThemeContext);
  const colors = [
    "#594CAD",
    "#3498DB",
    "#11806A",
    "#F1C40F",
    "#CD5B45",
    "#E92663",
    "#954AA8",
    "#594CAD",
    "#206694",
    "#11806A",
    "#C27C0E",
    "#FF424F",
    "#CD5B45",
    "#AD1B57",
    "#954AA8",
  ];
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            color: context === "dark" ? "white" : "black",
          }}
        >
          Appearance
        </div>
        <Link to="/coderoom">
          <div
            style={{
              border:
                context === "dark" ? "2px solid white" : "2px solid black",
              borderRadius: "50%",
            }}
          >
            <svg
              viewBox="0 0 24 24"
              height="28"
              width="28"
              aria-hidden="true"
              focusable="false"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              class="StyledIconBase-ea9ulj-0 bWRyML"
              style={{ color: context === "dark" ? "white" : "black" }}
            >
              <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
            </svg>
          </div>
        </Link>
      </div>
      {/* theme */}
      <div style={{ marginTop: "40px" }}>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "15px",
            color: context === "dark" ? "white" : "black",
          }}
        >
          THEME
        </p>
        <div style={{ display: "flex" }}>
          <div>
            <img
              src={light}
              alt="light-theme"
              width={"100%"}
              className={style.image_select}
              onClick={() => handleImageClick("light")}
            />
            <p
              style={{
                marginTop: "5px",
                fontSize: "15px",
                color: context === "dark" ? "white" : "black",
              }}
            >
              LIGHT
            </p>
          </div>
          <div
            style={{
              marginLeft: "10px",
              color: context === "dark" ? "white" : "black",
            }}
          >
            <img
              src={dark}
              alt="dark-theme"
              width={"100%"}
              className={style.image_select}
              onClick={() => handleImageClick("dark")}
            />
            <p
              style={{
                marginTop: "5px",
                fontSize: "15px",
                color: context === "dark" ? "white" : "black",
              }}
            >
              DARK
            </p>
          </div>
        </div>
      </div>
      {/* color */}
      <div style={{ marginTop: "40px" }}>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "15px",
            color: context === "dark" ? "white" : "black",
          }}
        >
          DISPLAY COLOR
        </p>
        <div style={{ display: "flex" }}>
          <div className={style.color_main_div}>
            {" "}
            <svg
              viewBox="0 0 24 24"
              height="28"
              width="28"
              aria-hidden="true"
              focusable="false"
              fill="#ffffff"
              xmlns="http://www.w3.org/2000/svg"
              class="StyledIconBase-ea9ulj-0 bWRyML"
            >
              <path d="M9.38 21.646A9.985 9.985 0 0 0 12 22l.141-.001a2.998 2.998 0 0 0 2.515-1.425c.542-.876.6-1.953.153-2.88l-.198-.415c-.453-.942-.097-1.796.388-2.281.485-.485 1.341-.841 2.28-.388h.001l.413.199a2.99 2.99 0 0 0 2.881-.153A2.997 2.997 0 0 0 22 12.141a9.926 9.926 0 0 0-.353-2.76c-1.038-3.827-4.353-6.754-8.246-7.285-3.149-.427-6.241.602-8.471 2.833S1.666 10.247 2.096 13.4c.53 3.894 3.458 7.208 7.284 8.246zM15.5 6a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-5-1a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zM9 15.506a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-2.5-6.5a1.5 1.5 0 1 1-.001 3.001A1.5 1.5 0 0 1 6.5 9.006z"></path>
            </svg>
          </div>
          <div className={style.color_sec_div}>
            {colors.map((color, index) => (
              <div
                key={index}
                style={{
                  background: color,
                  borderRadius: "4px",
                  width: "25px",
                  height: "25px",
                }}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAppearance;

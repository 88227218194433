import React from "react";
import logo from "../img/logo22.png";
import { Link } from "react-router-dom";
import { Link as ScrollLink, scroller } from "react-scroll";
import { useDispatch, useSelector } from "react-redux";
import { moveToFAQ } from "../../app/features/reactScroll/reactScrollSlice";
import "./BetaBatch.css";
const Nav = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (FAQ) {
      scroller.scrollTo("faq", {
        offset: -100,
        smooth: true,
      });
      dispatch(moveToFAQ(false));
    }
  }, [FAQ]);

  const { user } = useSelector((state) => state.auth);
  const { FAQ } = useSelector((state) => state.reactScroll);
  return (
    <>
      <nav className="bg-bg-3 sticky-top" style={{ overflow: "hidden" }}>
        <div className="d-block d-lg-none">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-3">
                <a
                  className="btn btn-width-equal-height d-lg-none rounded-circle custom-mobile-nav-btn"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#custom-id-ucdddk2g"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-light-1"
                  >
                    <path d="M2.00016 5.33333H14.0002C14.177 5.33333 14.3465 5.2631 14.4716 5.13807C14.5966 5.01305 14.6668 4.84348 14.6668 4.66667C14.6668 4.48986 14.5966 4.32029 14.4716 4.19526C14.3465 4.07024 14.177 4 14.0002 4H2.00016C1.82335 4 1.65378 4.07024 1.52876 4.19526C1.40373 4.32029 1.3335 4.48986 1.3335 4.66667C1.3335 4.84348 1.40373 5.01305 1.52876 5.13807C1.65378 5.2631 1.82335 5.33333 2.00016 5.33333ZM14.0002 10.6667H2.00016C1.82335 10.6667 1.65378 10.7369 1.52876 10.8619C1.40373 10.987 1.3335 11.1565 1.3335 11.3333C1.3335 11.5101 1.40373 11.6797 1.52876 11.8047C1.65378 11.9298 1.82335 12 2.00016 12H14.0002C14.177 12 14.3465 11.9298 14.4716 11.8047C14.5966 11.6797 14.6668 11.5101 14.6668 11.3333C14.6668 11.1565 14.5966 10.987 14.4716 10.8619C14.3465 10.7369 14.177 10.6667 14.0002 10.6667ZM14.0002 7.33333H2.00016C1.82335 7.33333 1.65378 7.40357 1.52876 7.5286C1.40373 7.65362 1.3335 7.82319 1.3335 8C1.3335 8.17681 1.40373 8.34638 1.52876 8.4714C1.65378 8.59643 1.82335 8.66667 2.00016 8.66667H14.0002C14.177 8.66667 14.3465 8.59643 14.4716 8.4714C14.5966 8.34638 14.6668 8.17681 14.6668 8C14.6668 7.82319 14.5966 7.65362 14.4716 7.5286C14.3465 7.40357 14.177 7.33333 14.0002 7.33333Z"></path>
                  </svg>
                </a>
              </div>
              <div
                className="col-6 text-center"
                data-aos="fade-down"
                data-aos-delay="250"
              >
                <a href="#" className="w-50">
                  <img
                    src={logo}
                    alt=""
                    className="img-fluid w-100 h-75 "
                    data-aos="fade-down"
                    data-aos-delay="0"
                  />
                </a>
              </div>
              <div
                className="col-3"
                data-aos="fade-down"
                data-aos-delay="500"
              ></div>
            </div>
          </div>
        </div>
        <div
          className="offcanvas offcanvas-start bg-bg-3"
          id="custom-id-ucdddk2g"
          aria-modal="true"
          role="dialog"
        >
          <div className="offcanvas-header">
            <Link to="/comingsoon">
              <img
                src={logo}
                //   srcset="
                //   pub/185029/ea555aaade9698f7280435fa483018d0/uploads/c980d9a810fd4f169933512f9b8296a3.png 2x
                // "
                alt=""
                className="img-fluid w-100"
              />
            </Link>
            <a
              className="btn btn-sm btn-width-equal-height btn-bg-3"
              data-bs-dismiss="offcanvas"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-dark-2"
              >
                <path d="M8.9398 8L13.1398 3.80667C13.2653 3.68113 13.3359 3.51087 13.3359 3.33333C13.3359 3.1558 13.2653 2.98554 13.1398 2.86C13.0143 2.73447 12.844 2.66394 12.6665 2.66394C12.4889 2.66394 12.3187 2.73447 12.1931 2.86L7.9998 7.06L3.80646 2.86C3.68093 2.73447 3.51066 2.66394 3.33313 2.66394C3.1556 2.66394 2.98533 2.73447 2.8598 2.86C2.73426 2.98554 2.66374 3.1558 2.66374 3.33333C2.66374 3.51087 2.73426 3.68113 2.8598 3.80667L7.0598 8L2.8598 12.1933C2.79731 12.2553 2.74771 12.329 2.71387 12.4103C2.68002 12.4915 2.6626 12.5787 2.6626 12.6667C2.6626 12.7547 2.68002 12.8418 2.71387 12.9231C2.74771 13.0043 2.79731 13.078 2.8598 13.14C2.92177 13.2025 2.99551 13.2521 3.07675 13.2859C3.15798 13.3198 3.24512 13.3372 3.33313 13.3372C3.42114 13.3372 3.50827 13.3198 3.58951 13.2859C3.67075 13.2521 3.74449 13.2025 3.80646 13.14L7.9998 8.94L12.1931 13.14C12.2551 13.2025 12.3288 13.2521 12.4101 13.2859C12.4913 13.3198 12.5785 13.3372 12.6665 13.3372C12.7545 13.3372 12.8416 13.3198 12.9228 13.2859C13.0041 13.2521 13.0778 13.2025 13.1398 13.14C13.2023 13.078 13.2519 13.0043 13.2857 12.9231C13.3196 12.8418 13.337 12.7547 13.337 12.6667C13.337 12.5787 13.3196 12.4915 13.2857 12.4103C13.2519 12.329 13.2023 12.2553 13.1398 12.1933L8.9398 8Z"></path>
              </svg>
            </a>
          </div>
          <div className="offcanvas-body">
            <div>
              <ScrollLink to="home" className="hover-overlay" offset={-200}>
                <a href="#" className="fw-bold text-dark-1 py-2 d-inline-block">
                  Home
                </a>
              </ScrollLink>
            </div>
            <div>
              <ScrollLink to="feature" offset={-100}>
                <a href="#" className="fw-bold text-dark-1 py-2 d-inline-block">
                  Features
                </a>
              </ScrollLink>
            </div>
            <div>
              <ScrollLink to="faq" offset={-100}>
                <a href="#" className="fw-bold text-dark-1 py-2 d-inline-block">
                  FAQ
                </a>
              </ScrollLink>
            </div>
            <div>
              <ScrollLink to="pricing" offset={-100}>
                <a href="#" className="fw-bold text-dark-1 py-2 d-inline-block">
                  Pricing
                </a>
              </ScrollLink>
            </div>
            <div>
              <Link to="/ide">
                <a href="#" className="fw-bold py-2 d-inline-block text-bg-1">
                  IDE Plugins
                </a>
              </Link>
            </div>
            {user ? (
              <Link to="/dashboard">
                <a href="#" className="btn btn-sm rounded-2 mt-4 btn-bg-1">
                  Dashboard
                </a>
              </Link>
            ) : (
              <Link to="/login">
                <a href="#" className="btn btn-sm rounded-2 mt-4 btn-bg-1">
                  Sign Up/Sign in
                </a>
              </Link>
            )}
          </div>
        </div>
        <div className="d-none d-lg-block">
          <div className="container">
            <div className="col-2">
              <div className="badge">Beta</div>
            </div>
            <div
              className="align-items-center justify-content-between g-0 row"
              style={{ height: "8rem" }}
            >
              <div className="col-2">
                <Link to="/comingsoon">
                  <img
                    src={logo}
                    alt=""
                    className="img-fluid w-75 vw-75"
                    //   srcset="
                    //   pub/185029/ea555aaade9698f7280435fa483018d0/uploads/c980d9a810fd4f169933512f9b8296a3.png 2x
                    // "
                  />
                </Link>
              </div>
              <div className="text-center d-flex justify-content-center hover-overlay col-6">
                <ScrollLink to="home" className="hover-overlay" offset={-100}>
                  <a href="#" className="fw-bold mx-4 text-dark-1 ">
                    Home
                  </a>
                </ScrollLink>
                <ScrollLink to="feature" offset={-100}>
                  <a href="#" className="fw-bold mx-4 text-dark-1">
                    Features
                  </a>
                </ScrollLink>
                <ScrollLink to="faq" offset={-100}>
                  <a href="#" className="fw-bold mx-4 text-dark-1">
                    FAQ
                  </a>
                </ScrollLink>
                <ScrollLink to="pricing" offset={-100}>
                  <a href="#" className="fw-bold mx-4 text-dark-1">
                    Pricing
                  </a>
                </ScrollLink>
                <Link to="/ide">
                  <a href="#" className="fw-bold mx-4 text-dark-1">
                    IDE Plugins
                  </a>
                </Link>
                <ScrollLink to="contact" offset={-100}>
                  <a href="#" className="fw-bold mx-4 text-dark-1">
                    contact
                  </a>
                </ScrollLink>
              </div>
              {user ? (
                <div className="d-flex align-items-center justify-content-end col-lg-3">
                  <Link
                    to="/dashboard"
                    className="btn btn-sm rounded-2 btn-bg-1"
                  >
                    Move To Dashboard
                  </Link>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-end col-lg-3">
                  <Link to="/login">
                    <a href="#" className="fw-bold fs-4 me-8 text-bg-1">
                      Sign In
                    </a>
                  </Link>
                  <Link to="/login" state={{ is_signup: true }}>
                    <a href="#" className="btn btn-sm rounded-2 btn-bg-1">
                      Sign Up
                    </a>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Nav;

import React, { useEffect, useState } from "react";
import BarChart from "../component/charts/barchart";
import style from "./dashboard.module.css";
import Cookies from "js-cookie";
import "./dashboard.module.css";
import { useSelector } from "react-redux";
import axios from "../utils/axiosInstance";

const DashboardTarget = () => {
  const [dailyData, setDailyData] = useState([]);
  const [weeklyData, setWeeklyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [usageData, setUsageData] = useState({
    labels: dailyData.map((data) => data.createdDate),
    datasets: [
      {
        label: "Prompt Tokens",
        data: dailyData.map((data) => {
          return data.prompt_tokens;
        }),
        backgroundColor: "rgba(37, 138, 214, 0.8)",
      },
      {
        label: "Completion Tokens",
        data: dailyData.map((data) => data.completion_tokens),
        backgroundColor: "rgba(246, 174, 85, 0.8)",
      },
      {
        label: "Total Tokens",
        data: dailyData.map((data) => data.total_tokens),
        backgroundColor: "rgba(205, 41, 126, 0.8)",
      },
    ],
  });
  const [weeklyUsageData, setWeeklyUsageData] = useState({
    labels: weeklyData.map((data) => data.createdDate),
    datasets: [
      {
        label: "Prompt Tokens",
        data: weeklyData.map((data) => data.prompt_tokens),
        backgroundColor: "rgba(37, 138, 214, 0.8)",
      },
      {
        label: "Completion Tokens",
        data: weeklyData.map((data) => data.completion_tokens),
        backgroundColor: "rgba(246, 174, 85, 0.8)",
      },
      {
        label: "Total Tokens",
        data: weeklyData.map((data) => data.total_tokens),
        backgroundColor: "rgba(205, 41, 126, 0.8)",
      },
    ],
  });
  const [monthlyUsageData, setMonthlyUsageData] = useState({
    labels: monthlyData.map((data) => data.createdDate),
    datasets: [
      {
        label: "Prompt Tokens",
        data: monthlyData.map((data) => data.prompt_tokens),
        backgroundColor: "rgba(37, 138, 214, 0.8)",
      },
      {
        label: "Completion Tokens",
        data: monthlyData.map((data) => data.completion_tokens),
        backgroundColor: "rgba(246, 174, 85, 0.8)",
      },
      {
        label: "Total Tokens",
        data: monthlyData.map((data) => data.total_tokens),
        backgroundColor: "rgba(205, 41, 126, 0.8)",
      },
    ],
  });

  const [chartData, setChartData] = useState(usageData);

  useEffect(() => {
    const token = Cookies.get("token");

    axios
      .get("usageDetails/daily", {
        headers: { Authorization: "Bearer " + token },
      })
      .then(({ data }) => {
        console.log(data.dailyTokens);
        setDailyData(data.dailyTokens);
      });

    axios
      .get("usageDetails/weekly", {
        headers: { Authorization: "Bearer " + token },
      })
      .then(({ data }) => {
        setWeeklyData(data.weeklyTokens);
      });

    axios
      .get("usageDetails/monthly", {
        headers: { Authorization: "Bearer " + token },
      })
      .then(({ data }) => {
        setMonthlyData(data.monthlyTokens);
      });
  }, []);

  useEffect(() => {
    // Update chartData when dailyData, weeklyData, or monthlyData change
    setUsageData({
      labels: dailyData.map((data) => data.createdDate),
      datasets: [
        {
          label: "Prompt Tokens",
          data: dailyData.map((data) => data.prompt_tokens),
          backgroundColor: "rgba(37, 138, 214, 0.8)",
        },
        {
          label: "Completion Tokens",
          data: dailyData.map((data) => data.completion_tokens),
          backgroundColor: "rgba(246, 174, 85, 0.8)",
        },
        {
          label: "Total Tokens",
          data: dailyData.map((data) => data.total_tokens),
          backgroundColor: "rgba(205, 41, 126, 0.8)",
        },
      ],
    });

    setWeeklyUsageData({
      labels: weeklyData.map((data) => data.createdDate),
      datasets: [
        {
          label: "Prompt Tokens",
          data: weeklyData.map((data) => data.prompt_tokens),
          backgroundColor: "rgba(37, 138, 214, 0.8)",
        },
        {
          label: "Completion Tokens",
          data: weeklyData.map((data) => data.completion_tokens),
          backgroundColor: "rgba(246, 174, 85, 0.8)",
        },
        {
          label: "Total Tokens",
          data: weeklyData.map((data) => data.total_tokens),
          backgroundColor: "rgba(205, 41, 126, 0.8)",
        },
      ],
    });
    setMonthlyUsageData({
      labels: monthlyData.map((data) => data.createdDate),
      datasets: [
        {
          label: "Prompt Tokens",
          data: monthlyData.map((data) => data.prompt_tokens),
          backgroundColor: "rgba(37, 138, 214, 0.8)",
        },
        {
          label: "Completion Tokens",
          data: monthlyData.map((data) => data.completion_tokens),
          backgroundColor: "rgba(246, 174, 85, 0.8)",
        },
        {
          label: "Total Tokens",
          data: monthlyData.map((data) => data.total_tokens),
          backgroundColor: "rgba(205, 41, 126, 0.8)",
        },
      ],
    });
  }, [dailyData, weeklyData, monthlyData]);

  useEffect(() => {
    setChartData(usageData);
  }, [usageData]);

  //options
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "daily") {
      setChartData(usageData);
      console.log(chartData);
    } else if (selectedValue === "weekly") {
      setChartData(weeklyUsageData);
    } else if (selectedValue === "monthly") {
      setChartData(monthlyUsageData);
    }
  };

  return (
    <section className="pb-20 bg-bg-3">
      <main style={{ maxWidth: "100%", height: 100 }}>
        <div className={style.cards}></div>
        <div className={`${style.card_single}`}>
          <div className={style.card_single_one}>
            <h2 style={{ color: "white" }}>
              Hello {user && user.firstName ? user.firstName : "User"}!
            </h2>
            <span>Its good to see you again</span>
          </div>
          <div>
            {/*<video
                    height={150}
                    autoPlay
                    muted
                    loop
                    src='./mlvideo.mp4'
                  ></video>*/}
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center my-3 py-5">
            <div className="col-sm-6">
              <div className="form-group">
                <label
                  htmlFor="chart-type-select"
                  className="fw-800 display-5 tw-text-lg"
                  style={{ fontSize: "1rem" }}
                >
                  Usage Chart
                </label>
                <select
                  id="chart-type-select"
                  className="d-block  my-4"
                  onChange={handleDropdownChange}
                  style={{ width: "100px", height: "40px" }}
                >
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-10">
              <div className="">
                <div className="">
                  <BarChart chartData={chartData} options={options} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
};

export default DashboardTarget;

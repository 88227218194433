import { createAsyncThunk } from "@reduxjs/toolkit";
import mixpanel from "mixpanel-browser";
import Cookies from "js-cookie";
import axiosInstance from "../../../utils/axiosInstance";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (userCredentials, thunkAPI) => {
    try {
      const request = await axiosInstance.post(
        `/users/signin`,
        userCredentials
      );

      try {
        mixpanel.identify(userCredentials.email);

        // // set user properties
        // mixpanel.people.set({ $email: userCredentials.email });

        // Track the signup event
        mixpanel.track("Login Completed", {
          "Email ID": userCredentials.email,
          "Login Date": new Date().toISOString(),
        });
      } catch (error) {
        console.log("Mixpanel Error: ", error);
      }
      const response = request;
      Cookies.set("token", response.data.token, {
        secure: true,
      });
      Cookies.set("user", JSON.stringify(response.data.data.user), {
        secure: true,
      });
      localStorage.setItem("loginResponse", JSON.stringify(response.data.data));
      return response.data;
    } catch (error) {
      mixpanel.track("Login Failed", {
        "Email ID": userCredentials.email,
        "Login Date": new Date().toISOString(),
      });
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const signUpUser = createAsyncThunk(
  "auth/signUpUser",
  async (userCredentials, thunkAPI) => {
    try {
      const request = await axiosInstance.post(
        `/users/signup`,
        userCredentials
      );
      try {
        mixpanel.identify(userCredentials.email);

        // set user properties
        mixpanel.people.set({ $email: userCredentials.email });

        // Track the signup event
        mixpanel.track("Sign Up Completed", {
          "Email ID": userCredentials.email,
          "Sign Up Date": new Date().toISOString(),
          Name: `${userCredentials.firstName} ${userCredentials.lastName}`,
        });
      } catch (error) {
        console.log("Mixpanel Error : ", error);
      }
      const response = request;
      return response.data;
    } catch (error) {
      mixpanel.track("Sign Up Failed", {
        "Email ID": userCredentials.email,
        "Sign Up Date": new Date().toISOString(),
        Name: `${userCredentials.firstName} ${userCredentials.lastName}`,
      });
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateUser = (user) => {
  Cookies.set("user", JSON.stringify(user), {
    secure: true,
  });
};

export const subUserSignup = createAsyncThunk(
  "auth/signUpUser",
  async (userCredentials, thunkAPI) => {
    try {
      const request = await axiosInstance.post(
        `/users/subusers/signup?token=${userCredentials.token}`,
        userCredentials
      );
      const response = request;
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import CodeRoomHome from "../component/coderoom/CodeRoomHome";
import CodeRoomChannel from "../component/coderoom/CodeRoomChannel";
import CodeRoomChannelNames from "../component/coderoom/CodeRoomChannelName";
import style from "./codeRoom.module.css";
import { ThemeContext } from "../utils/ThemeContext";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { SketchPicker } from "react-color";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";
import { getUnseenMsg, setUnseenMsg } from "../Service/chatService";
import logo from "../component/img/logo22.png";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const CodeRoom = () => {
  const roomId = useParams().id;
  const [loading, setLoading] = React.useState(false);
  const { context } = useContext(ThemeContext);
  const [isDashboardOpen, setDashboardOpen] = useState(true);
  const [activeSection, setActiveSection] = useState("");
  const [allChannels, setAllChannels] = useState([]);
  const [isChannelOpen, setChannelOpen] = useState(true);
  const [userDetails, setUserDetails] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({
    chatroomUsername: "",
    color: "",
  });

  const [incomingMessage, setIncomingMessage] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    addChatroomUser();
    if (!userInfo.color) {
      alert("Please select a color");
      return;
    }
  };

  const toggleDashboard = () => {
    setDashboardOpen(!isDashboardOpen);
  };

  const handleSectionClick = (section, uuid) => {
    // let unSeenMsg = getUnseenMsg();
    // if (unSeenMsg.length > 0) {
    //   let indexofmsg = unSeenMsg.indexOf((unseen) => unseen.room === uuid);
    //   setUnseenMsg(unSeenMsg.slice(0, indexofmsg));
    // }
    setActiveSection(section);
    console.log("Clicked >> ", divRef.current);
    if (divRef.current) {
      divRef.current.click();
    }
  };

  const toggleChannel = () => {
    setChannelOpen(!isChannelOpen);
  };
  const getChatroomUser = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/users/get_chatroom_user`,
        config
      );
      if (response) {
        setUserDetails(response.data.details);
      }
      // Handle the response data
    } catch (error) {
      //("error.response.data::: ", error);
    }
  };

  const addChatroomUser = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/users/update_chatroom_user`,
        userInfo,
        config
      );
      if (response) {
        setLoading(false);
        getChatroomUser();
        setIsOpen(false);
      }
      // Handle the response data
    } catch (error) {
      setLoading(false);
      //("error.response.data::: ", error);
    }
  };
  useEffect(() => {
    //("CHAT USER ROOM CALLED");
    // if (roomId !== undefined) {
    getChatroomUser();
    // }
  }, []);

  useEffect(() => {
    if (userDetails && userDetails["chatRoomUsername"] === ("" || null)) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [userDetails]);

  const findObjectByUUID = (data, uuid) => {
    for (const obj of data) {
      const foundRoom = obj.rooms.find((room) => room.uuid === uuid);
      if (foundRoom) {
        return foundRoom;
      }
    }
    return null; // Return null if no match found
  };

  useEffect(() => {
    if (allChannels.length > 0) {
      const matchedObject = findObjectByUUID(allChannels, roomId);
      setActiveSection(matchedObject ? matchedObject.room : null);
    }
  }, [allChannels]);

  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const buttonRef = React.useRef(null);
  const divRef = React.useRef(null);

  const handleButtonClick = () => {
    console.log("Clicked >> ", divRef.current);
    if (divRef.current) {
      divRef.current.click();
    }
  };

  return (
    <>
      {/* modal */}
      <div>
        <Modal
          show={isOpen}
          // onHide={closeModal}
          // backdrop="static"
          keyboard={false}
          centered
          className={
            context === "dark"
              ? `${style.modalBlack} ${style.modal}`
              : `${style.modal}`
          }
        >
          <div className="modal-header">
            <h5
              className="modal-title"
              style={{ color: context === "dark" ? "white" : "black" }}
            >
              User Details
            </h5>
            <button
              type="button"
              className={`btn-close ${style.btnClose}`}
              // onClick={closeModal}
            ></button>
          </div>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label
                  htmlFor="username"
                  className="form-label"
                  style={{
                    color: context === "dark" ? "white" : "black",
                  }}
                >
                  Username
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="username"
                  value={userInfo.chatroomUsername}
                  onChange={(e) => {
                    setUserInfo({
                      ...userInfo,
                      chatroomUsername: e.target.value,
                    });
                  }}
                  required
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="username"
                  className="form-label"
                  style={{
                    color: context === "dark" ? "white" : "black",
                  }}
                >
                  Display Color
                </label>
                <SketchPicker
                  onChange={(e) => {
                    setUserInfo({
                      ...userInfo,
                      color: e.hex,
                    });
                  }}
                  required
                  color={userInfo.color}
                  className={style.colorPicker}
                />
              </div>
              <div className={style.modal_footer}>
                {/* <button type="submit" className={style.btn_cancel}>
                  Cancel
                </button> */}
                <button className={style.btn_submit}>
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>

      <div
        className="row align-items-center hidden"
        style={{ display: "none" }}
      >
        <div className="col-3">
          <a
            className="btn btn-width-equal-height d-lg-none rounded-circle custom-mobile-nav-btn invisible"
            data-bs-toggle="offcanvas"
            data-bs-target="#custom-id-ucdddk2g"
            ref={divRef}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="fill-light-1"
            >
              <path d="M2.00016 5.33333H14.0002C14.177 5.33333 14.3465 5.2631 14.4716 5.13807C14.5966 5.01305 14.6668 4.84348 14.6668 4.66667C14.6668 4.48986 14.5966 4.32029 14.4716 4.19526C14.3465 4.07024 14.177 4 14.0002 4H2.00016C1.82335 4 1.65378 4.07024 1.52876 4.19526C1.40373 4.32029 1.3335 4.48986 1.3335 4.66667C1.3335 4.84348 1.40373 5.01305 1.52876 5.13807C1.65378 5.2631 1.82335 5.33333 2.00016 5.33333ZM14.0002 10.6667H2.00016C1.82335 10.6667 1.65378 10.7369 1.52876 10.8619C1.40373 10.987 1.3335 11.1565 1.3335 11.3333C1.3335 11.5101 1.40373 11.6797 1.52876 11.8047C1.65378 11.9298 1.82335 12 2.00016 12H14.0002C14.177 12 14.3465 11.9298 14.4716 11.8047C14.5966 11.6797 14.6668 11.5101 14.6668 11.3333C14.6668 11.1565 14.5966 10.987 14.4716 10.8619C14.3465 10.7369 14.177 10.6667 14.0002 10.6667ZM14.0002 7.33333H2.00016C1.82335 7.33333 1.65378 7.40357 1.52876 7.5286C1.40373 7.65362 1.3335 7.82319 1.3335 8C1.3335 8.17681 1.40373 8.34638 1.52876 8.4714C1.65378 8.59643 1.82335 8.66667 2.00016 8.66667H14.0002C14.177 8.66667 14.3465 8.59643 14.4716 8.4714C14.5966 8.34638 14.6668 8.17681 14.6668 8C14.6668 7.82319 14.5966 7.65362 14.4716 7.5286C14.3465 7.40357 14.177 7.33333 14.0002 7.33333Z"></path>
            </svg>
          </a>
        </div>
      </div>

      <div className={style.font}>
        <header
          className={`${
            windowDimensions.width >= 1024 ? style.d_header : style.d_header0
          }`}
        >
          <div
            className={style.d_header1}
            style={{
              background: context === "dark" ? "#1E1E1E" : "#f1f1f1",
              width: windowDimensions.width >= 1024 ? "auto" : "100vw",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: windowDimensions.width >= 1024 ? "38%" : "100%",
                columnGap: "20px",
              }}
            >
              <div
                className="col-3"
                ref={buttonRef}
                onClick={handleButtonClick}
              >
                <a className="btn btn-width-equal-height d-lg-none rounded-circle custom-mobile-nav-btn">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-light-1"
                  >
                    <path d="M2.00016 5.33333H14.0002C14.177 5.33333 14.3465 5.2631 14.4716 5.13807C14.5966 5.01305 14.6668 4.84348 14.6668 4.66667C14.6668 4.48986 14.5966 4.32029 14.4716 4.19526C14.3465 4.07024 14.177 4 14.0002 4H2.00016C1.82335 4 1.65378 4.07024 1.52876 4.19526C1.40373 4.32029 1.3335 4.48986 1.3335 4.66667C1.3335 4.84348 1.40373 5.01305 1.52876 5.13807C1.65378 5.2631 1.82335 5.33333 2.00016 5.33333ZM14.0002 10.6667H2.00016C1.82335 10.6667 1.65378 10.7369 1.52876 10.8619C1.40373 10.987 1.3335 11.1565 1.3335 11.3333C1.3335 11.5101 1.40373 11.6797 1.52876 11.8047C1.65378 11.9298 1.82335 12 2.00016 12H14.0002C14.177 12 14.3465 11.9298 14.4716 11.8047C14.5966 11.6797 14.6668 11.5101 14.6668 11.3333C14.6668 11.1565 14.5966 10.987 14.4716 10.8619C14.3465 10.7369 14.177 10.6667 14.0002 10.6667ZM14.0002 7.33333H2.00016C1.82335 7.33333 1.65378 7.40357 1.52876 7.5286C1.40373 7.65362 1.3335 7.82319 1.3335 8C1.3335 8.17681 1.40373 8.34638 1.52876 8.4714C1.65378 8.59643 1.82335 8.66667 2.00016 8.66667H14.0002C14.177 8.66667 14.3465 8.59643 14.4716 8.4714C14.5966 8.34638 14.6668 8.17681 14.6668 8C14.6668 7.82319 14.5966 7.65362 14.4716 7.5286C14.3465 7.40357 14.177 7.33333 14.0002 7.33333Z"></path>
                  </svg>
                </a>
              </div>

              <div className={style.w_100}>
                <div className={style.d_header_inner}>
                  <div
                    className={style.semibold}
                    style={{
                      color: context === "dark" ? "white" : "black",
                    }}
                  >
                    {activeSection ? "#" : ""}
                  </div>
                  <p
                    className={style.d_header_h1}
                    style={{
                      color: context === "dark" ? "white" : "black",
                    }}
                  >
                    {activeSection}
                  </p>
                </div>
              </div>
            </div>
            <div className={style.d_header_h1_div}>
            <Link to="/coderoom-setting" style={{ display:"flex", alignItems:"center", justifyContent:"center", marginRight: "2rem" }}>
                      <div >
                        <svg
                          viewBox="0 0 24 24"
                          height="22"
                          width="22"
                          aria-hidden="true"
                          focusable="false"
                          fill={context === "dark" ? "white" : "black"}
                          xmlns="http://www.w3.org/2000/svg"
                          class="StyledIconBase-ea9ulj-0 VtuNT"
                        >
                          <path d="m2.344 15.271 2 3.46a1 1 0 0 0 1.366.365l1.396-.806c.58.457 1.221.832 1.895 1.112V21a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-1.598a8.094 8.094 0 0 0 1.895-1.112l1.396.806c.477.275 1.091.11 1.366-.365l2-3.46a1.004 1.004 0 0 0-.365-1.366l-1.372-.793a7.683 7.683 0 0 0-.002-2.224l1.372-.793c.476-.275.641-.89.365-1.366l-2-3.46a1 1 0 0 0-1.366-.365l-1.396.806A8.034 8.034 0 0 0 15 4.598V3a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1.598A8.094 8.094 0 0 0 7.105 5.71L5.71 4.904a.999.999 0 0 0-1.366.365l-2 3.46a1.004 1.004 0 0 0 .365 1.366l1.372.793a7.683 7.683 0 0 0 0 2.224l-1.372.793c-.476.275-.641.89-.365 1.366zM12 8c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4 1.794-4 4-4z"></path>
                        </svg>
                      </div>
                    </Link>

              <Link to="/dashboard">
                <button
                  className={
                    context === "dark"
                      ? style.d_header_button_dark
                      : style.d_header_button_light
                  }
                >
                  Return
                </button>
              </Link>
            </div>
          </div>
          <div></div>
        </header>

        <div className={style.flex}>
          <>
            {/* {isDashboardOpen && ( */}
            {windowDimensions.width <= 1024 ? (
              <div
                className="offcanvas offcanvas-start bg-bg-3"
                id="custom-id-ucdddk2g"
                aria-modal="true"
                role="dialog"
              >
                <div className="offcanvas-body p-0">
                  <aside
                    className={
                      windowDimensions.width <= 1024
                        ? style.d_aside
                        : `${style.d_aside} ${style.d_aside_closed}`
                    }
                    style={{
                      background: context === "dark" ? "#1E1E1E" : "#f1f1f1",
                    }}
                  >
                    <div>
                      <div
                        className={style.aside_div}
                        style={{
                          borderBottom:
                            context === "dark"
                              ? "1px solid #848484"
                              : "1px solid #d3d3d3",
                          marginTop: "10%",
                        }}
                      >
                        <p
                          className={style.aside_h1}
                          style={{
                            color: context === "dark" ? "white" : "black",
                          }}
                        >
                          DeepTuring Coderoom
                        </p>
                      </div>
                      <ul className={style.aside_ul}>
                        <li className={style.aside_li}>
                          {/* {isChannelOpen && ( */}
                          <CodeRoomChannelNames
                            handleSectionClick={handleSectionClick}
                            setAllChannels={setAllChannels}
                            incomingMessage={incomingMessage}
                            activeSection={activeSection}
                          />
                          {/* )} */}
                        </li>
                      </ul>
                    </div>
                    {/* setting icon */}
                   
                  </aside>
                </div>
              </div>
            ) : (
              <aside
                className={
                  isDashboardOpen
                    ? style.d_aside
                    : `${style.d_aside} ${style.d_aside_closed}`
                }
                style={{
                  background: context === "dark" ? "#1E1E1E" : "#f1f1f1",
                }}
              >
                <div>
                  <div
                    className={style.aside_div}
                    style={{
                      borderBottom:
                        context === "dark"
                          ? "1px solid #848484"
                          : "1px solid #d3d3d3",
                    }}
                  >
                    <p
                      className={style.aside_h1}
                      style={{
                        color: context === "dark" ? "white" : "black",
                      }}
                    >
                      DeepTuring Coderoom
                    </p>
                  </div>
                  <ul className={style.aside_ul}>
                    <li className={style.aside_li}>
                      <div
                        className={style.aside_li_div}
                        onClick={toggleChannel}
                      >
                        <div className={style.aside_li_div2}>
                          <div
                            className={style.aside_channel_div}
                            style={{
                              color: context === "dark" ? "white" : "black",
                            }}
                          >
                            Channels
                          </div>
                        </div>
                        {!isChannelOpen ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill={context === "dark" ? "white" : "black"}
                            stroke={context === "dark" ? "white" : "black"}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className={style.aside_svg}
                          >
                            <polyline points="6 9 12 15 18 9" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill={context === "dark" ? "white" : "black"}
                            stroke={context === "dark" ? "white" : "black"}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className={style.aside_svg}
                          >
                            <polyline points="6 15 12 9 18 15" />
                          </svg>
                        )}
                      </div>

                      {isChannelOpen && (
                        <CodeRoomChannelNames
                          handleSectionClick={handleSectionClick}
                          setAllChannels={setAllChannels}
                          incomingMessage={incomingMessage}
                          activeSection={activeSection}
                        />
                      )}
                    </li>
                  </ul>
                </div>
                {/* setting icon */}
                {/* <Link to="/coderoom-setting">
                  <div className={style.aside_setting}>
                    <svg
                      viewBox="0 0 24 24"
                      height="22"
                      width="22"
                      aria-hidden="true"
                      focusable="false"
                      fill={context === "dark" ? "white" : "black"}
                      xmlns="http://www.w3.org/2000/svg"
                      class="StyledIconBase-ea9ulj-0 VtuNT"
                    >
                      <path d="m2.344 15.271 2 3.46a1 1 0 0 0 1.366.365l1.396-.806c.58.457 1.221.832 1.895 1.112V21a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-1.598a8.094 8.094 0 0 0 1.895-1.112l1.396.806c.477.275 1.091.11 1.366-.365l2-3.46a1.004 1.004 0 0 0-.365-1.366l-1.372-.793a7.683 7.683 0 0 0-.002-2.224l1.372-.793c.476-.275.641-.89.365-1.366l-2-3.46a1 1 0 0 0-1.366-.365l-1.396.806A8.034 8.034 0 0 0 15 4.598V3a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1.598A8.094 8.094 0 0 0 7.105 5.71L5.71 4.904a.999.999 0 0 0-1.366.365l-2 3.46a1.004 1.004 0 0 0 .365 1.366l1.372.793a7.683 7.683 0 0 0 0 2.224l-1.372.793c-.476.275-.641.89-.365 1.366zM12 8c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4 1.794-4 4-4z"></path>
                    </svg>
                  </div>
                </Link> */}
              </aside>
            )}

            {/* // )} */}

            <main
              className={style.d_main}
              style={{
                background: context === "dark" ? "#242424" : "#ffffff",
              }}
            >
              {activeSection === "dashboard" && <CodeRoomHome />}
              <CodeRoomChannel
                userId={userDetails.uuid}
                setIncomingMessage={setIncomingMessage}
                userDetails={userDetails}
              />
            </main>

            {windowDimensions.width >= 1024 && (
              <aside
                className={style.d_right_aside}
                style={{
                  background: context === "dark" ? "#1E1E1E" : "#f1f1f1",
                }}
              >
                <div className={style.right_aside_div}></div>
              </aside>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default CodeRoom;

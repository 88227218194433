import { useState, useEffect, useRef } from "react";
import { FaMicrophone } from "react-icons/fa";
import MicRecorder from "mic-recorder";
import { getTextFromSpeech } from "../../api/speect-to-text";
import ClipLoader from "react-spinners/ClipLoader";

/**
 * @param setText() the function will get an array of words;
 */

const _recorder = new MicRecorder({
  bitRate: 224,
  encoder: "wav",
});
export function VoiceToText({ setText }) {
  const [recording, setRecording] = useState(false);
  const [recorder, _setRecorder] = useState(_recorder);
  const [loading, setLoading] = useState(false);

  async function start() {
    // setting loader and recording to true, doesn't matter if the user granted the permissions or not.
    setLoading(true);
    setRecording(true);
    try {
      // Awaiting the start method. If the promise rejected. Then the catch block will be executed.
      // recording and loader will be set to false.
      const mediaStream = await recorder.start(); //start recording.
      //("Starting");
    } catch (error) {
      // Show the error to the user.
      //("Unable to start the recording.", error);
      setRecording(false);
      setLoading(false);
      setRecording(false);
      setLoading(false);
    } finally {
    }
  }

  async function stop() {
    //("Stop");

    try {
      const [buffer, blob] = await recorder.stop().getAudio();
      setRecording(false);
      //("Blob Type", blob.type);
      const file = new File([buffer], "recording.wav", {
        type: blob.type,
        lastModified: Date.now(),
      });
      const formData = new FormData();
      //("BLOB", file);
      formData.append("file", blob, Date.now() + "-" + file.name);
      const response = await getTextFromSpeech(formData);
      setText(response);
      //(response);
    } catch (err) {
      //(err);
    } finally {
      setLoading(false);
      setRecording(false);
    }
  }
  return (
    <div className="tw-flex tw-items-center">
      {recording ? (
        <div onClick={stop} className="tw-flex tw-items-center">
          <div className="tw-relative ">
            <FaMicrophone className="tw-h-7 tw-w-5 tw-mx-3 tw-cursor-pointer tw-text-black" />
            <span className="tw-animate-ping tw-right-1 tw-bottom-0  tw-absolute tw-inline-flex tw-h-2 tw-w-2 tw-rounded-full tw-bg-blue-600 tw-opacity-90" />
          </div>
        </div>
      ) : (
        <div onClick={start} className="tw-flex tw-items-center">
          <FaMicrophone className="tw-h-7 tw-w-5 tw-mx-3 tw-cursor-pointer tw-text-black" />
        </div>
      )}
      <ClipLoader color="red" loading={loading} />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import ChartType from "../component/charts/chartType";
import { useLocation } from "react-router";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import {
  dailyData,
  weeklyData,
  monthlyData,
  totalDailyToken,
  totalWeeklyToken,
  totalMonthlyToken,
} from ".././component/helpers/fakeChatData";
import axios from "../utils/axiosInstance";
import Cookies from "js-cookie";

const Usage = () => {
  const [dailyData, setDailyData] = useState([]);
  const [weeklyData, setWeeklyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [totalDailyToken, setTotalDailyToken] = useState({});
  const [totalWeeklyToken, setTotalWeeklyToken] = useState({});
  const [totalMonthlyToken, setTotalMonthlyToken] = useState({});
  const [chartTypeDaily, setDailyChartType] = useState("Bar");
  const [chartTypeWeekly, setWeeklyChartType] = useState("Bar");
  const [chartTypeMonthly, setMonthlyChartType] = useState("Bar");
  const location = useLocation();

  const [usageData, setUsageData] = useState({
    labels: dailyData.map((data) => data.createdTime),
    datasets: [
      {
        label: "Prompt Tokens",
        data: dailyData.map((data) => data.prompt_tokens),
        backgroundColor: "rgba(37, 138, 214, 0.8)",
      },
      {
        label: "Completion Tokens",
        data: dailyData.map((data) => data.completion_tokens),
        backgroundColor: "rgba(246, 174, 85, 0.8)",
      },
      {
        label: "Total Tokens",
        data: dailyData.map((data) => data.total_tokens),
        backgroundColor: "rgba(205, 41, 126, 0.8)",
      },
    ],
  });
  const [weeklyUsageData, setWeeklyUsageData] = useState({
    labels: weeklyData.map((data) => data.createdDate),
    datasets: [
      {
        label: "Prompt Tokens",
        data: weeklyData.map((data) => data.prompt_tokens),
        backgroundColor: "rgba(37, 138, 214, 0.8)",
      },
      {
        label: "Completion Tokens",
        data: weeklyData.map((data) => data.completion_tokens),
        backgroundColor: "rgba(246, 174, 85, 0.8)",
      },
      {
        label: "Total Tokens",
        data: weeklyData.map((data) => data.total_tokens),
        backgroundColor: "rgba(205, 41, 126, 0.8)",
      },
    ],
  });
  const [monthlyUsageData, setMonthlyUsageData] = useState({
    labels: monthlyData.map((data) => data.createdDate),
    datasets: [
      {
        label: "Prompt Tokens",
        data: monthlyData.map((data) => data.prompt_tokens),
        backgroundColor: "rgba(37, 138, 214, 0.8)",
      },
      {
        label: "Completion Tokens",
        data: monthlyData.map((data) => data.completion_tokens),
        backgroundColor: "rgba(246, 174, 85, 0.8)",
      },
      {
        label: "Total Tokens",
        data: monthlyData.map((data) => data.total_tokens),
        backgroundColor: "rgba(205, 41, 126, 0.8)",
      },
    ],
  });

  useEffect(() => {
    const token = Cookies.get("token");

    axios
      .get("usageDetails/daily", {
        headers: { Authorization: "Bearer " + token },
      })
      .then(({ data }) => {
        console.log(data.dailyTokens);
        setDailyData(data.dailyTokens);
        setTotalDailyToken(data.totalDailyTokens);
      });

    axios
      .get("usageDetails/weekly", {
        headers: { Authorization: "Bearer " + token },
      })
      .then(({ data }) => {
        setWeeklyData(data.weeklyTokens);
        setTotalWeeklyToken(data.totalWeeklyTokens);
      });

    axios
      .get("usageDetails/monthly", {
        headers: { Authorization: "Bearer " + token },
      })
      .then(({ data }) => {
        setMonthlyData(data.monthlyTokens);
        setTotalMonthlyToken(data.totalMonthlyTokens);
      });
  }, []);

  useEffect(() => {
    // Update chartData when dailyData, weeklyData, or monthlyData change
    setUsageData({
      labels: dailyData.map((data) => data.createdDate),
      datasets: [
        {
          label: "Prompt Tokens",
          data: dailyData.map((data) => data.prompt_tokens),
          backgroundColor: "rgba(37, 138, 214, 0.8)",
        },
        {
          label: "Completion Tokens",
          data: dailyData.map((data) => data.completion_tokens),
          backgroundColor: "rgba(246, 174, 85, 0.8)",
        },
        {
          label: "Total Tokens",
          data: dailyData.map((data) => data.total_tokens),
          backgroundColor: "rgba(205, 41, 126, 0.8)",
        },
      ],
    });

    setWeeklyUsageData({
      labels: weeklyData.map((data) => data.createdDate),
      datasets: [
        {
          label: "Prompt Tokens",
          data: weeklyData.map((data) => data.prompt_tokens),
          backgroundColor: "rgba(37, 138, 214, 0.8)",
        },
        {
          label: "Completion Tokens",
          data: weeklyData.map((data) => data.completion_tokens),
          backgroundColor: "rgba(246, 174, 85, 0.8)",
        },
        {
          label: "Total Tokens",
          data: weeklyData.map((data) => data.total_tokens),
          backgroundColor: "rgba(205, 41, 126, 0.8)",
        },
      ],
    });
    setMonthlyUsageData({
      labels: monthlyData.map((data) => data.createdDate),
      datasets: [
        {
          label: "Prompt Tokens",
          data: monthlyData.map((data) => data.prompt_tokens),
          backgroundColor: "rgba(37, 138, 214, 0.8)",
        },
        {
          label: "Completion Tokens",
          data: monthlyData.map((data) => data.completion_tokens),
          backgroundColor: "rgba(246, 174, 85, 0.8)",
        },
        {
          label: "Total Tokens",
          data: monthlyData.map((data) => data.total_tokens),
          backgroundColor: "rgba(205, 41, 126, 0.8)",
        },
      ],
    });
  }, [dailyData, weeklyData, monthlyData]);

  //daily chart
  const optionsHour = {
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            unit: "day",
            unitStepSize: 1,
            displayFormats: {
              day: "MMM D",
            },
          },
        },
      ],
      yAxes: [
        {
          beginAtZero: true,
        },
      ],
    },
  };

  //monthly
  const options = {
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            unit: "month",
            unitStepSize: 1,
            displayFormats: {
              month: "MMM YYYY",
            },
          },
        },
      ],
      yAxes: [
        {
          beginAtZero: true,
        },
      ],
    },
  };
  //weekly
  const optionsWeek = {
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            parser: "MMM DD",
            tooltipFormat: "MMM DD",
            unit: "week",
            unitStepSize: 1,
            displayFormats: {
              week: "MMM D",
            },
            timezone: "Asia/Dhaka",
          },
        },
      ],
      yAxes: [
        {
          beginAtZero: true,
        },
      ],
    },
  };

  useEffect(() => {
    ReactGA.event("page_view", { page_title: "Usage Chart Page" });
    ReactGA.send({
      page: location.pathname,
      hitType: "pageview",
      title: "Usage Chart Page",
    });
  }, []);

  useEffect(() => {
    try {
      mixpanel.track("Usage Charts Page", {
        "Daily Chart Type": chartTypeDaily,
        "Weekly Chart Type": chartTypeWeekly,
        "Monthly Chart Type": chartTypeMonthly,
      });
    } catch (error) {
      console.log("Mixpanel Error : ", error);
    }
  }, [chartTypeDaily, chartTypeMonthly, chartTypeWeekly]);

  return (
    <React.Fragment>
      <main>
        <section className="py-5 py-lg-10 bg-bg-3 text-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12 col-xl-10 col-xxl-10">
                <h4
                  className="display-5 mb-6"
                  data-aos="fade-down"
                  data-aos-delay="0"
                  style={{ paddingLeft: "1rem" }}
                >
                  {" "}
                  Daily Usage Chart
                </h4>
                <p>Select Chart Type</p>
                <select
                  className="mb-15 fs-1 text-bg-1"
                  data-aos="fade-down"
                  data-aos-delay="150"
                  name="chartType"
                  onChange={(event) => {
                    setDailyChartType(event.target.value);
                  }}
                >
                  <option value={"Bar"}>Bar</option>
                  <option value={"Line"}>Line</option>
                  <option value={"Pie"}>Pie</option>
                </select>
                <div className="row justify-content-center">
                  <div className="col-4 text-center">
                    <h5 className="mb-0 font-size-20">
                      {totalDailyToken.prompt_tokens}
                    </h5>
                    <p className="text-muted">Prompt Tokens</p>
                  </div>
                  <div className="col-4 text-center">
                    <h5 className="mb-0 font-size-20">
                      {totalDailyToken.completion_tokens}
                    </h5>
                    <p className="text-muted">Completion Tokens</p>
                  </div>
                  <div className="col-4 text-center">
                    <h5 className="mb-0 font-size-20">
                      {totalDailyToken.total_tokens}
                    </h5>
                    <p className="text-muted">Total Tokens</p>
                  </div>
                </div>
                <ChartType
                  chartType={chartTypeDaily}
                  chartData={usageData}
                  options={optionsHour}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="py-5 py-lg-10 bg-bg-3 text-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12 col-xl-10 col-xxl-10">
                <h4
                  className="display-5 mb-6"
                  data-aos="fade-down"
                  data-aos-delay="0"
                  style={{ paddingLeft: "1rem" }}
                >
                  {" "}
                  Weekly Usage Chart
                </h4>
                <p>Select Chart Type</p>
                <select
                  className="mb-15 fs-1 text-bg-1"
                  data-aos="fade-down"
                  data-aos-delay="150"
                  name="chartType"
                  onChange={(event) => {
                    setWeeklyChartType(event.target.value);
                  }}
                >
                  <option value={"Bar"}>Bar</option>
                  <option value={"Line"}>Line</option>
                  <option value={"Pie"}>Pie</option>
                </select>
                <div className="row justify-content-center">
                  <div className="col-4 text-center">
                    <h5 className="mb-0 font-size-20">
                      {totalWeeklyToken.prompt_tokens}
                    </h5>
                    <p className="text-muted">Prompt Tokens</p>
                  </div>
                  <div className="col-4 text-center">
                    <h5 className="mb-0 font-size-20">
                      {totalWeeklyToken.completion_tokens}
                    </h5>
                    <p className="text-muted">Completion Tokens</p>
                  </div>
                  <div className="col-4 text-center">
                    <h5 className="mb-0 font-size-20">
                      {totalWeeklyToken.total_tokens}
                    </h5>
                    <p className="text-muted">Total Tokens</p>
                  </div>
                </div>
                <ChartType
                  chartType={chartTypeWeekly}
                  chartData={weeklyUsageData}
                  options={optionsWeek}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="py-5 py-lg-10 bg-bg-3 text-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12 col-xl-10 col-xxl-10">
                <h4
                  className="display-5 mb-6"
                  data-aos="fade-down"
                  data-aos-delay="0"
                  style={{ paddingLeft: "1rem" }}
                >
                  {" "}
                  Monthly Usage Chart
                </h4>
                <p>Select Chart Type</p>
                <select
                  className="mb-15 fs-1 text-bg-1"
                  data-aos="fade-down"
                  data-aos-delay="150"
                  name="chartType"
                  onChange={(event) => {
                    setMonthlyChartType(event.target.value);
                  }}
                >
                  <option value={"Bar"}>Bar</option>
                  <option value={"Line"}>Line</option>
                  <option value={"Pie"}>Pie</option>
                </select>
                <div className="row justify-content-center">
                  <div className="col-4 text-center">
                    <h5 className="mb-0 font-size-20">
                      {totalMonthlyToken.prompt_tokens}
                    </h5>
                    <p className="text-muted">Prompt Tokens</p>
                  </div>
                  <div className="col-4 text-center">
                    <h5 className="mb-0 font-size-20">
                      {totalMonthlyToken.completion_tokens}
                    </h5>
                    <p className="text-muted">Completion Tokens</p>
                  </div>
                  <div className="col-4 text-center">
                    <h5 className="mb-0 font-size-20">
                      {totalMonthlyToken.total_tokens}
                    </h5>
                    <p className="text-muted">Total Tokens</p>
                  </div>
                </div>
                <ChartType
                  chartType={chartTypeMonthly}
                  chartData={monthlyUsageData}
                  options={options}
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

export default Usage;

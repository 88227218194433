import React from "react";
import Header from "./parts/Header";
import { useLocation } from "react-router";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import "./parts/BillingPage.css";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  deleteAccount,
} from "../app/features/security/securityService";
import {
  editIsAccountDeleteSuccess,
  editIsSuccessSecurity,
} from "../app/features/security/securitySlice";
import { logout } from "../app/features/auth/authSlice";
import { useNavigate } from "react-router-dom";

const SecurityPage = () => {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.event("page_view", { page_title: "Secuirity Page" });
    ReactGA.send({
      page: location.pathname,
      hitType: "pageview",
      title: "Secuirity Page",
    });

    try {
      mixpanel.track("Secuirity Page");
    } catch (error) {
      console.log("Mixpanel Error : ", error);
    }
  }, []);
  // Navigate react-router-dom
  const navigate = useNavigate();
  // State for managing form inputs
  const [changePasswordObj, setChangePasswordObj] = React.useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  // Redux state
  const dispatch = useDispatch();
  const { loading, isError, message, isSuccess, isAccountDeleteSuccess } =
    useSelector((state) => state.security);
  const { user } = useSelector((state) => state.auth);

  // Redux useEffect
  React.useEffect(() => {
    if (isError) {
      toast.error(message);
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      toast.success(message);
      dispatch(editIsSuccessSecurity(false));

      setChangePasswordObj({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    }
  }, [isSuccess]);

  React.useEffect(() => {
    if (isSuccess) {
      toast.success(message);
      dispatch(editIsSuccessSecurity(false));

      setChangePasswordObj({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    }
  }, [isSuccess]);

  // Delete Account Success
  React.useEffect(() => {
    if (isAccountDeleteSuccess) {
      toast.success(message);
      dispatch(editIsAccountDeleteSuccess(false));
      dispatch(logout());
      setTimeout(() => {
        navigate("/login");
      }, 500);
    }
  }, [isAccountDeleteSuccess]);

  // On Change Password Form Function
  const onChangePasswordForm = (e) => {
    setChangePasswordObj({
      ...changePasswordObj,
      [e.target.name]: e.target.value,
    });
  };
  // Change Password Submit Function
  const changePasswordSubmitFunc = (e) => {
    e.preventDefault();
    // Validating newPassword and confirmPassword are same or not
    if (changePasswordObj.newPassword !== changePasswordObj.confirmPassword) {
      return toast.error("New Password and Confirm Password do not match");
    }
    // Calling api here
    dispatch(changePassword(changePasswordObj));
  };
  // Delete My Account Function
  const deleteMyAccountFunc = (e) => {
    // Calling api here
    dispatch(deleteAccount(user.uuid));
  };

  return (
    <div className="container-xl px-4 mt-4">
      <Header />
      <hr className="mt-0 mb-4" />
      <div className="row">
        <div className="col-lg-8">
          {/* <!-- Change password card--> */}
          <div className="card mb-4">
            <div className="card-header">Change Password</div>
            <div className="card-body">
              <form onSubmit={changePasswordSubmitFunc}>
                {/* <!-- Form Group (current password)--> */}
                <div className="mb-3">
                  <label className="small mb-1" for="currentPassword">
                    Current Password
                  </label>
                  <input
                    className="form-control"
                    id="currentPassword"
                    type="password"
                    name="oldPassword"
                    value={changePasswordObj.oldPassword}
                    onChange={onChangePasswordForm}
                    placeholder="Enter current password"
                    required={true}
                  />
                </div>
                {/* <!-- Form Group (new password)--> */}
                <div className="mb-3">
                  <label className="small mb-1" for="newPassword">
                    New Password
                  </label>
                  <input
                    className="form-control"
                    id="newPassword"
                    type="password"
                    name="newPassword"
                    value={changePasswordObj.newPassword}
                    onChange={onChangePasswordForm}
                    placeholder="Enter new password"
                    required={true}
                  />
                </div>
                {/* <!-- Form Group (confirm password)--> */}
                <div className="mb-3">
                  <label className="small mb-1" for="confirmPassword">
                    Confirm Password
                  </label>
                  <input
                    className="form-control"
                    id="confirmPassword"
                    type="password"
                    name="confirmPassword"
                    value={changePasswordObj.confirmPassword}
                    onChange={onChangePasswordForm}
                    placeholder="Confirm new password"
                    required={true}
                  />
                </div>
                <button className="btn btn-primary black-btn" type="submit">
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          {/* <!-- Delete account card--> */}
          <div className="card mb-4">
            <div className="card-header">Delete Account</div>
            <div className="card-body">
              <p>
                Deleting your account is a permanent action and cannot be
                undone. If you are sure you want to delete your account, select
                the button below.
              </p>
              <button
                className="btn btn-danger-soft text-danger black-btn"
                type="button"
                onClick={deleteMyAccountFunc}
              >
                I understand, delete my account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SecurityPage;

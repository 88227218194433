let authTok =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiN2U3MmRlNmEtN2QyMS00OTNhLWI5YzItMGJkNGM1N2MwNjM0IiwiaWF0IjoxNjg1MTI1MjY2LCJleHAiOjE2ODUzODQ0NjZ9.x69wZLbamrCj05wIGeW-Bljr3Dh1EyPrde0j63tUVss";

export const loginResponse = {
  status: "success",
  message: "Logged In successfully!!",
  token: authTok,
  data: {
    user: {
      uuid: "7e72de6a-7d21-493a-b9c2-0bd4c57c0634",
      firstName: "Fai",
      lastName: "codex",
      email: "sluu@gmail.com",
      token: 0,
      startTokenAt: null,
      pemiumToken: 0,
      profilePic: null,
      planId: "10",
      planName: "enterprise",
      customPlan: false,
      isPaid: false,
      paidPlan: "Basic",
      isSubscribed: false,
      subscribedPlan: "Basic",
      delinquent: false,
    },
  },
};

export const loginSubUSerResponse = {
  status: "success",
  message: "Logged In successfully!!",
  token: authTok,
  data: {
    subuser: {
      suid: "f3db717d-594b-49fe-80d3-7e4cff10f4a6",
      userId: "7e72de6a-7d21-493a-b9c2-0bd4c57c0637",
      firstName: "Malik",
      lastName: "fai",
      email: "lia@breazeim.com",
      profilePic: null,
      planId: null,
      planName: "Basic",
      customPlan: false,
      isPaid: false,
      paidPlan: "Basic",
    },
  },
};

export const getAuthToken = () => {
  let token = "Bearer " + loginResponse.token;
  return token;
};
export const getUserInfo = () => {
  let response = localStorage.getItem("loginResponse")
    ? JSON.parse(localStorage.getItem("loginResponse"))
    : loginResponse.data;
  return response;
};

// export const socketConnect = "https://sluucorp-backend.onrender.com";
export const socketConnect = "https://deepturingai.com";

// export const socketConnect='http://localhost:3080'

import React from "react";
import logo from "../img/logo.png";
import { Link } from "react-router-dom";
import "./mainFooter.css";

const Footer2 = () => {
  return (
    <>
      <footer className="pb-12 bg-bg-1" style={{ paddingTop: "4rem" }}>
        <div className="container">
          <div className="row">
            <div
              className="mb-8 mb-md-0 col-lg-3 col-xl-3"
              data-aos="fade-down"
              data-aos-delay="0"
            >
              <a className="mb-4">
                <Link to="/comingsoon">
                  <img
                    src={logo}
                    //   srcset="
                    //   pub/185029/ea555aaade9698f7280435fa483018d0/uploads/c980d9a810fd4f169933512f9b8296a3.png 2x
                    // "
                    alt=""
                    className="img-fluid"
                    width={100}
                  />
                </Link>
              </a>
              <p className="fs-6  text-light-2">
                The World's best AI for your programming needs, focus more on
                deployment and less on typing
              </p>
            </div>
            <div
              className="mb-sm-8 mb-md-0 col-lg-2 col-md-3 col-sm-6"
              data-aos="fade-down"
              data-aos-delay="150"
            >
              <h5 className="mb-4 text-light-1 ">Products</h5>
              <a
                href="#"
                className="mb-4 me-2 me-sm-0 d-sm-block text-light-1 text-small disabled"
              >
                DeepTuring Sandbox
              </a>
              <a
                href="#"
                className="mb-4 me-2 me-sm-0 d-sm-block text-light-1 text-small disabled"
              >
                DeepTuring Ai Playground
              </a>
              <a
                href="#"
                className="mb-4 me-2 me-sm-0 d-sm-block text-light-1 text-small disabled"
              >
                DeepTuring IDE Plugins
              </a>
              <a
                href="#"
                className="mb-4 me-2 me-sm-0 d-sm-block text-light-1 text-small disabled"
              >
                DeepTuring Coderoom
              </a>
            </div>
            <div
              className="mb-sm-8 mb-md-0 col-lg-2 col-md-3 col-sm-6"
              data-aos="fade-down"
              data-aos-delay="250"
            >
              <h5 className="mb-4 text-light-1 ">Integrations</h5>
              <Link
                to="ide"
                className="mb-4 me-2 me-sm-0 d-sm-block text-light-1 text-small"
              >
                List of Supported IDE
              </Link>
            </div>
            <div
              className="col-lg-2 col-md-3 col-sm-6"
              data-aos="fade-down"
              data-aos-delay="400"
            >
              <h5 className="mb-4 text-light-1 ">Resources</h5>
              <Link to="/about">
                <a
                  href="#"
                  className="mb-4 me-2 me-sm-0 d-sm-block text-light-1 text-small"
                >
                  About
                </a>
              </Link>
              <Link
                to="comingsoon"
                className="mb-4 me-2 me-sm-0 d-sm-block text-light-1 text-small"
              >
                Blog
              </Link>
              <Link
                to="/privacy"
                className="mb-4 me-2 me-sm-0 d-sm-block text-light-1 text-small"
              >
                Privacy
              </Link>
              <Link
                to="/tos"
                className="mb-4 me-2 me-sm-0 d-sm-block text-light-1 text-small"
              >
                Terms of Service
              </Link>
            </div>
            <div
              className="col-lg-2 col-md-3 col-sm-6"
              data-aos="fade-down"
              data-aos-delay="500"
            >
              <h5 className="mb-4 text-light-1 ">Contact</h5>
              <a
                href="mailto:support@deepturingai.com"
                className="mb-4 me-2 me-sm-0 d-block text-action-1 fw-bold text-light-1  fs-5"
              >
                support@deepturingai.com
              </a>
            </div>
          </div>
          <p
            className="mb-0  mt-66 text-light-2 fs-5"
            style={{ color: "white", marginTop: "4.2rem" }}
            //data-aos='fade-down'
          >
            Copyright © 2024 Sluu Corporation Ltd. All Rights Reserved
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer2;

import React from "react";
import Modal from "react-bootstrap/Modal";
import { ThemeContext } from "../../utils/ThemeContext";

const LoadingModal = ({ showImageChecking, isGptLoading, initialLoading }) => {
  const { context } = React.useContext(ThemeContext);

  return (
    <Modal
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      show={showImageChecking || isGptLoading || initialLoading}
      centered
    >
      {context === "dark" ? (
        <Modal.Body style={{ backgroundColor: "black" }}>
          <div className="body">
            <div
              className={`loading-cont ${
                isGptLoading || initialLoading ? "loading-cont-gpt" : null
              }`}
            >
              <div className="cont">
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <div
            className="body1"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className={`loading-cont1 ${
                isGptLoading || initialLoading ? "loading-cont-gpt" : null
              }`}
            >
              <div className="cont1">
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default LoadingModal;

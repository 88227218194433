import React, { useEffect, useState } from "react";
import { getUser, referSubuser } from "../../Service/subuserServices";
import { getUserInfo } from "../../Service/testLogin";
import { useLocation } from "react-router";
import ReactGA from "react-ga4";
import { Spinner } from "react-bootstrap";
import { useTeam } from "../../context/TeamProvider";
import mixpanel from "mixpanel-browser";

const AddUser = () => {
  const [inputs, setInputs] = useState(1);
  const [showInput, setShowInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [fetchUserLoading, setFetchUserLoading] = useState(true);
  const [fetchUserError, setFetchUserError] = useState(false);
  const [email, setEmail] = useState("");
  const [subUsers, setSubUsers] = useState([]);
  const { teamId, isEnterprise } = useTeam();
  const location = useLocation();

  const handleSubmit = async () => {
    setLoading(true);
    try {
      let response = await referSubuser(email);
      if (response.data.status === "success") {
        setSuccess(true);
        try {
          mixpanel.track("Add User in Team Chat", {
            Email: email,
            Status: "SUCCESS",
          });
        } catch (error) {
          console.log("Mixpanel Error : ", error);
        }
      }
    } catch (error) {
      setError(true);
      mixpanel.track("Add User in Team Chat Fail", {
        Email: email,
        Status: "FAIL",
      });
      console.log("Error", error);
    }

    setLoading(false);
    setEmail("");
  };
  const handleInputChange = (event) => {
    // const { name, value } = event.target;
    // setUserInput((prevState) => ({ ...prevState, [name]: value }));
    const { value } = event.target;
    setEmail(value);
  };

  const handleGetUser = async (id) => {
    setFetchUserLoading(true);
    try {
      let response = await getUser(id);
      setSubUsers(response.data.data.user.subsers);
      setFetchUserLoading(false);
      // //(response.data.data.user.subsers);
    } catch (error) {
      setFetchUserLoading(false);
      setFetchUserError(true);
      //("Error", error);
    }
  };

  useEffect(() => {
    if (!teamId) {
      return;
    }
    handleGetUser(teamId);
  }, [teamId]);

  useEffect(() => {
    ReactGA.event("page_view", { page_title: "Add User Page" });
    ReactGA.send({
      page: location.pathname,
      hitType: "pageview",
      title: "Add User Page",
    });
  }, []);

  return (
    <>
      <section>
        <hr />
        <h5 style={{ marginLeft: "0rem" }}>
          {isEnterprise
            ? "Add other users to your account"
            : "Other users in the account"}
        </h5>
        <div id="form-placeholder"></div>
        {isEnterprise && (
          <>
            {showInput &&
              (success || error ? (
                <>
                  <div className="tw-text-2xl tw-text-center tw-p-4">
                    {success && "Invite Send Successfully!"}
                    {error && "Error Sending Email"}
                  </div>
                  <BtnGroup
                    firstBtnClick={() => {
                      setSuccess(false);
                      setShowInput(false);
                      setError(false);
                    }}
                    secondBtnClick={() => {
                      setSuccess(false);
                      setError(false);
                    }}
                    spinnerShow={""}
                    firstBtnText="Go Back!"
                    secondtBtnText="Invite Another User"
                  />
                </>
              ) : (
                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-3">
                  {[...Array(inputs)].map((index) => {
                    return (
                      <>
                        <div className="form-group" style={{ width: "100%" }}>
                          <input
                            name="email"
                            value={email}
                            onChange={handleInputChange}
                            type="text"
                            className="form-control"
                            placeholder="name@example.com"
                            style={{ margin: "1rem auto", width: "100%" }}
                          />
                        </div>
                        <BtnGroup
                          firstBtnClick={handleSubmit}
                          secondBtnClick={() => {
                            setEmail("");
                            setShowInput(false);
                          }}
                          spinnerShow={loading}
                          cssClass="md:tw-justify-start"
                          firstBtnText="Proceed"
                          secondtBtnText="Cancel"
                        />
                      </>
                    );
                  })}
                </div>
              ))}
            {!showInput && (
              <button
                id="btn-add"
                onClick={() => {
                  setShowInput(true);
                }}
                type="button"
                // className="btn btn-primary"
                className="tw-bg-white tw-text-gray-600 tw-py-2 tw-text-lg tw-font-bold tw-outline-none tw-border-0"
              >
                Add
              </button>
            )}
          </>
        )}

        <hr />
        <div className="">
          <ul className="tw-flex tw-items-center tw-justify-center tw-h-full tw-flex-col">
            {fetchUserLoading ? (
              <div className="tw-mt-3">
                <Spinner />
              </div>
            ) : fetchUserError ? (
              <div className="tw-mt-7">
                <p>You'll need an enterprise account to access this feature</p>
              </div>
            ) : (
              subUsers.map((user, index) => {
                return (
                  <li
                    style={{ border: "1px solid #888888", width: "20rem" }}
                    key={index}
                    className="tw-flex tw-flex-col tw-justify-center tw-rounded-md tw-shadow-md tw-p-2 tw-px-10 tw-mb-3"
                  >
                    <div className="tw-break-all">
                      {user.firstName + " " + user.lastName}
                    </div>
                    <div className="tw-break-all">{user.email}</div>
                  </li>
                );
              })
            )}
          </ul>
        </div>
      </section>
    </>
  );
};

export default AddUser;

function BtnGroup(props) {
  const {
    firstBtnClick,
    secondBtnClick,
    spinnerShow,
    cssClass,
    firstBtnText,
    secondtBtnText,
  } = props;
  return (
    <div className={`tw-flex tw-justify-center tw-min-w-full ${cssClass}`}>
      <button
        id="btn-add"
        style={{
          fontSize: "1rem",
          padding: "0.6rem 0 0.6rem 1.6rem",
          backgroundColor: "#00e500",
          color: "white",
          borderRadius: "0.4rem",
          marginRight: "1rem",
          width: "9rem",
        }}
        type="button"
        className="btn btn-primary"
        onClick={firstBtnClick}
      >
        {firstBtnText}
        {spinnerShow ? (
          <Spinner size="sm" className="ms-1" />
        ) : (
          <div
            style={{
              width: "1.25rem",
              display: "inline-block",
              color: "#00e500",
            }}
          >
            1
          </div>
        )}
      </button>
      <button
        id="btn-add"
        style={{
          fontSize: "1rem",
          padding: "0.6rem",
          borderRadius: "0.4rem",
          backgroundColor: "red",
          color: "white",
          minWidth: "9rem",
        }}
        type="button"
        className="btn btn-primary"
        onClick={secondBtnClick}
      >
        {secondtBtnText}
      </button>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import ChatFooter from "./ChatFooter";
import ChatMessages from "./ChatMessages";
import ChatHeader from "./ChatHeader";
import io from "socket.io-client";
import { socketConnect } from "../../Service/testLogin";

const GroupInbox = ({
  setPreviewToggle,
  teamProfile,
  // teamId,
  loginUser,
  room,
}) => {
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState("");
  const [messageReceived, setMessageReceived] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [typingIndicator, setTypingIndicator] = useState("");
  const [multipleUSers, setMultipleUSers] = useState([]);

  const getMemberName = () => {
    return [
      { id: 0, name: teamProfile.name },
      ...teamProfile.members.map((member) => {
        return {
          id: member.id,
          name: member.firstName + " " + member.lastName,
        };
      }),
    ];
  };

  useEffect(() => {
    const socket = io.connect(socketConnect);
    setSocket(socket);
    socket.emit("join_room", { room: room, userId: loginUser });
    return () => {
      socket.disconnect();
      setSocket(null);
    };
  }, []);

  useEffect(() => {
    if (socket === null) {
      return;
    }
    socket.on("receive_message", (data) => {
      if (data.room != room) {
        //("Should not set G Msg");
      }
      setMessageReceived(data.chat);
    });
    socket.on("receive_typing_indicator", (data) => {
      setTypingIndicator(() => {
        return { id: data.id, name: data.name, typing: data.typing };
      });
    });
  }, [socket]);

  useEffect(() => {
    if (messageReceived === "") {
      return;
    }

    setMessageList((prev) => {
      return [messageReceived, ...prev];
    });
    setMessageReceived("");
  }, [messageReceived]);

  useEffect(() => {
    if (typingIndicator.typing) {
      !multipleUSers.includes(typingIndicator.id) &&
        setMultipleUSers((prev) => {
          return [...prev, typingIndicator.id];
        });
    } else {
      setMultipleUSers((prev) => {
        return prev.filter((id) => {
          return id !== typingIndicator.id;
        });
      });
    }
  }, [typingIndicator]);

  return (
    <div
      className={`tw-flex tw-h-full lg:tw-col-span-4 tw-col-span-full lg:tw-flex tw-flex-col tw-justify-between tw-drop-shadow-lg tw-rounded-lg tw-shadow-lg tw-shadow-slate-400`}
    >
      <ChatHeader
        loading={false}
        handleBackArrow={() => {
          setPreviewToggle(true);
        }}
        groupChat={true}
        receiverProfile={teamProfile}
        typingIndicator={typingIndicator}
        multipleUSers={multipleUSers}
      />

      <ChatMessages
        clientMessages={messageList}
        setClientMessages={setMessageList}
        loginUser={loginUser}
        memberNames={getMemberName}
        groupChat={true}
        room={room}
      />
      <ChatFooter
        message={message}
        setMessage={setMessage}
        loginUser={loginUser}
        setMessageList={setMessageList}
        room={room}
        socket={socket}
        memberNames={getMemberName}
        groupChat={true}
        memberCount={teamProfile.members.length}
      />
    </div>
  );
};

export default GroupInbox;

import React from "react";
import { useLocation } from "react-router";
import Header from "./parts/Header";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import person from "./img/person.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { editProfile } from "../app/features/profile/profileService";
import { editIsSuccess } from "../app/features/profile/profileSlice";
import { addUser } from "../app/features/auth/authSlice";
import "./parts/BillingPage.css";

const EditProfile = () => {
  const [birthday, setBirthday] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [imagePreview, setImagePreview] = React.useState(null);
  // Redux
  const dispatch = useDispatch();
  const location = useLocation();
  // Get User from State
  const { user } = useSelector((state) => state.auth);
  const { loading, isError, message, isSuccess, updatedUser } = useSelector(
    (state) => state.profile
  );
  // Edit Profile input state
  const [editProfileObj, setEditProfileObj] = React.useState({
    firstName: "",
    lastName: "",
  });

  React.useEffect(() => {
    ReactGA.event("page_view", { page_title: "Edit Profile Page" });
    ReactGA.send({
      page: location.pathname,
      hitType: "pageview",
      title: "Edit Profile Page",
    });

    try {
      mixpanel.track("Edit Profile Page");
    } catch (error) {
      console.log("Mixpanel Error : ", error);
    }
  }, []);

  // Redux useEffect
  React.useEffect(() => {
    if (isError) {
      toast.error(message);
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      toast.success(message);
      dispatch(editIsSuccess(false));
      if (updatedUser) {
        dispatch(addUser(updatedUser));
      }
      // remaining functionality here
    }
  }, [isSuccess]);
  // Create a ref for the file input
  const fileInputRef = React.createRef();

  // Function to handle file input change
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    // Check if a file was selected
    if (!file) {
      return;
    }

    // Check file type (JPG or PNG)
    if (
      !file.type.includes("jpeg") &&
      !file.type.includes("png") &&
      !file.type.includes("jpg")
    ) {
      toast.error("Please select a JPG, JPEG or PNG image.");
      return;
    }

    // Check file size (5 MB limit)
    if (file.size > 5 * 1024 * 1024) {
      toast.error("File size exceeds the 5 MB limit.");
      return;
    }

    // If all checks pass, set the selected file
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };
  // Edit OnChange Function
  const editOnChangeFunc = (e) => {
    setEditProfileObj({
      ...editProfileObj,
      [e.target.name]: e.target.value,
    });
  };

  const handleBirthdayChange = (date) => {
    setBirthday(date);
  };

  const handleUploadButtonClick = () => {
    // Trigger the hidden file input element
    fileInputRef.current.click();
  };

  // For setting user FirstName and LastName
  React.useEffect(() => {
    if (user) {
      setEditProfileObj({
        ...editProfileObj,
        firstName: user.firstName ? user.firstName : "",
        lastName: user.lastName ? user.lastName : "",
      });
    }

    return () => {};
  }, [user]);

  // Submit Form Function to update Profile
  const editProfileSubmitFunc = async (e) => {
    e.preventDefault();
    dispatch(
      editProfile({ userUuid: user.uuid, editProfileObj, file: selectedFile })
    );
  };

  return (
    <>
      <div className="container-xl px-4 mt-4">
        <Header />
        <hr className="mt-0 mb-4" />
        <div className="row">
          <div className="col-xl-4">
            {/* <!-- Profile picture card--> */}
            <div className="card mb-4 mb-xl-0">
              <div className="card-header">Profile Picture</div>
              <div className="card-body text-center">
                {/* <!-- Profile picture image--> */}
                <img
                  style={{ maxWidth: "8rem", objectFit: "cover" }}
                  className="img-account-profile rounded-circle mb-2"
                  onError={person}
                  // src="http://bootdey.com/img/Content/avatar/avatar1.png"
                  src={
                    imagePreview
                      ? imagePreview
                      : user && user.profilePic
                      ? user.profilePic
                      : person
                  }
                  alt=""
                />
                {/* <!-- Profile picture help block--> */}
                <div className="small font-italic text-muted mb-4">
                  JPG or PNG no larger than 5 MB
                </div>
                {/* <!-- Profile picture upload button--> */}
                {/* Hidden file input */}
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                />
                <button
                  className="btn btn-primary black-btn"
                  onClick={handleUploadButtonClick}
                  type="button"
                >
                  Upload new image
                </button>
              </div>
            </div>
          </div>
          <div className="col-xl-8">
            {/* <!-- Account details card--> */}
            <div className="card mb-4">
              <div className="card-header">Account Details</div>
              <div className="card-body">
                <form onSubmit={editProfileSubmitFunc}>
                  {/* <!-- Form Row--> */}
                  <div className="row gx-3 mb-3">
                    {/* <!-- Form Group (first name)--> */}
                    <div className="col-md-6">
                      <label className="small mb-1" for="inputFirstName">
                        First name
                      </label>
                      <input
                        className="form-control"
                        id="inputFirstName"
                        type="text"
                        placeholder="Enter your first name"
                        // value="Valerie"
                        name="firstName"
                        value={editProfileObj.firstName}
                        onChange={editOnChangeFunc}
                        required={true}
                      />
                    </div>
                    {/* <!-- Form Group (last name)--> */}
                    <div className="col-md-6">
                      <label className="small mb-1" for="inputLastName">
                        Last name
                      </label>
                      <input
                        className="form-control"
                        id="inputLastName"
                        type="text"
                        placeholder="Enter your last name"
                        // value="Luna"
                        name="lastName"
                        value={editProfileObj.lastName}
                        onChange={editOnChangeFunc}
                        required={true}
                      />
                    </div>
                  </div>
                  {/* <!-- Form Row        --> */}
                  {/* <div className="row gx-3 mb-3"> */}
                  {/* <!-- Form Group (organization name)--> */}
                  {/* <div className="col-md-6">
                      <label className="small mb-1" for="inputOrgName">
                        Organization name
                      </label>
                      <input
                        className="form-control"
                        id="inputOrgName"
                        type="text"
                        placeholder="Enter your organization name"
                        value={currentUser ? currentUser.organizationName : ""}
                      />
                    </div> */}
                  {/* <!-- Form Group (location)--> */}
                  {/* <div className="col-md-6">
                      <label className="small mb-1" for="inputLocation">
                        Location
                      </label>
                      <input
                        className="form-control"
                        id="inputLocation"
                        type="text"
                        placeholder="Enter your location"
                        value={currentUser ? currentUser.location : ""}
                      />
                    </div>
                  */}
                  {/* </div> */}
                  {/* <!-- Form Group (email address)--> */}
                  <div className="mb-3">
                    <label className="small mb-1" for="inputEmailAddress">
                      Email address
                    </label>
                    <input
                      className="form-control"
                      id="inputEmailAddress"
                      type="email"
                      readOnly={true}
                      placeholder="Enter your email address"
                      value={
                        user && user.email ? user.email : "name@example.com"
                      }
                    />
                  </div>
                  {/* <!-- Form Row--> */}
                  {/* <div className="row gx-3 mb-3"> */}
                  {/* <!-- Form Group (birthday)--> */}
                  {/* <div className="col-md-6">
                      <label className="small mb-1" htmlFor="birthday">
                        Date of Birth
                      </label>
                      <DatePicker
                        id="birthday"
                        selected={birthday}
                        onChange={handleBirthdayChange}
                        // readOnly={true}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="Select a date"
                      />
                    </div> */}
                  {/* </div>  */}
                  {/* <!-- Save changes button--> */}
                  <button className="btn btn-primary black-btn" type="submit">
                    Save changes
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;

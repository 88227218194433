import "./teams.css";

import React from "react";
import TeamTab from "../component/parts/TeamTab";

const Teams = ({ children }) => {
  return (
    <div className="container-xl px-4 tw--mt-24">
      <main>
        <TeamTab />
        <hr className="mt-0 mb-4" />
        {children}
      </main>
    </div>
  );
};

export default Teams;

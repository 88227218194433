import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import style from "./pricing.module.css";
import { useDispatch, useSelector } from "react-redux";
import { selectPlanAction } from "../../app/features/plans/planSlices";
import { fetchPlans } from "../../app/features/plans/planSlices";
import NotFound from "../../component/NotfoundPage/Notfound";
import Loader from "./Loader";

const Pricing = ({ handlePlanBtnClick }) => {
  const [active, setActive] = useState(1);
  let history = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPlans());
  }, []);

  const planObj = {
    Basic: ["BASIC_TIER", "Basic Plan"],
    Pro: ["PRO_TIER", "Pro Plan"],
    Enterprise: ["ENTERPRISE_TIER", "Enterprise Plan"],
  };

  const state = useSelector((state) => state.plans);

  const handleChoosePlan = async (plan) => {
    ReactGA.gtag("event", "add_to_cart", {
      currency: "USD",
      value: +plan.planPrice,
      items: [
        {
          item_id: planObj[plan.planName][0],
          item_name: planObj[plan.planName][1],
          quantity: 1,
        },
      ],
    });
    ReactGA.send({
      page: history.pathname,
      hitType: "pageview",
      title: "Pricing Choose Page",
    });

    try {
      mixpanel.track("Pricing Choose Page", {
        currency: "USD",
        value: +plan.planPrice,
        description: plan.planDesc,
        item_id: planObj[plan.planName][0],
        item_name: planObj[plan.planName][1],
        quantity: 1,
      });
    } catch (error) {
      console.log("Mixpanel Error : ", error);
    }

    dispatch(selectPlanAction(plan));
    navigate("/checkout");
  };

  if (state.isLoading) {
    return (
      <div className={style.loader}>
        <Loader />
      </div>
    );
  }

  if (
    state.plans === null ||
    // state.plans.length == 0 ||
    state.plans === undefined
  ) {
    return <NotFound />;
  }

  if (state.plans !== null && state.plans.length !== 0) {
    return (
      <div
        className={style.pricing_card}
        style={{
          left: history.pathname === "dashboard/notfound" ? "70%" : "50%",
        }}
      >
        <div className={style.card_header}>
          <div className={style.card_btn_parent}>
            <button
              id="basic_plan"
              onClick={() => setActive(1)}
              className={active === 1 ? style.active : ""}
            >
              Basic
            </button>
            <button
              id="pro_plan"
              className={active === 2 ? style.active : ""}
              onClick={() => setActive(2)}
            >
              Pro
            </button>
            <button
              className={active === 3 ? style.active : ""}
              id="enterprise_plan"
              onClick={() => setActive(3)}
            >
              Enterprise
            </button>
            <div className={style.overlay} />
          </div>
        </div>

        <div className={style.card_body}>
          <div id="card_basic_plan" className={style.active}>
            <div className={style.card_plans}>
              <span className={style.plan_tag}>
                {state.plans[active - 1].planName}
              </span>
              <div className={style.card_plan}>
                <h3 className={style.plan_title}>
                  {active - 1 === 0
                    ? "Pay as you go"
                    : "Monthly subscription FUUU"}
                </h3>
                <h3 className={style.plan_price}>
                  ${state.plans[active - 1].planPrice}
                </h3>
              </div>
            </div>
            <div className={style.card_content}>
              <p>{state.plans[active - 1].planDesc}</p>
              <div className={style.card_lists}></div>
            </div>
            <div className={style.card_button}>
              <button
                onClick={(e) => handleChoosePlan(state.plans[active - 1])}
              >
                Choose Plan
              </button>
            </div>
          </div>
        </div>
      </div>
      //     // <div style={{position: 'relative'}}>
      //     // </div>
      //     // <div>
      //     //   <button onClick={dispatch(fetchPlans)}>Get DAta</button>
      //     //   <div
      //     //     className={style.pricing_card}
      //     //     style={{
      //     //       left: history.pathname === "/dashboard/notfound" ? "70%" : "50%",
      //     //     }}
      //     //   >
      //     //     <div className={style.card_header}>
      //     //       <div className={style.card_btn_parent}>
      //     //         <button
      //     //           id="basic_plan"
      //     //           onClick={() => setActive(1)}
      //     //           className={active === 1 ? style.active : ""}
      //     //         >
      //     //           Basic
      //     //         </button>
      //     //         <button
      //     //           id="pro_plan"
      //     //           className={active === 2 ? style.active : ""}
      //     //           onClick={() => setActive(2)}
      //     //         >
      //     //           Pro
      //     //         </button>
      //     //         <button
      //     //           className={active === 3 ? style.active : ""}
      //     //           id="enterprise_plan"
      //     //           onClick={() => setActive(3)}
      //     //         >
      //     //           Enterprise
      //     //         </button>
      //     //         <div className={style.overlay} />
      //     //       </div>
      //     //     </div>
      //     //     <div className={style.card_body}>
      //     //       <div id="card_basic_plan" className={style.active}>
      //     //         <div className={style.card_plans}>
      //     //           <span className={style.plan_tag}>
      //     //             {plansText[active - 1].tag}
      //     //           </span>
      //     //           <div className={style.card_plan}>
      //     //             <h3 className={style.plan_title}>
      //     //               {plansText[active - 1].title}
      //     //             </h3>
      //     //             <h3 className={style.plan_price}>
      //     //               {plansText[active - 1].price}
      //     //             </h3>
      //     //           </div>
      //     //         </div>
      //     //         <div className={style.card_content}>
      //     //           <p>{plansText[active - 1].content}</p>
      //     //           <div className={style.card_lists}></div>
      //     //         </div>
      //     //         ;
      //     //         <div className={style.card_button}>
      //     //           <button onClick={handleChoosePlan}>Choose Plan</button>
      //     //         </div>
      //     //       </div>
      //     //     </div>
      //     //   </div>
      //     // </div>
    );
  }

  // if (state.plans !== null) {
  //   return (
  //     <>
  //       <div className="container">
  //         <div className="row" style={{ marginTop: "100px" }}>
  //           {state.plans.map((plan) => {
  //             return (
  //               <div className="col-md-4">
  //                 <div className="card">
  //                   <div className="card-body" style={{ padding: "25px" }}>
  //                     <div className="content d-flex justify-content-between">
  //                       <div className="planName">
  //                         <p>Plan Name</p>
  //                         <h3>{plan.planName}</h3>
  //                       </div>
  //                       <div className="price">
  //                         <h3
  //                           className="planPrice"
  //                           style={{ marginTop: "39px" }}
  //                         >
  //                           ${plan.planPrice}
  //                         </h3>
  //                       </div>
  //                     </div>
  //                     <div
  //                       className="description"
  //                       style={{ marginTop: "30px" }}
  //                     >
  //                       <p>{plan.planDesc}</p>
  //                     </div>
  //                     <hr></hr>
  //                     <div className={style.card_button}>
  //                       <button
  //                         style={{
  //                           background: "#000",
  //                           color: "#fff",
  //                           padding: "10px 20px",
  //                           width: "357px",
  //                           borderRadius: "25px",
  //                           border: "1px solid #000",
  //                         }}
  //                         onClick={(e) => handleChoosePlan(plan)}
  //                       >
  //                         Choose Plan
  //                       </button>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             );
  //           })}
  //         </div>
  //       </div>
  //     </>
  //   );
  // }

  if (state.isError) {
    return <p>Something went wrong!</p>;
  }
};

export default Pricing;

import React from "react";
import style from "./payment.module.css";
const ThankYou = () => {
  return (
    <div className={style.bg}>
      <div className={style.card}>
        <span className={style.card__success}>
          <i className={style.ion_checkmark} />
        </span>
        <h1 className={style.card__msg}>Payment Complete</h1>
        <h2 className={style.card__submsg}>Thank you for your payment</h2>
        <div className={style.card__body}>
          <img
            src="http://nathgreen.co.uk/assets/img/nath.jpg"
            className={style.card__avatar}
          />
          <div className={style.card__recipient_info}>
            <p className={style.card__recipient}>Nath Green</p>
            <p className={style.card__email}>hello@nathgreen.co.uk</p>
          </div>
          <h1 className={style.card__price}>
            <span>$</span>20<span>.00</span>
          </h1>
          <p className={style.card__method}>Payment method</p>
          <div className={style.card__payment}>
            <img
              src="https://seeklogo.com/images/V/VISA-logo-F3440F512B-seeklogo.com.png"
              className={style.card__credit_card}
            />
            <div className={style.card__card_details}>
              <p className={style.card__card_type}>Credit / debit card</p>
              <p className={style.card__card_number}>Visa ending in **89</p>
            </div>
          </div>
        </div>
        <div className={style.card__tags}>
          <span className={style.card__tag}>completed</span>
          <span className={style.card__tag}>#123456789</span>
        </div>
      </div>
    </div>
  );
};
export default ThankYou;

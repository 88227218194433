import React from "react";
import style from "./restriction.module.css";
const Test = () => {
  return (
    <section>
      <div class={style.main}>
        <h1> Age Requirements </h1>
        <p>
          Deepturing's Terms of Service require people to be over a minimum age
          to access our services, so we require users to confirm their age to
          satisfy that minimum age requirement. We use this information in
          accordance with our Privacy Policy to keep our users safe and as
          required by law.
        </p>
        <p>
          <b>
            <u>
              For all countries not listed below, 13 is the minimum age to
              access our service
            </u>
          </b>
        </p>
        <p>
          <b>Asia</b>
        </p>
        <p></p>
        South Korea: 14+
        <br />
        Vietnam: 15+
        <br />
        <br />
        <p>
          <b>Caribbean</b>
        </p>
        Aruba: 16+
        <br />
        Caribbean Netherlands: 16+
        <br />
        Curaçao: 16+ <br /> Saint Maarten: 16+
        <br />
        <br />
        <p>
          <b>Europe</b>
        </p>
        <p>
          Austria: 14+
          <br />
          Bulgaria: 14+
          <br />
          Croatia: 16+
          <br />
          Cyprus: 14+
          <br />
          Czech Republic: 15+
          <br />
          France: 15+
          <br />
          Germany: 16+
          <br />
          Greece: 15+
          <br />
          Hungary: 16+
          <br />
          Ireland: 16+
          <br />
          Italy: 14+
          <br />
          Lithuania: 14+
          <br />
          Luxembourg: 16+
          <br />
          Netherlands: 16+
          <br />
          Poland: 16+
          <br />
          Romania: 16+
          <br />
          San Marino: 16+
          <br />
          Serbia: 15+
          <br />
          Slovakia: 16+
          <br />
          Slovenia: 16+
          <br />
          Spain: 14+
          <p></p>
          <p></p>
          <p>
            <b>South America</b>
          </p>
          <p>
            <p></p>
            Chile: 14+
            <br />
            Colombia: 14+
            <br />
            Peru: 14+
            <br />
            Venezuela: 14+
            <p></p>
          </p>
        </p>
      </div>
    </section>
  );
};

export default Test;

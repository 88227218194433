import React, { createContext, useEffect, useState } from "react";

const ThemeContext = createContext();

const ImageProvider = ({ children }) => {
  const [context, setContext] = useState("light");

  // Load context value from localStorage on component mount
  useEffect(() => {
    const storedContext = localStorage.getItem("context");
    if (storedContext) {
      setContext(storedContext);
    }
  }, []);

  // Update context value and store in localStorage
  const handleImageClick = (value) => {
    setContext(value);
    localStorage.setItem("context", value);
  };

  return (
    <ThemeContext.Provider value={{ context, handleImageClick }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ImageProvider };

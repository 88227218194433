import React, { useEffect, useState } from "react";
// import io from "socket.io-client";
import { getTeamProfile } from "../../Service/teamServices";
import { useLocation } from "react-router";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga4";
import GroupInbox from "./groupInbox";
import Preview from "./PreviewUsers ";
import Inbox from "./userInbox";
import Spinner from "./Spinner";

import { useTeam } from "../../context/TeamProvider";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../Service/subuserServices";
import { loginResponse, loginSubUSerResponse } from "../../Service/testLogin";

const Messenger = () => {
  const { loginUser, setLoginUser, setCurrentRoom, teamId } = useTeam();
  const [room, setRoom] = useState("");
  const [inboxToggle, setInboxToggle] = useState(false);
  const [previewToggle, setPreviewToggle] = useState(false);
  const [user, setUser] = useState("");
  const [teamProfile, setTeamProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [status, setStatus] = useState({});
  const [isAuthTeamMember, setIsaAuthTeamMember] = useState(true);
  const location = useLocation();

  // const convertToNumber = (string) => {
  //   let id=''
  //   for (let i = 0; i < string.length; i++) {
  //     id += !Number(string[i])?Math.abs(string[i].charCodeAt(0) - 97):Number(string[i])
  //   }

  //   return Number(id.substring(0,5));
  // };

  let navigate = useNavigate();
  // const teamId = "5f31f440-f78c-4bc2-a478-bcb498302a23";
  // const teamId = "69327672-9ea7-4017-9e5c-b640e7357fd2";

  const handleGetTeamProfile = async (id) => {
    try {
      const response = await getUser(id);
      // const res = await getTeamProfile(id);
      setRoom(teamId);
      // setTeamProfile(response.data.data.user);
      setTeamProfile(() => {
        return {
          name: "Group",
          uuid: response.data.data.user.uuid,
          members: [
            // { id: response.data.data.user.uuid, ...response.data.data.user },
            ...response.data.data.user.subsers.map((subUser, index) => {
              return { id: subUser.suid, ...subUser };
            }),
          ],
        };
      });
    } catch (error) {
      //(error);
      console.log(error)
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    setCurrentRoom(() => {
      return room;
    });
  }, [room]);

  useEffect(() => {
    if (!teamId) {
      return;
    }
    handleGetTeamProfile(teamId);
  }, [teamId]);

  useEffect(() => {
    return () => {
      setCurrentRoom(null);
    };
  }, []);

  useEffect(() => {
    if (teamProfile === null) {
      return;
    }
    // setIsaAuthTeamMember(() => {
    //   return teamProfile.subsers.filter((member) => {
    //     return loginUser === member.suid;
    //   }).length === 0
    //     ? false
    //     : true;
    // });
    setLoading(false);
  }, [teamProfile]);

  useEffect(() => {
    if (isAuthTeamMember === null) {
      return;
    }
    // setLoading(false);
  }, [isAuthTeamMember]);

  useEffect(() => {
    ReactGA.event("page_view", { page_title: "Team Chat Page" });
    ReactGA.send({
      page: location.pathname,
      hitType: "pageview",
      title: "Team Chat Page",
    });

    try {
      mixpanel.track("Team Chat");
    } catch (error) {
      console.log("Mixpanel Error : ", error);
    }
  }, []);

  return (
    <>
      {loading && !error ? (
        <Spinner />
      ) : error ? (
        // <div>Some error occured while fetching the team profile</div>
        <div>You'll need an enterprise account to access this feature</div>
      ) : !isAuthTeamMember ? (
        navigate("/dashboard/error")
      ) : (
        <>
         {/*  {teamProfile && (
            <div className="tw-flex">
              {teamProfile.members.map((member, index) => {
                return (
                  <button
                    key={index}
                    className={`tw-p-2 ${
                      loginUser === member.id
                        ? "tw-bg-gray-300"
                        : "tw-bg-green-300"
                    }`}
                    onClick={() => {
                      setLoginUser(member.id);
                      localStorage.setItem(
                        "loginUser",
                        JSON.stringify(member.id)
                      );
                      localStorage.setItem(
                        "loginUser",
                        JSON.stringify(member.id)
                      );
                      index === 0
                        ? localStorage.setItem(
                            "loginResponse",
                            JSON.stringify(loginResponse.data)
                          )
                        : localStorage.setItem(
                            "loginResponse",
                            JSON.stringify(loginSubUSerResponse.data)
                          );
                    }}
                  >
                    {member.firstName}
                  </button>
                );
              })}
            </div>
          )}
          <br /> */}
          {teamProfile && (
            <div
              style={{ width: "54vw" }}
              className="tw-font-sans tw-flex tw-items-center tw-bg-slate-100 tw-text-black "
            >
              <div
                style={{
                  border: "1px solid #888888",
                  height: "85vh",
                  width: "54vw",
                  minWidth: "24rem",
                }}
                className="lg:tw-hidden tw-grid tw-grid-rows-1 tw-grid-cols-6 md:tw-rounded-xl tw-bg-white tw-shadow-lg tw-shadow-slate-400"
              >
                {previewToggle ? (
                  <PreviewUsers />
                ) : !inboxToggle ? (
                  <GroupChat />
                ) : (
                  <PrivateChat />
                )}
              </div>
              <div
                style={{
                  border: "1px solid #888888",
                  height: "85vh",
                  width: "54vw",
                  minWidth: "24rem",
                }}
                className="tw-hidden lg:tw-grid tw-grid-rows-1 tw-grid-cols-6 md:tw-rounded-xl tw-bg-white tw-shadow-lg tw-shadow-slate-400"
              >
                <PreviewUsers />
                {!inboxToggle ? <GroupChat /> : <PrivateChat />}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );

  function PreviewUsers() {
    return (
      <Preview
        inboxToggle={inboxToggle}
        setInboxToggle={setInboxToggle}
        setUser={setUser}
        setPreviewToggle={setPreviewToggle}
        teamProfile={teamProfile}
        loginUser={loginUser}
        setRoom={setRoom}
        status={status}
        setStatus={setStatus}
      />
    );
  }
  function GroupChat() {
    return (
      <>
        <GroupInbox
          setPreviewToggle={setPreviewToggle}
          teamProfile={teamProfile}
          teamId={teamId}
          loginUser={loginUser}
          room={room}
        />
      </>
    );
  }
  function PrivateChat() {
    return (
      <Inbox
        handleHeaderBackArrow={() => {
          setInboxToggle(false);
          setRoom(teamId);
        }}
        user={user}
        inboxToggle={inboxToggle}
        setInboxToggle={setInboxToggle}
        teamProfile={teamProfile}
        loginUser={loginUser}
        room={room}
        status={status}
      />
    );
  }
};

export default Messenger;

import React from "react";
import Chart from "../component/parts/Chart";

const data = [
  {
    name: "Sunday",
    users: 4000,
  },
  {
    name: "Monday",
    users: 3000,
  },
  {
    name: "Tuesday",
    users: 2000,
  },
  {
    name: "Wednesday",
    users: 2780,
  },
  {
    name: "Thursday",
    users: 1890,
  },
  {
    name: "Friday",
    users: 2390,
  },
  {
    name: "Saturday",
    users: 3490,
  },
];

const Charts = () => {
  return (
    <React.Fragment>
      <main>
        <Chart data={data} aspect={2.2} title={`Daily Users Statistics`} />
      </main>
    </React.Fragment>
  );
};

export default Charts;

import { createSlice } from "@reduxjs/toolkit";
import { editProfile } from "./profileService";

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    loading: false,
    message: null,
    isError: false,
    isSuccess: false,
    updatedUser: null,
  },

  extraReducers: (builder) => {
    builder
      .addCase(editProfile.pending, (state) => {
        state.loading = true;
        state.isError = false;
        state.message = null;
        state.isSuccess = false;
      })
      .addCase(editProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.isError = false;
        state.isError = false;
        state.updatedUser = action.payload.user;
        state.message = action.payload.message;
        state.isSuccess = true;
      })
      .addCase(editProfile.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      });
  },
  reducers: {
    editIsSuccess: (state, action) => {
      state.isSuccess = action.payload;
    },
  },
});

export const { editIsSuccess } = profileSlice.actions;

export default profileSlice.reducer;

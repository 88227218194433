import React, { useEffect, useRef, useState } from "react";
import { useTeam } from "../../context/TeamProvider";
import { statusDetail } from "../../utils/functions";
import useSound from "use-sound";
import notification from "../../../src/assets/notification.mp3";

const imgplaceholdder =
  "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg";

const Preview = ({
  inboxToggle,
  setInboxToggle,
  setUser,
  setPreviewToggle,
  teamProfile,
  setRoom,
}) => {
  const [play] = useSound(notification);
  const searchRef = useRef(null);
  const [search, setSearch] = useState("");
  const [filteredMember, setFilteredMembers] = useState([]);
  const [recNoti, setRecNoti] = useState("");

  const { loginUser, socket, room, currentRoom } = useTeam();
  const [notiIndicator, setNotiIndicator] = useState({});
  const [receivedStatus, setReceivedStatus] = useState("");
  const [status, setStatus] = useState(
    localStorage.getItem("status")
      ? JSON.parse(localStorage.getItem("status"))
      : {}
  );
  // const { room, myStatus } = useTeam();

  const calculateRoom = (senderId, receiverId) => {
    return receiverId >= senderId
      ? receiverId.toString() + senderId.toString()
      : senderId.toString() + receiverId.toString();
  };

  useEffect(() => {
    let notificationIndicator = localStorage.getItem("notification")
      ? JSON.parse(localStorage.getItem("notification"))
      : {};

    setNotiIndicator(notificationIndicator);
  }, []);

  useEffect(() => {
    if (socket === null) {
      return;
    }
    socket.on("receive_change_status", (data) => {
      setReceivedStatus(data);
    });

    socket.on("receive_noti_indicator", (data) => {
      setRecNoti(data);
    });
  }, [socket]);

  useEffect(() => {
    if (recNoti === "") {
      return;
    }
    if (recNoti.sender === loginUser) {
      return;
    }
    if (recNoti.chatroom === currentRoom) {
      return;
    }
    if (!recNoti.mentionedReceivers) {
      // event is for private chats
      if (recNoti.receiverId === loginUser) {
        play();
      }
      setNotiIndicator((prev) => {
        const updatedNoti = { ...prev };
        updatedNoti[recNoti.chatroom] = true;
        return updatedNoti;
      });
    } else {
      //event is for group chats
      let filteredMember = recNoti.mentionedReceivers.filter((member) => {
        return member.id === loginUser;
      });
      if (filteredMember.length !== 0) {
        play();
      }
    }
  }, [recNoti]);

  useEffect(() => {
    if (Object.keys(notiIndicator).length === 0) {
      return;
    }

    localStorage.setItem("notification", JSON.stringify(notiIndicator));
  }, [notiIndicator]);

  useEffect(() => {
    if (receivedStatus === "") {
      return;
    }
    if (receivedStatus.id === loginUser) {
      return;
    }
    socket.emit("joined_member_status", { room: room });
    if (receivedStatus.status !== status[receivedStatus.id]) {
      setStatus((prev) => {
        const updatedStatus = { ...prev };
        updatedStatus[receivedStatus.id] = receivedStatus.status;
        // localStorage.setItem("status", JSON.stringify(updatedStatus));
        return updatedStatus;
      });
    }
  }, [receivedStatus]);

  useEffect(() => {
    if (Object.keys(status).length === 0) {
      return;
    }
    localStorage.setItem("status", JSON.stringify(status));
  }, [status]);

  return (
    <div
      className={`${
        inboxToggle ? "tw-hidden" : "tw-flex"
      } tw-h-full lg:tw-col-span-2 tw-col-span-full lg:tw-flex tw-flex-col tw-gap-2 tw-drop-shadow-lg tw-py-4`}
    >
      <div className="tw-basis-[80%] tw-flex tw-flex-col tw-px-3">
        <input
          type="search"
          placeholder="Search"
          ref={searchRef}
          value={search}
          style={{ height: "3rem" }}
          className={`tw-w-full tw-rounded-xl focus:tw-outline-none focus:tw-border-0 tw-border-1 tw-border-slate-300`}
          onChange={(event) => {
            let query = event.target.value;
            setFilteredMembers(() => {
              return teamProfile.members.filter((member, index) => {
                let name = member.firstName + " " + member.lastName;
                return (
                  query.toLowerCase() ===
                  name.substring(0, query.length).toLowerCase()
                );
              });
            });
            setSearch(query);
          }}
        />
      </div>

      <div
        className={`tw-basis-[80%] tw-flex tw-flex-col tw-overflow-scroll tw-overflow-x-hidden tw-px-3`}
      >
        {teamProfile && search.length === 0 && (
          <UserDisplay array={teamProfile.members} />
        )}

        {teamProfile && search.length !== 0 && (
          <UserDisplay array={filteredMember} />
        )}
      </div>
    </div>
  );

  function UserDisplay({ array }) {
    return array.map(
      (user, index) =>
        user.id !== loginUser && (
          <div
            className="tw-flex tw-justify-between tw-items-start tw-px-3 tw-my-1 tw-py-3 tw-cursor-pointer tw-rounded-lg hover:tw-bg-gray-50"
            key={index}
            onClick={() => {
              setNotiIndicator((prev) => {
                const updatedNoti = { ...prev };
                updatedNoti[calculateRoom(loginUser, user.id)] = false;
                localStorage.setItem(
                  "notification",
                  JSON.stringify(updatedNoti)
                );
                setInboxToggle(true);
                setPreviewToggle(false);
                setUser(user.id);
                setRoom(() => {
                  return user.id >= loginUser
                    ? user.id.toString() + loginUser.toString()
                    : loginUser.toString() + user.id.toString();
                });
                return updatedNoti;
              });
            }}
          >
            <div className="tw-flex tw-items-center tw-space-x-4 ">
              <div className="tw-relative">
                <img
                  className="tw-rounded-full tw-w-10 tw-h-10"
                  data-testid="flowbite-avatar-img"
                  src={false ? user.profilePic : imgplaceholdder}
                />
                <span
                  style={{
                    backgroundColor:
                      status[user.id] === "1"
                        ? statusDetail[0].color
                        : status[user.id] === "3"
                        ? statusDetail[2].color
                        : statusDetail[1].color,
                  }}
                  className="tw-absolute tw-h-3.5 tw-w-3.5 tw-rounded-full tw-border-2 tw-border-white dark:tw-border-gray-800 tw--bottom-1 tw--right-1"
                ></span>
              </div>
              <div
                style={{}}
                className="tw-space-y-1 tw-font-medium tw-h-10 lg:tw-max-w-vw8 xl:tw-max-w-vw10 2xl:tw-max-w-vw11 tw-relative"
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="tw-truncate">
                    {user.firstName + " " + user.lastName}
                  </div>
                  {notiIndicator[calculateRoom(loginUser, user.id)] && (
                    <div
                      style={{
                        borderRadius: "50%",
                        backgroundColor: "red",
                        height: "12px",
                        width: "12px",
                        marginLeft: "0.5rem",
                      }}
                    ></div>
                  )}
                </div>
                <div className="tw-text-sm tw-text-gray-500 tw-truncate">
                  {user.email}
                </div>
              </div>
            </div>
          </div>
        )
    );
  }
};

export default Preview;

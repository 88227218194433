import React from "react";
import MDEditor from "@uiw/react-md-editor";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import styles from "./styles/markdownEditorWithCodeHighlighting.module.css";
const MarkdownEditorWithCodeHighlighting = ({ value }) => {
  const [copy, setCopy] = React.useState(false);
  // const codeWithLineBreaks = value.replace(/\\n/g, "\n");
  const formattedVal = value.replace(/^\n\n/, "");

  const RenderCodeBlock = () => {
    return (
      <SyntaxHighlighter language={"javascript"} style={vscDarkPlus}>
        {formattedVal}
      </SyntaxHighlighter>
    );
  };
  React.useEffect(() => {
    if (copy) {
      const timer = setTimeout(() => {
        setCopy(false);
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [copy]);
  return (
    <div className={styles.markdownEditorWrapper}>
      {/* <MDEditor.Markdown
        source={codeWithLineBreaks}
        renderers={{ code: renderCodeBlock }}
      /> */}
      <div className={styles.header}>
        <button
          onClick={() => {
            navigator.clipboard.writeText(formattedVal);
            setCopy(true);
          }}
        >
          {!copy ? (
            <>
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                class="h-4 w-4"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
              </svg>
              Copy code
            </>
          ) : (
            <>
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                class="h-4 w-4"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 13l4 4L19 7"></path>
              </svg>
              Copied!
            </>
          )}
        </button>
      </div>
      <RenderCodeBlock />
    </div>
  );
};

export default MarkdownEditorWithCodeHighlighting;

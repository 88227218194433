import "./PrivacyPolicy.module.css";
export const PrivacyPolicy = () => (
  <>
    <div class="main">
      <b>
        <h1>Privacy Policy</h1>
      </b>
      <p>Last updated: March 20, 2024</p>
      <hr />

      <p>
        DeepTuring a platform operated by Sluu Corporation Ltd. Throughout this
        Privacy Policy ("Deepturing", "we", "our") refers to Sluu Corporation Ltd
        and its affiliates. DeepTuring is committed to maintaining the privacy
        of its users ("user", "you"). The following information describes how
        DeepTuring collects and processes information about you when you use its
        website (the "Website") and Services (as defined below).
      </p>
      <p>
        <b>Our Privacy Policy explains:</b>
      </p>
      <p>• What information we collect and why we collect it.</p>
      <p> • How we use that information. </p>
      <p>• Your rights with regard to the collection of such information. </p>
      <p>
        {" "}
        By using our Website and Services, you agree to this Privacy Policy.{" "}
      </p>
      <p>
        {" "}
        Sluu Corporation Ltd operator of DeepTuring is an artificial general
        intelligence and quantum computing technology company focused on
        developing and deploying advanced super intelligent AI and quantum
        computing solutions. Our mission is to ensure that artificial general
        intelligence (AGI) is created to solve a plethora of world problems
        across multiple industries safely and to Advance the well-being of
        humankind. Our product DeepTuring uses AI to help developers build
        software smarter and faster. We offer AI tools that are able to generate
        and autocomplete code.{" "}
        {/*To do that Deepturing
        runs machine-learning algorithms over code and discovers practices that
        form the de-facto coding standards.*/}{" "}
        ("Services").{" "}
      </p>
      <p>
        <b>Collecting Information</b>
      </p>
      <p>
        Please be aware that the information that DeepTuring collects from you
        when you use its website and services is the only thing that is covered
        by this privacy policy. Certain information may be gathered
        automatically, and other information is gathered when you use our
        services and interact with the website. The kinds of data that could be
        automatically gathered are those that DeepTuring collects or those that
        are obtained by a third-party analytics provider that offers instruments
        for automatically gathering data regarding how you use the provider.{" "}
      </p>
      <p>
        {" "}
        Information from which you can be personally identified may also be
        collected, including but not limited to your email address and the
        location of the computer through which you used the Website and Services
        (hereinafter: "Personal Information"). Personal Information shall be
        collected only if received voluntarily by you, including, without
        limitation, when you download DeepTuring′s plugin, use DeepTuring′s
        Services via the following methods:{" "}
      </p>
      <p>
        a. Registration to use the Services: you may register to use the
        Services using your full name and email address. We will explicitly
        indicate the mandatory fields for completion.{" "}
      </p>
      <p>
        {" "}
        b. Registration through your social media accounts such as your
        Microsoft or Google accounts: You may also register using your Microsoft
        or Google Accounts or a certain social media account you created. If you
        choose to register with these accounts, you give us access to retrieve
        your information from these services. The particular categories of
        information we retrieve from the accounts are within the scope of
        information that such networks makes available to us by default,
        according to your privacy settings at each network. The information we
        collect in this manner typically includes your full name, email address
        and public information from your profile on these services.{" "}
      </p>
      <p>
        {" "}
        c. Contact Us: If you contact us for various reasons such as requesting
        a specific service, asking questions or raising a complaint, we will
        collect the information related to your inquiry. This may include your
        user ID in the Service, email address and other contact information,
        depending on the nature of your inquiry.{" "}
      </p>
      <p>
        {" "}
        d. Subscribing to our Newsletter: If you wish to register to be provided
        with our newsletter and be provided with information on the Services and
        special promotions, we will collect your email address, subject to your
        consent, and will send you the required materials.{" "}
      </p>

      <p>
        <b>Use of Information:</b>
      </p>
      <p>
        We use the personal information we collect from you for a range of
        different business purposes according to different legal bases of
        processing. We may use or process your personal information for the
        following purposes. One or more purposes may apply simultaneously{" "}
      </p>
      <p>1. Providing the requested services:</p>
      <p>
        • We will collect your Personal Information to enable you to register to
        use the Services.
      </p>
      <p>
        • We collect Personal Information to provide you with the Services you
        signed up to receive.
      </p>
      <p>
        • We will collect your Personal Information to facilitate any inquiry
        you made via the Website.
      </p>
      <p>
        • We will collect your Personal Information and will send you relevant
        notifications within the Service, when we believe that they are required
        for the provision of the Services to you.
      </p>
      <p>
        • Such collection of information will enable us to provide you with
        technical and professional assistance, with regard to the Website and
        Services you use or intend to use.
      </p>
      <p>
        We process the Personal Information where it is necessary for the
        adequate performance of the contract regarding the requested Services.
      </p>
      <p>2. Improvement and Development of the Services:</p>
      <p>
        {" "}
        • We collect Personal Information to improve and develop our Services
        and understand feedback on DeepTuring′s Services and Website and to help
        provide more information on the use of our Services quickly and easily.
      </p>
      <p>
        {" "}
        • We collect your Personal Information as well as telemetry data to
        understand feedback on our Services and improve and develop our Services
        accordingly.{" "}
      </p>
      <p>
        • We collect Personal Information for ongoing review and improvement of
        the information provided on our Website to ensure it is user friendly.{" "}
      </p>
      <p>
        {" "}
        • We collect Personal Information to improve the management and
        administration of our business and maintain compliancy with our internal
        policies and procedures.{" "}
      </p>
      <p>
        {" "}
        • We conduct surveys and research, test features in development, and
        analyze the information we have to evaluate and improve our Services,
        develop new features, and conduct audits and troubleshooting activities.{" "}
      </p>
      <p>
        {" "}
        • Your e-mail and your account will be used to send you Service updates
        and notifications about your account, to respond to you regarding
        comments or inquiries you have submitted or in order to contact you when
        Deepturing believes it to be necessary.{" "}
      </p>
      <p>
        {" "}
        We process this information in light of our legitimate interest in
        improving the Website and Services, to allow our users to have the best
        experience.{" "}
      </p>
      <p> 3. Maintain a Safe and Secure Environment</p>
      <p>
        {" "}
        We may use your information to detect and prevent fraud, abuse and
        security incidents in the following ways:{" "}
      </p>
      <p>
        {" "}
        • Verify and authenticate your identity when deemed necessary to prevent
        unauthorized or illegal activity;{" "}
      </p>
      <p> • Enhance the safety and security of our Website and Services; </p>
      <p> • Conduct security investigations and risk assessments; </p>
      <p>
        {" "}
        • Prevent or take action against activities that are, or may be, in
        breach of our terms of service or applicable law.{" "}
      </p>
      <p>
        We process this information in light of our legitimate interest in
        improving our Website and Services and enabling our users to browse in a
        secure environment.{" "}
      </p>
      <p> 4. Personalize Content, Advertising and Marketing</p>
      <p>
        {" "}
        • By using DeepTuring′s Services, we have a legitimate business interest
        for collecting and matching the data of you for the site functionality{" "}
      </p>
      <p>
        {" "}
        • This enables us to understand your needs and interests, optimize the
        content we send you and make it more suitable and relevant to your
        needs.{" "}
      </p>
      <p>
        {" "}
        • This also enables us to improve your experience on the Website by
        providing you with personalized content, recommendations, and features.{" "}
      </p>
      <p>
        {" "}
        We process this information in light of our legitimate interest to
        personalize your experience and customize our content.{" "}
      </p>
      <p>
        <b> Disclosure of Information and Transfer of Data </b>
      </p>
      <p>
        {" "}
        Except as otherwise provided in this Privacy Policy, we reasonably
        attempt to ensure that we never intentionally disclose any of your
        Personal Information, to any third party without having received your
        permission, except as provided for herein or otherwise as permitted or
        required under law.{" "}
      </p>
      <p>
        {" "}
        In order to perform our contractual and other legal responsibilities or
        purposes, we may, from time to time, need to share your Personal
        Information with third parties. We may as well share your Personal
        Information with our affiliates, subsidiaries or any third party service
        providers and individuals to facilitate our Services or any portion
        thereof, such as marketing, data management or maintenance services.{" "}
      </p>
      <p>
        {" "}
        We may also transfer your information, including Personal Information,
        in connection with a corporate merger, consolidation, the sale of
        related assets or corporate division or other fundamental corporate
        changes. Furthermore, information about you may also be released in
        order to comply with any valid legal obligation or inquiry or process
        such as a search warrant, subpoena, statute or court order. We will also
        release specific information in special cases, such as if you use the
        Website or Services to perform an unlawful act or omission or take any
        act or omission that may damage Deepturing, its property and goodwill,
        or if there is an attempted breach of the security of the Website,
        Services or a physical or property threat to you or others.{" "}
      </p>
      <p>
        <b>Your rights</b>
      </p>
      <p>
        {" "}
        You are entitled to request access to or modification of your
        information at any time. Certain jurisdictions, specifically those found
        in the European Union (the "EU") or the European Economic Area (the
        "EEA"), may grant you certain rights with respect to your Personal
        Information. You might be eligible for the following rights, if
        applicable::
      </p>
      <p>
        {" "}
        1. Request a rectification of your Personal Information where the
        information we hold about you is incorrect or incomplete.
      </p>
      <p>
        2. Object to the processing of your Personal Information for direct
        marketing purposes.{" "}
      </p>
      <p>
        {" "}
        3. Object to the processing of your Personal Information where our legal
        basis for that processing is that such processing is necessary for our
        legitimate interests.{" "}
      </p>
      <p>
        4. Object to an automated decision-making (including profiling) in
        certain circumstances.{" "}
      </p>
      <p>
        5. Request the erasure of your Personal Information in certain
        circumstances, such as where processing is no longer necessary for the
        purpose it was originally collected for, and there is no compelling
        reason for us to continue to process or store it;{" "}
      </p>
      <p>
        {" "}
        6. Receive your Personal Information, or ask us to transfer it to
        another organization that you have provided to us, which we process by
        automated means, where our processing is either based on your consent or
        is necessary for the performance of a contract with you.{" "}
      </p>
      <p>
        If you wish to file a request regarding any of the above you may contact
        us at support@deepturingai.com
      </p>

      <p>
        <b> California Online Privacy Protection Act </b>
      </p>
      <p>
        {" "}
        CalOPPA requires commercial websites and online services to post a
        privacy policy. The law′s reach stretches well beyond California to
        require any person or company in the United States (and conceivably the
        world) that operates websites collecting Personally Identifiable
        Information from California consumers, to post a conspicuous privacy
        policy on its website stating exactly the information being collected
        and those individuals or companies with whom it is being shared. See
        more at:
        http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf.
      </p>
      <p> According to CalOPPA, DeepTuring agrees to the following: </p>
      <p>
        {" "}
        • Once this Privacy Policy is created, we will add a link to it on our
        home page or on the first significant page after entering the Website.
      </p>
      <p>
        {" "}
        • DeepTuring′s Privacy Policy link includes the word ′Privacy′ and can
        easily be found on each page on the Website.{" "}
      </p>
      <p>
        {" "}
        • You can request to change your Personal Information by emailing us at:
        support@deepturingai.com{" "}
      </p>

      <p>
        <b> California Privacy Rights </b>
      </p>
      <p>
        {" "}
        The California Consumer Privacy Act of 2018 ("CCPA") permits users who
        are California residents to request to exercise certain rights. If you
        are a California resident, the CCPA grants you the right to request
        certain information about our practices with respect to your Personal
        Information. In particular, you can request to receive information on
        the following:
      </p>
      <p>
        • The categories and specific pieces of your Personal Information that
        we have collected.{" "}
      </p>
      <p>
        • The categories of sources from which we collected your Personal
        Information.{" "}
      </p>
      <p>
        {" "}
        • The business or commercial purposes for which we collected your
        Personal Information.{" "}
      </p>
      <p>
        {" "}
        • The categories of third parties with which we shared your Personal
        Information.{" "}
      </p>
      <p>
        You can rest assured that we do not sell your Personal Information. If
        you choose to exercise your rights, we will not charge you different
        prices or provide different quality of our Services, unless those
        differences are related to your provision of your Personal Information.{" "}
      </p>
      <p>
        {" "}
        Please note that you must verify your identity and request before
        further action is taken. As a part of this process, government
        identification may be required. Moreover, you may designate an
        authorized agent to make a request on your behalf.{" "}
      </p>
      <p>
        {" "}
        We endeavor to respond to a verifiable consumer request within 45 days
        of its receipt. If we require more time (up to 90 days), we will inform
        you of the reason and extension period in writing. Any disclosures we
        provide, will only cover the 12 month period preceding your verifiable
        request′s receipt. If, for some reason, we cannot reply within such time
        frame, our response will include an explanation for our inability to
        comply. If you wish to exercise your CCPA rights, please contact us. We
        will not discriminate against you for exercising any of your CCPA
        rights. Unless permitted by the CCPA, we will not:
      </p>
      <p> 1. Deny you goods or services. </p>
      <p>
        {" "}
        2. Charge you different prices or rates for goods or services, including
        through granting discounts or other benefits, or imposing penalties.{" "}
      </p>
      <p>
        {" "}
        3. Provide you with a different level or quality of goods or services.{" "}
      </p>
      <p>
        {" "}
        4. Suggest that you may receive a different price or rate for goods or
        services or a different level or quality of goods or services.{" "}
      </p>
      <p>
        {" "}
        <b> Cookies</b>
      </p>
      <p>
        {" "}
        We may use "cookies" and/or other technologies or files (collectively,
        "cookies") to identify how users make use of our Website and Services.
        This aggregated tracking information may be used to help us improve and
        enhance the Website and Services experience for all of our users. In
        addition, cookies are used for adjusting the Website and Services to
        your personal preferences. If you would prefer not to have cookies
        stored on your computer, you may modify your browser settings to reject
        most cookies, or manually remove cookies that have been placed on your
        computer. However, by rejecting the cookies, you may be unable to fully
        access the offerings on this Website.{" "}
      </p>
      <p>
        <b> Opt In or Opt Out</b>
      </p>
      <p>
        {" "}
        You are always in control of your data, and if you choose to receive
        information from us, or others, you can change your mind later. If, at
        any time, you would like to stop receiving such information or opt out
        of a feature, you may notify us. You should be aware, however, that it
        is not always possible to completely remove or modify information in our
        databases and servers, although we will always make reasonable efforts
        to do so upon your request.
      </p>
      <p>
        <b> Data Security </b>
      </p>
      <p>
        {" "}
        We deploy industry standard measures to ensure the security,
        confidentiality, integrity and availability of the Personal Information
        we process. We maintain physical, technical and administrative
        safeguards, and test and update these periodically. We endeavor to
        restrict access to Personal Information on a ‘need to know′ basis for
        the provision of Services to you. No such measures are perfect or
        impenetrable. In the event of a security breach, we will take all
        reasonable action to minimize any harm. Although we will do our best to
        protect Personal Information, we cannot guarantee the security of data
        transmitted to our Website and transmission is at the users own risk.{" "}
      </p>
      <p>
        <b> Data Retention </b>
      </p>
      <p>
        {" "}
        DeepTuring does not retain information longer than necessary to provide
        its Services and for its reasonable business and lawful needs. If you
        withdraw your consent to us processing your Personal Information, we
        will erase your Personal Information from our systems, unless the
        Personal Information is required for DeepTuring to establish, exercise
        or defend against legal claims or it is necessary for the performance of
        the requested Services.
      </p>
      <p>
        <b> Children's Privacy </b>
      </p>
      <p>
        {" "}
        The Service is not intended for users under the age of 13. We do not,
        knowingly or intentionally, collect information about children who are
        under 13 years of age. In the event that we learn that we have collected
        personal information from a child under 13 without consent from a parent
        or legal guardian, we will delete that information as quickly as
        possible.{" "}
      </p>
      <p>
        {" "}
        If a Child is from a GDPR member state, the age of consent is 16,
        However According to Article 8(1), member states may enact laws that
        lower the age of consent to 13 (but not under 13). The Country list and
        minimum age is listed in our Terms of service. We will abide by each
        member state minimum digital age consent accordingly.{" "}
      </p>
      <p>
        <b> Revisions and Modifications to our Privacy Policy</b>
      </p>
      <p>
        {" "}
        We reserve the right to revise, amend, or modify this Privacy Policy at
        any time. When changing the policy, we will update this posting
        accordingly. Please review this Privacy Policy often so that you will
        remain updated regarding our current policies.{" "}
      </p>
      <p>
        <b> Governing Law and Jurisdiction</b>
      </p>
      <p>
        {" "}
        This Privacy Policy will be governed and interpreted pursuant to the
        laws of the State of Jamaica without giving effect to its choice of law
        rules. You expressly agree that the exclusive jurisdiction for any claim
        or action arising out of or relating to this Privacy Policy shall be to
        the competent courts in Kingston, Jamaica, to the exclusion of any other
        jurisdiction.{" "}
      </p>
    </div>
  </>
);

export default function Skeletion({ nullMargin }: { nullMargin?: boolean }) {
  return (
    <div
      className={`tw-flex tw-items-center ${
        !nullMargin && "tw-mt-4"
      } tw-space-x-3`}
    >
      <svg
        className="tw-text-gray-200 tw-w-14 tw-h-14 dark:tw-text-gray-700"
        aria-hidden="true"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
          clipRule="evenodd"
        ></path>
      </svg>
      <div>
        <div className="tw-h-2.5 tw-bg-gray-200 tw-rounded-full dark:tw-bg-gray-700 tw-w-32 tw-mb-2"></div>
        <div className="tw-w-48 tw-h-2 tw-bg-gray-200 tw-rounded-full dark:tw-bg-gray-700"></div>
      </div>
    </div>
  );
}
